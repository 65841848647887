<div class="ninjas-notification-notice ninjas-notification-notice-closable"
     [ngStyle]="notification.options.style"
     [ngClass]="notification.options.class"
     [@enterLeave]="state"
     (mouseenter)="onEnter()"
     (mouseleave)="onLeave()">
  <div *ngIf="!notification.template" (click)="onClick()" class="ninjas-notification-notice-content" [ngClass]="{'clickable-container': onClickCallbackPresent}">
    <div class="ninjas-notification-notice-content" [ngClass]="{ 'ninjas-notification-notice-with-icon': notification.type !== 'blank' }">
      <div [class.ninjas-notification-notice-with-icon]="notification.type !== 'blank'">
        <ng-container [ngSwitch]="notification.type">
          <div *ngSwitchCase="'success'" class="ninjas-notification-notice-icon ninjas-notification-notice-icon-success" ></div>
          <div *ngSwitchCase="'info'" class="ninjas-notification-notice-icon ninjas-notification-notice-icon-info" ></div>
          <div *ngSwitchCase="'warning'" class="ninjas-notification-notice-icon ninjas-notification-notice-icon-warning" ></div>
          <div *ngSwitchCase="'error'"  class="ninjas-notification-notice-icon ninjas-notification-notice-icon-error" ></div>
        </ng-container>
        <div class="ninjas-notification-notice-message" [innerHTML]="notification.title"></div>
        <div class="ninjas-notification-notice-description" [innerHTML]="notification.message"></div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="notification.template" [ngTemplateOutlet]="notification.template" [ngTemplateOutletContext]="{ $implicit: this }"></ng-template>
  <a tabindex="0" class="ninjas-notification-notice-close" (click)="close()">
    <span class="ninjas-notification-notice-close-x">
      <img class="ninjas-notification-close-icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGJSURBVGhD7ZltjsIwDESL9ow9QnuAPRTXXHhVgrKTlH45JVR+kgVK7JmxgD+lcxzHcZwTuYXXM6jj1ff9zzAM93Ecf8NRNfDAC89wZEOyxF+oasugHX2sl7nJEtWWQVM8pmWeVzZfs5JBKLNl0BJtc48JBMXAzAgN0TTTLoKwGB02ZFa0DmuuAgMx3G3MjGjs1toFRmK8OQC9MrtZwwQMJcDqIPTIzOrZKmAsQRYDcSe9izOnQAAJNBuMM+mZ7f0IBJFgWUDey13W0wQEkoCvoKGKd2G8LQgmQd9Vm0tECCiBS9X2EhGCSvC0vmMJIKyET+sSn0asS/w+YrW5DMEk6CtwqOJdGG8DAknALCjv5S7r+SgEkWCzATmTntneUyGABFoMxp30Ls5UBWMJsjoQPTKzetYUDCXA5iD0yuxmjUNgJMa7AzAjGru1NoGBGB42Zla0Dmu+BWExMjNEQzTNtP+BoBiYG6El2uYe13lkepWH2BPJMtWWiOBRZYmE7/+jx3Ecx3Eyuu4BYx4c8oFDYqEAAAAASUVORK5CYII=">
    </span>
  </a>
</div>
