import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';

export type CtaSize = 'small' | 'medium' | 'large';

/**
 * List of classes to add to MatButton instances based on host attributes to
 * style as different variants.
 */
const CTA_HOST_ATTRIBUTES = [
  'zen-primary-cta',
  'zen-secondary-cta',
  'zen-secondary-inverted-cta',
  'zen-text-cta',
  'zen-underlined-text-cta',
  'zen-primary-icon-cta',
  'zen-gray-icon-cta',
  'zen-gray-filled-icon-cta',
  'zen-secondary-icon-cta',
  'zen-gray-text-cta',
  'zen-gray-underlined-text-cta',
  'zen-blue-text-cta',
  'zen-blue-underlined-text-cta',
  'zen-editor-cta',
];

@Component({
  selector:
    'button[zen-primary-cta],' +
    'button[zen-secondary-cta],' +
    'button[zen-secondary-inverted-cta],' +
    'button[zen-text-cta],' +
    'button[zen-underlined-text-cta],' +
    'button[zen-primary-icon-cta],' +
    'button[zen-secondary-icon-cta],' +
    'button[zen-gray-icon-cta],' +
    'button[zen-gray-filled-icon-cta],' +
    'button[zen-gray-text-cta],' +
    'button[zen-gray-underlined-text-cta],' +
    'button[zen-blue-text-cta],' +
    'button[zen-blue-underlined-text-cta],' +
    'button[zen-editor-cta],' +
    'a[zen-primary-cta],' +
    'a[zen-secondary-cta],' +
    'a[zen-secondary-inverted-cta],' +
    'a[zen-primary-icon-cta],' +
    'a[zen-secondary-icon-cta],' +
    'a[zen-gray-icon-cta],' +
    'a[zen-gray-filled-icon-cta],' +
    'a[zen-text-cta],' +
    'a[zen-underlined-text-cta],' +
    'a[zen-gray-text-cta],' +
    'a[zen-gray-underlined-text-cta],' +
    'a[zen-blue-text-cta],' +
    'a[zen-blue-underlined-text-cta],' +
    'a[zen-editor-cta]',
  templateUrl: 'cta.html',
  exportAs: 'zenCta',
  styleUrls: ['cta.scss'],
  // tslint:disable-next-line:use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:no-inputs-metadata-property
  inputs: ['size'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'zen-base-cta',
    '[class.zen-cta-small]': 'size === "small"',
    '[class.zen-cta-medium]': 'size === "medium"',
    '[class.zen-cta-large]': 'size === "large"',
    // '[class.disabled]': 'disabled'
  },
})
export class ZenCtaComponent {
  @Input() size: CtaSize = 'medium';

  constructor(private elementRef: ElementRef) {
    // For each of the variant selectors that is present in the button's host
    // attributes, add the correct corresponding class.
    for (const attr of CTA_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (this._getHostElement() as HTMLElement).classList.add(attr);
      }
    }

    // Add a class that applies to all buttons. This makes it easier to target if somebody
    // wants to target all Material buttons. We do it here rather than `host` to ensure that
    // the class is applied to derived classes.
    elementRef.nativeElement.classList.add('zen-cta-base');
  }

  _getHostElement() {
    return this.elementRef.nativeElement;
  }

  /** Gets whether the button has one of the given attributes. */
  _hasHostAttributes(...attributes: string[]) {
    return attributes.some((attribute) =>
      this._getHostElement().hasAttribute(attribute)
    );
  }
}
