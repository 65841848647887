<div class="leave-main-div">
  <div *ngIf="!requestLeaveResult">
    <span class="heading">Apply for Leaves</span>
    <div class="basic-form">
      <form [formGroup]="applicationFormGroup">
        <div class="calendar-div">
          <div class="calendar">
            <mat-form-field appearance="outline">
              <mat-label>From Date</mat-label>
              <input
                matInput
                formControlName="fromDate"
                [min]="todayDate"
                [matDatepicker]="picker"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="
                  applicationFormGroup.get('fromDate').hasError('required')
                "
                >From Date is required</mat-error
              >
              <mat-error
                *ngIf="
                  applicationFormGroup
                    .get('fromDate')
                    .hasError('already-requested')
                "
                >you have already requested for leaves/slot-change on these
                dates.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="calendar">
            <mat-form-field appearance="outline">
              <mat-label>To Date</mat-label>
              <input
                matInput
                formControlName="toDate"
                [min]="applicationFormGroup.get('fromDate').value"
                [matDatepicker]="picker2"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <mat-error
                *ngIf="applicationFormGroup.get('toDate').hasError('required')"
                >To Date is required</mat-error
              >
              <mat-error
                *ngIf="
                  applicationFormGroup
                    .get('toDate')
                    .hasError('matDatepickerMin')
                "
                >To Date should greater than From Date</mat-error
              >
              <mat-error
                *ngIf="
                  applicationFormGroup
                    .get('toDate')
                    .hasError('already-requested')
                "
                >you have already requested for leaves/slot-change on these
                dates.</mat-error
              >
              <mat-error
                *ngIf="
                  applicationFormGroup
                    .get('toDate')
                    .hasError('different-months')
                "
                >You can request leaves for one month in one request. If your
                leave spans multiple months, please create separate
                requests.</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div class="reason-div">
          <mat-form-field appearance="outline" class="reason-textarea">
            <mat-label>Reason...</mat-label>
            <textarea #reason matInput formControlName="reason"></textarea>
            <mat-error
              *ngIf="applicationFormGroup.get('reason').hasError('required')"
              >Reason is Required</mat-error
            >
          </mat-form-field>
        </div>

        <div class="note-div">
          <div>
            <img class="slot-time-image" src="assets/images/info-icon.svg" />
          </div>
          <div class="note">
            Note, that we take into multiple parameters like leaves,
            performance, doubts and more which affects your TA points
          </div>
        </div>

        <div class="leave-count-div mt-20">
          <div>
            <img class="slot-time-image" src="assets/images/info-icon.svg" />
          </div>
          <div class="note">
            Total Leaves taken during Current TAship <
            {{ from | date : 'dd/MM/yyyy' }} ~ {{ to | date : 'dd/MM/yyyy' }} >
            :
            <span class="leave-count">{{ leaveCount }}</span>
          </div>
        </div>

        <div class="leave-count-div mt-20">
          <div>
            <img class="slot-time-image" src="assets/images/info-icon.svg" />
          </div>
          <div class="note">
            Total Leaves taken this month:
            <span
              class="leave-count"
              *ngIf="leavesTakenThisMonth || totalLeavesAllowedInMonth"
              >{{ leavesTakenThisMonth }}/{{ totalLeavesAllowedInMonth }}</span
            >
          </div>
        </div>

        <div
          class="error-message"
          *ngIf="applicationFormGroup.hasError('error')"
        >
          {{ errorMessage }}
        </div>

        <div class="action-div">
          <button type="reset" (click)="closeModal()">Cancel</button>
          <div class="next-button">
            <button
              (mouseenter)="showErrors()"
              (click)="submitApplication()"
              type="submit"
              class="submit-button loading-button expand"
              [class.disabled]="applicationFormGroup.invalid"
              [class.sending]="isLoading"
            >
              {{ isLoading ? 'Submitting...' : 'Submit' }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="requestLeaveResult">
    <mentor-ta-leave-response
      [requestLeaveResult]="requestLeaveResult"
    ></mentor-ta-leave-response>
  </div>
</div>
