export interface MentorUser {
  id: number;
  email: string;
  name: string;
  image: string;
  roles: string[];
  node_access_token: string;
  has_offline_module_access: boolean;
  status: 'online' | 'offline' | 'away';
  as_employee_online: boolean;
  active_doubts: number;
  today_start_time: string;
  today_end_time: string;
  week_off_day: string;
  phone_number: string;
  tooltip: string;
  banner_status: string;
  project_present: boolean;
  doubt_points_enabled: boolean;
  incomplete_content_count: number;
}

export const UserRoles = {
  ADMIN: 'admin',
  DOUBTS_TEAM: 'Doubts Team',
  DOUBTS_INTERN: 'Doubts Intern',
  DOUBTS_MANAGER: 'Doubts Manager',
  STUDENT_EXPERIENCE_TEAM: 'Students Experience Team',
  STUDENT_EXP_MANAGER: 'Student Experience Manager',
  CAREER_CAMP_STUD_EXP_TASK_TEAM: 'Career Camp Student Experience Task Team',
  STUD_EXP_TASK_TEAM: 'Student Experience Task Team',
  WHATSAPP_MESSAGE_ROLE: 'whatsapp_message_role',
  PROJECT_EVALUATOR: 'Project Evaluator',
  TA: 'ta',
};
