<div class="monthly-shareable-container">
  <div class="image-container">
    <img [src]="certificateData.certificate_image_link" />
  </div>
  <div class="social-link-container">
    <div class="share-text">Share on:</div>
    <div class="social-buttons">
      <shared-ui-linkedin-button
        [ninjasPopover]="linkedinSocialButtonPopoverTemplate"
        [open]="linkedinPopoverVisible"
        [position]="'top-left'"
        [allowRepositioningOnScroll]="true"
        popoverClass="social-share-popover"
        [color]="'#fbfdff'"
        (mouseenter)="linkedinPopoverVisible = true"
        (mouseleave)="linkedinPopoverVisible = false"
        (click)="shareOnSocial('LinkedIn')"
      ></shared-ui-linkedin-button>
      <shared-ui-whatsapp-button
        [ninjasPopover]="whatsappSocialButtonPopoverTemplate"
        [open]="whatsappPopoverVisible"
        [position]="'top-left'"
        [allowRepositioningOnScroll]="true"
        popoverClass="social-share-popover"
        [color]="'#fbfdff'"
        (mouseenter)="whatsappPopoverVisible = true"
        (mouseleave)="whatsappPopoverVisible = false"
        (click)="shareOnSocial('Whatsapp')"
      ></shared-ui-whatsapp-button>
      <ng-template #linkedinSocialButtonPopoverTemplate>
        <div class="social-popover-inner-container">
          Share your achievement card on LinkedIn
        </div>
      </ng-template>
      <ng-template #whatsappSocialButtonPopoverTemplate>
        <div class="social-popover-inner-container">
          Share your achievement card on Whatsapp
        </div>
      </ng-template>
    </div>
  </div>
</div>
