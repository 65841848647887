<ng-container *ngIf="theme === 'light'">
  <div class="flex-row gap-12">
    <button
      *ngIf="notification.has_primary_cta"
      zen-secondary-cta
      size="small"
      (click)="
        openUrl(
          notification.content.primary_cta_url,
          notification.content.primary_cta_text
        )
      "
    >
      {{ notification.content.primary_cta_text | sentenceCase }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="theme === 'dark'">
  <div class="flex-row gap-12">
    <button
      *ngIf="notification.has_primary_cta"
      zen-primary-cta
      size="small"
      (click)="
        openUrl(
          notification.content.primary_cta_url,
          notification.content.primary_cta_text
        )
      "
    >
      {{ notification.content.primary_cta_text | sentenceCase }}
    </button>
    <button
      *ngIf="notification.has_secondary_cta"
      class="override-hover"
      zen-secondary-inverted-cta
      size="small"
      (click)="
        openUrl(
          notification.content.secondary_cta_url,
          notification.content.secondary_cta_text
        )
      "
    >
      {{ notification.content.secondary_cta_text | sentenceCase }}
    </button>
  </div>
</ng-container>
