import {
  OriginConnectionPosition,
  OverlayConnectionPosition,
} from '@angular/cdk/overlay';

export type PopoverPosition =
  | 'bottom'
  | 'top'
  | 'left'
  | 'right'
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

export const originConnectPosition = (
  position: PopoverPosition
): OriginConnectionPosition => {
  switch (position) {
    case 'top':
    case 'top-left':
    case 'top-right':
      return {
        originX: 'center',
        originY: 'top',
      };
    case 'bottom':
    case 'bottom-left':
    case 'bottom-right':
      return {
        originX: 'center',
        originY: 'bottom',
      };
    case 'left':
    case 'left-top':
    case 'left-bottom':
      return {
        originX: 'start',
        originY: 'center',
      };
    case 'right':
    case 'right-top':
    case 'right-bottom':
      return {
        originX: 'end',
        originY: 'center',
      };
    default:
      return {
        originX: 'center',
        originY: 'center',
      };
  }
};

export const fallbackOriginConnectPosition = (
  position: OriginConnectionPosition
): OriginConnectionPosition => {
  let originX = position.originX;
  let originY = position.originY;
  if (originY === 'top') {
    originY = 'bottom';
  } else if (originY === 'bottom') {
    originY = 'top';
  }
  if (originX === 'start') {
    originX = 'end';
  } else if (originX === 'end') {
    originX = 'start';
  }
  return {
    originY,
    originX,
  };
};

export const overlayConnectionPosition = (
  position: PopoverPosition
): OverlayConnectionPosition => {
  switch (position) {
    case 'top':
      return {
        overlayX: 'center',
        overlayY: 'bottom',
      };
    case 'top-left':
      return {
        overlayX: 'end',
        overlayY: 'bottom',
      };
    case 'top-right':
      return {
        overlayX: 'start',
        overlayY: 'bottom',
      };
    case 'bottom':
      return {
        overlayX: 'center',
        overlayY: 'top',
      };
    case 'bottom-left':
      return {
        overlayX: 'end',
        overlayY: 'top',
      };
    case 'bottom-right':
      return {
        overlayX: 'start',
        overlayY: 'top',
      };
    case 'left':
      return {
        overlayX: 'end',
        overlayY: 'center',
      };
    case 'left-top':
      return {
        overlayX: 'end',
        overlayY: 'bottom',
      };
    case 'left-bottom':
      return {
        overlayX: 'end',
        overlayY: 'top',
      };
    case 'right':
      return {
        overlayX: 'start',
        overlayY: 'center',
      };
    case 'right-top':
      return {
        overlayX: 'start',
        overlayY: 'bottom',
      };
    case 'right-bottom':
      return {
        overlayX: 'start',
        overlayY: 'top',
      };
    default:
      return {
        overlayX: 'center',
        overlayY: 'center',
      };
  }
};

export const fallbackOverlayConnectionPositon = (
  position: OverlayConnectionPosition
): OverlayConnectionPosition => {
  let overlayX = position.overlayX;
  let overlayY = position.overlayY;
  if (overlayY === 'top') {
    overlayY = 'bottom';
  } else if (overlayY === 'bottom') {
    overlayY = 'top';
  }
  if (overlayX === 'start') {
    overlayX = 'end';
  } else if (overlayX === 'end') {
    overlayX = 'start';
  }
  return {
    overlayX,
    overlayY,
  };
};

export const invertPopoverPosition = (
  position: PopoverPosition
): PopoverPosition => {
  switch (position) {
    case 'bottom':
      return 'top';
    case 'bottom-left':
      return 'top-right';
    case 'bottom-right':
      return 'top-left';
    case 'left':
      return 'right';
    case 'left-bottom':
      return 'right-top';
    case 'left-top':
      return 'right-bottom';
    case 'right':
      return 'left';
    case 'right-bottom':
      return 'left-top';
    case 'right-top':
      return 'left-bottom';
    case 'top':
      return 'bottom';
    case 'top-left':
      return 'bottom-right';
    case 'top-right':
      return 'bottom-left';
  }
};
