export function getQueryParams() {
  const searchString = decodeURIComponent(window.location.search);
  if (searchString.length === 0) {
    return {};
  } else {
    return searchString
      .substring(1)
      .split('&')
      .map((pairString) => pairString.split('='))
      .reduce((out, pair) => {
        out[pair[0]] = pair[1];
        return out;
      }, {});
  }
}
