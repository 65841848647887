// Essential Imports
import {
  Inject,
  Injectable,
  OnDestroy,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// Util Imports
import {
  Devices,
  Breakpoints,
} from '@codingninjas/shared-ui/utils/breakpoints';
import { MOBILES_UAS } from '@codingninjas/shared-ui/constants/device-detector.constants';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DeviceTypeService implements OnDestroy {
  private _destroy$ = new Subject<void>();
  private _deviceType: Devices = Devices.UNKNOWN;
  private _userAgentDeviceType: Devices = Devices.UNKNOWN;

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private _platformId: object,
    @Optional() @Inject('req') private readonly request: any
  ) {
    this._setDeviceType();
    this._setUserAgentDeviceType();
  }

  private _setDeviceType(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.breakpointObserver
        .observe([Breakpoints.DESKTOP, Breakpoints.MOBILE])
        .pipe(takeUntil(this._destroy$))
        .subscribe((_result) => {
          if (this.breakpointObserver.isMatched(Breakpoints.DESKTOP)) {
            this._deviceType = Devices.DESKTOP;
          } else if (this.breakpointObserver.isMatched(Breakpoints.MOBILE)) {
            this._deviceType = Devices.MOBILE;
          } else {
            this._deviceType = Devices.UNKNOWN;
          }
        });
    } else {
      this._deviceType = this.request.isMobile
        ? Devices.MOBILE
        : Devices.DESKTOP;
    }
  }

  private _setUserAgentDeviceType(): void {
    if (isPlatformBrowser(this._platformId)) {
      const userAgent = window.navigator.userAgent;
      this._userAgentDeviceType = this._checkUserAgentDeviceMobile(userAgent)
        ? Devices.MOBILE
        : Devices.DESKTOP;
      return;
    } else {
      this._userAgentDeviceType = this.request.isMobile
        ? Devices.MOBILE
        : Devices.DESKTOP;
    }
  }

  // Getter Methods
  getDeviceType(): Devices {
    return this._deviceType;
  }

  isMobile(): boolean {
    return this._deviceType === Devices.MOBILE;
  }

  isDesktop(): boolean {
    return this._deviceType === Devices.DESKTOP;
  }

  isUAMobile(): boolean {
    return this._userAgentDeviceType === Devices.MOBILE;
  }

  isUADesktop(): boolean {
    return this._userAgentDeviceType === Devices.DESKTOP;
  }

  private _checkUserAgentDeviceMobile(userAgent: string): boolean {
    const match = Object.keys(MOBILES_UAS).find((mobileUA) => {
      let regex: any = MOBILES_UAS[mobileUA];
      if (typeof regex === 'string') {
        regex = new RegExp(regex);
      }
      return regex.test(userAgent);
    });

    return !!match;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
