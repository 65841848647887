<div class="mainContainer">
  <div class="action-container">
    <p class="prompt">Please Login to Continue</p>
    <div class="buttonsContainer">
      <auth-login-signup style="width: 100%"></auth-login-signup>
    </div>
    <p class="promt-error red" *ngIf="logged_out">
      You were logged out as you logged in on some other tab.
    </p>
  </div>
</div>
