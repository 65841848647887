import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  NotificationTheme,
  WebinarActivityNotification,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-webinar-join-activity',
  templateUrl: './webinar-join-activity.component.html',
  styleUrls: ['./webinar-join-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebinarJoinActivityComponent implements OnInit {
  @Input() notification: WebinarActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;

  _actionPerformed = false;
  notificationTitle: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.notification.content.icon_context += '-fill';

    if (this.theme === 'dark') {
      this.notificationTitle = this.notification.content.title;
    } else {
      this.notificationTitle = 'Scheduled webinar';
    }
  }

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }
}
