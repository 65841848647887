import { Inject, Injectable, Optional, TemplateRef } from '@angular/core';
import {
  NINJAS_NOTIFICATION_CONFIG,
  NINJAS_NOTIFICATION_DEFAULT_CONFIG,
  MinifiedNotificationConfig,
} from './minified-notification.config';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  MinifiedNotification,
  NinjasNotificationOptions,
  NinjasNotificationType,
} from './minified-notification';

@Injectable({
  providedIn: 'root',
})
export class MinifiedNotificationService {
  private _actionsSource: BehaviorSubject<NotificationActions> =
    new BehaviorSubject<NotificationActions>(null);
  $actions: Observable<NotificationActions> =
    this._actionsSource.asObservable();

  config: MinifiedNotificationConfig = {};

  constructor(
    @Optional()
    @Inject(NINJAS_NOTIFICATION_DEFAULT_CONFIG)
    defaultConfig: MinifiedNotificationConfig,
    @Optional()
    @Inject(NINJAS_NOTIFICATION_CONFIG)
    config: MinifiedNotificationConfig
  ) {
    this.setConfig({ ...defaultConfig, ...config });
  }

  private setConfig(config: MinifiedNotificationConfig): void {
    this.config = { ...this.config, ...config };
  }

  getConfig(): MinifiedNotificationConfig {
    return this.config;
  }

  notify(
    type,
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ) {
    switch (type) {
      case 'success':
        this.success(title, content, options);
        break;
      case 'info':
        this.info(title, content, options);
        break;
      case 'warning':
        this.warning(title, content, options);
        break;
      case 'error':
        this.error(title, content, options);
        break;
    }
  }

  success(
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ): MinifiedNotification {
    return this.create('success', title, content, options);
  }

  error(
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ): MinifiedNotification {
    return this.create('error', title, content, options);
  }

  info(
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ): MinifiedNotification {
    return this.create('info', title, content, options);
  }

  warning(
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ): MinifiedNotification {
    return this.create('warning', title, content, options);
  }

  blank(
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ): MinifiedNotification {
    return this.create('blank', title, content, options);
  }

  create(
    type: NinjasNotificationType,
    title: string,
    content: string,
    options?: NinjasNotificationOptions
  ): MinifiedNotification {
    const noti = new MinifiedNotification(title, type)
      .setOptions(options)
      .setMessage(content);
    this._actionsSource.next(new Add(noti));
    return noti;
  }

  // For content with template
  // template(template: TemplateRef<{}>, options?: NinjasNotificationOptions): MinifiedNotification {
  //   return this.createMessage({ template }, options);
  // }

  removeNotification(notificationId: string) {
    this._actionsSource.next(new Remove(notificationId));
  }

  clearAll() {
    this._actionsSource.next(new ClearAll());
  }
}

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_ALL = 'CLEAR_ALl';
export class Add {
  type = ADD_NOTIFICATION;
  constructor(public payload: MinifiedNotification) {}
}

export class Remove {
  type = REMOVE_NOTIFICATION;
  constructor(public payload: string) {}
}

export class ClearAll {
  type = CLEAR_ALL;
  payload = null;
}

export type NotificationActions = Add | Remove | ClearAll;
