import {
  Component,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
} from '@angular/core';
import { CustomNinjasNotification } from '../../custom-ninja-notification';
import { ExpandReportNotificationAnimation } from './custom-notification-actionable.animation';

@Component({
  selector: 'codingninjas-custom-notification-actionable',
  animations: [ExpandReportNotificationAnimation],
  templateUrl: './custom-notification-actionable.component.html',
  styleUrls: ['./custom-notification-actionable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNotificationActionableComponent {
  @Input() showActionables: boolean;
  @Input() notification: CustomNinjasNotification;
  @Output() closeEvent = new EventEmitter();
  @Output() collapseActionableEvent = new EventEmitter();

  get expandNotificationState(): string {
    switch (this.showActionables) {
      case true:
        return 'expand';
      case false:
        return 'collapse';
    }
  }

  collapseActionable() {
    this.collapseActionableEvent.emit();
  }

  close(executeAfterCloseCallback: boolean) {
    this.closeEvent.emit(executeAfterCloseCallback);
  }

  notificationActionConfirmed() {
    this.close(false);
    const callbackEvent = this.notification.actionableClickCallback;
    callbackEvent.call(this);
  }
}
