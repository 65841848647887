import { Action } from '@ngrx/store';
import {
  AuthData,
  AuthResponse,
  BasicUserDetails,
  ReverseAutoLoginState,
  CollegeValidationDetailsState,
} from './';
import { NetworkingError } from '@codingninjas/networking';
import { SocialAuthData, SocialProvider } from '../social-login';
import { NinjasLoginCredentials } from './models/ninjas-login-credentials.model';
import { AuthUser } from '@codingninjas/auth';

export enum AuthActionTypes {
  SocialAuth = '[Auth] SocialAuth',
  SocialRedirectLogin = '[Auth] SocialRedirectLogin',
  NinjasLogin = '[Auth] NinjasLogin',
  Authenticated = '[Auth] Authenticated',
  Authenticating = '[Auth] Authenticating',
  AuthenticationError = '[Auth] AuthenticationError',
  ResetAuthenticationError = '[Auth] ResetAuthenticationError',
  Logout = '[Auth] Logout',
  LocalLogout = '[Auth] Local Logout',
  PreAuth = '[Auth] PreAuth',
  SetUser = 'SetUser',
  SetUserProfileImage = 'SetUserProfileImage',
  SetUserPhoneNumber = 'SetUserPhoneNumber',
  SetEmailVerificationAndBasicDetails = 'SetEmailVerificationAndBasicDetails',
  SetAuthAndUser = 'SetAuthAndUser',
  FBMergeState = '[Auth] FBMergeState',
  ChangeUserState = 'ChangeUserState',
  TriggerReverseAutoLogin = '[Auth] TriggerReverseAutoLogin',
  SetCollegeValidationDetails = '[Auth] SetCollegeValidationDetails',
}

export class SocialAuth implements Action {
  readonly type = AuthActionTypes.SocialAuth;

  constructor(
    public payload: SocialProvider,
    public origin: string = '',
    public token: string = ''
  ) {}
}

export class NinjasLogin implements Action {
  readonly type = AuthActionTypes.NinjasLogin;

  constructor(public payload: NinjasLoginCredentials) {}
}

export class Authenticating implements Action {
  readonly type = AuthActionTypes.Authenticating;
}

export class Authenticated implements Action {
  readonly type = AuthActionTypes.Authenticated;

  constructor(public payload: AuthResponse) {}
}

export class FBMergeState implements Action {
  readonly type = AuthActionTypes.FBMergeState;

  constructor(
    public state: number,
    public email: string,
    public token: string
  ) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LocalLogout implements Action {
  readonly type = AuthActionTypes.LocalLogout;
}

export class AuthenticationError implements Action {
  readonly type = AuthActionTypes.AuthenticationError;

  constructor(public payload: NetworkingError) {}
}

export class ResetAuthenticationError implements Action {
  readonly type = AuthActionTypes.ResetAuthenticationError;

  constructor() {}
}

export class PreAuth implements Action {
  readonly type = AuthActionTypes.PreAuth;

  constructor(public payload: AuthResponse) {}
}

export class SetUser implements Action {
  readonly type = AuthActionTypes.SetUser;

  constructor(public payload: AuthUser) {}
}

export class SetUserProfileImage implements Action {
  readonly type = AuthActionTypes.SetUserProfileImage;

  constructor(public payload: string) {}
}

export class SetAuthAndUser implements Action {
  readonly type = AuthActionTypes.SetAuthAndUser;
  constructor(
    public payload: { user_access_token: string; authUser: AuthUser }
  ) {}
}

export class SetUserPhoneNumber implements Action {
  readonly type = AuthActionTypes.SetUserPhoneNumber;

  constructor(
    public payload: { phone_number: string; phone_country_code: string }
  ) {}
}

export class SetEmailVerificationAndBasicDetails implements Action {
  readonly type = AuthActionTypes.SetEmailVerificationAndBasicDetails;

  constructor(
    public payload: {
      naukriUnverified: boolean;
      basicDetails: BasicUserDetails;
      classroomPaid?: boolean;
      naukriFlowUser?: number;
      registrationUtmSource?: string;
      registrationUtmMedium?: string;
      registrationUtmCampaign?: string;
      sourcePageUrl?: string;
      sessionStartPageUrl?: string;
    }
  ) {}
}

export class ChangeUserState implements Action {
  readonly type = AuthActionTypes.ChangeUserState;
  constructor(public data: AuthUser) {}
}

export class SocialRedirectLogin implements Action {
  readonly type = AuthActionTypes.SocialRedirectLogin;

  constructor(public data: SocialAuthData) {}
}

export class TriggerReverseAutoLogin implements Action {
  readonly type = AuthActionTypes.TriggerReverseAutoLogin;

  constructor(public payload: ReverseAutoLoginState) {}
}

export class SetCollegeValidationDetails implements Action {
  readonly type = AuthActionTypes.SetCollegeValidationDetails;

  constructor(public payload: CollegeValidationDetailsState) {}
}

export type AuthActions =
  | SocialAuth
  | NinjasLogin
  | Authenticating
  | Authenticated
  | LocalLogout
  | Logout
  | AuthenticationError
  | ResetAuthenticationError
  | PreAuth
  | SocialRedirectLogin
  | SetUser
  | SetUserProfileImage
  | SetUserPhoneNumber
  | SetAuthAndUser
  | SetEmailVerificationAndBasicDetails
  | ChangeUserState
  | FBMergeState
  | TriggerReverseAutoLogin
  | SetCollegeValidationDetails;
