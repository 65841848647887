<ng-container *ngIf="theme === 'light'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-secondary-cta
    size="small"
    (click)="
      openUrl(
        notification.content.primary_cta_url,
        notification.content.primary_cta_text
      )
    "
  >
    <span class="flex-row gap-8">
      {{ notification.content.primary_cta_text | sentenceCase }}
      <mat-icon
        fontSet="zen-icon"
        fontIcon="icon-arrow-top-right"
        class="icon"
      ></mat-icon>
    </span>
  </button>
</ng-container>
<ng-container *ngIf="theme === 'dark'">
  <div class="flex-row gap-8">
    <button
      *ngIf="notification.has_primary_cta"
      zen-underlined-text-cta
      size="small"
      (click)="
        openUrl(
          notification.content.primary_cta_url,
          notification.content.primary_cta_text
        )
      "
    >
      <span>{{ notification.content.primary_cta_text | sentenceCase }}</span>
    </button>

    <mat-icon
      class="arrow-icon"
      fontSet="zen-icon"
      fontIcon="icon-arrow-top-right"
    ></mat-icon>
  </div>
</ng-container>
