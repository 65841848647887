import { Inject, Injectable, Optional, TemplateRef } from '@angular/core';
import {
  NINJAS_NOTIFICATION_CONFIG,
  NINJAS_NOTIFICATION_DEFAULT_CONFIG,
  NinjasNotificationConfig
} from './ninjas-notification.config';
import { BehaviorSubject, Observable } from 'rxjs';
import { NinjasNotification, NinjasNotificationOptions, NinjasNotificationType } from './ninjas-notification';

@Injectable({
  providedIn: 'root'
})
export class NinjasNotificationService {

  private _actionsSource: BehaviorSubject<NotificationActions> = new BehaviorSubject<NotificationActions>(null);
  $actions: Observable<NotificationActions> = this._actionsSource.asObservable();

  config: NinjasNotificationConfig = {};

  constructor(@Optional() @Inject(NINJAS_NOTIFICATION_DEFAULT_CONFIG) defaultConfig: NinjasNotificationConfig,
              @Optional() @Inject(NINJAS_NOTIFICATION_CONFIG) config: NinjasNotificationConfig) {
    this.setConfig({ ...defaultConfig, ...config });
  }

  private setConfig(config: NinjasNotificationConfig): void {
    this.config = { ...this.config, ...config };
  }

  getConfig(): NinjasNotificationConfig{
    return this.config;
  }

  success(title: string, content: string, options?: NinjasNotificationOptions): NinjasNotification {
    return this.create( 'success', title, content, options);
  }

  error(title: string, content: string, options?: NinjasNotificationOptions): NinjasNotification {
    return this.create( 'error', title, content, options);
  }

  info(title: string, content: string, options?: NinjasNotificationOptions): NinjasNotification {
    return this.create( 'info', title, content, options);
  }

  warning(title: string, content: string, options?: NinjasNotificationOptions): NinjasNotification {
    return this.create( 'warning', title, content, options);
  }

  blank(title: string, content: string, options?: NinjasNotificationOptions): NinjasNotification {
    return this.create( 'blank', title, content, options);
  }

  create(type: NinjasNotificationType, title: string, content: string, options?: NinjasNotificationOptions): NinjasNotification {
    const noti = new NinjasNotification(title,type)
      .setOptions(options)
      .setMessage(content);
    this._actionsSource.next(new Add(noti));
    return noti;
  }

  // For content with template
  // template(template: TemplateRef<{}>, options?: NinjasNotificationOptions): NinjasNotification {
  //   return this.createMessage({ template }, options);
  // }

  removeNotification(notificationId: string){
    this._actionsSource.next(new Remove(notificationId));
  }

  clearAll(){
    this._actionsSource.next(new ClearAll());
  }


}


export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const CLEAR_ALL = "CLEAR_ALl";
export class Add {
  type = ADD_NOTIFICATION;
  constructor(public payload:NinjasNotification){}
}

export class Remove {
  type = REMOVE_NOTIFICATION;
  constructor(public payload:string){}
}

export class ClearAll {
  type = CLEAR_ALL;
  payload = null;
}

export type NotificationActions = Add | Remove | ClearAll;



