<div [ngSwitch]="_loginForm">
  <ng-container *ngSwitchCase="LOGIN_FORM.INITIAL">
    <auth-initial-login-form
      [loginData]="loginData"
      [showOptInCheckBox]="showOptInCheckBox"
      (loginDataChange)="updateLoginData($event)"
      (formChange)="changeForm($event)"
    ></auth-initial-login-form>
  </ng-container>

  <ng-container *ngSwitchCase="LOGIN_FORM.NEW_USER">
    <auth-user-login-form
      [setLoginData]="loginData"
      [isNewUser]="true"
      (backPress)="changeForm(LOGIN_FORM.INITIAL)"
      [showOptInCheckBox]="showOptInCheckBox"
    ></auth-user-login-form>
  </ng-container>

  <ng-container *ngSwitchCase="LOGIN_FORM.REGISTERED_USER_WITH_PASSWORD">
    <auth-user-login-form
      [setLoginData]="loginData"
      [userHasPassword]="true"
      (backPress)="changeForm(LOGIN_FORM.INITIAL)"
      [showOptInCheckBox]="showOptInCheckBox"
    ></auth-user-login-form>
  </ng-container>

  <ng-container *ngSwitchCase="LOGIN_FORM.REGISTERED_USER_WITHOUT_PASSWORD">
    <auth-user-login-form
      [setLoginData]="loginData"
      [userHasPassword]="false"
      (backPress)="changeForm(LOGIN_FORM.INITIAL)"
      [showOptInCheckBox]="showOptInCheckBox"
    ></auth-user-login-form>
  </ng-container>
</div>
