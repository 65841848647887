import { MyActivityNotificationModel } from '../constants/my-activities-notification.constants';

export class MyActivityNotification {
  private static counter = 0;
  private _notification: MyActivityNotificationModel;
  private _onAfterCloseCallback: EventCallback;
  private readonly _id: string;
  private _options: MyActivityNotificationOptions = {};
  public state?: 'enter' | 'leave';

  public constructor(notification: MyActivityNotificationModel) {
    this._notification = notification;
    this._id = 'notification-' + MyActivityNotification.counter++;
  }

  public setOptions(
    options: MyActivityNotificationOptions
  ): MyActivityNotification {
    this._options = options;
    return this;
  }

  get notificationId(): string {
    return this._id;
  }

  get notification(): MyActivityNotificationModel {
    return this._notification;
  }

  get options(): MyActivityNotificationOptions {
    return this._options;
  }

  // On Dismiss Callback
  public afterClosed(callback: EventCallback): MyActivityNotification {
    this._onAfterCloseCallback = callback;
    return this;
  }

  get afterClosedCallback(): EventCallback {
    return this._onAfterCloseCallback;
  }
}

type EventCallback = (event: Event) => void;

export interface MyActivityNotificationOptions {
  duration?: number;
  animate?: boolean;
  pauseOnHover?: boolean;
  audio?: string;
  showClose?: boolean;
}
