import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AttendInterviewActivityNotification,
  NotificationTheme,
} from '../../../../../constants/my-activities-notification.constants';
import { MyActivitiesNotificationService } from '@codingninjas/notification';

@Component({
  selector: 'codingninjas-attend-interview-session-action',
  templateUrl: './attend-interview-session-action.component.html',
  styleUrls: ['./attend-interview-session-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendInterviewSessionActionComponent {
  @Input() notification: AttendInterviewActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;
  @Output() ctaClicked = new EventEmitter();

  constructor(
    private myActivityNotificationService: MyActivitiesNotificationService
  ) {}

  openUrl(url, ctaText) {
    window.open(url, '_self');
    this.ctaClicked.emit();
    this.myActivityNotificationService.triggerCTAClickAnalytics(
      ctaText,
      this.notification,
      this.source
    );
  }
}
