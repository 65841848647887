import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { AuthState } from '@codingninjas/auth';
import { Subject } from 'rxjs';
import { AuthQuery } from '../../+state/auth.selector';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from '../../service/analytics.service';

declare let window: any;

@Component({
  selector: 'auth-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    private authState: Store<AuthState>,
    private analyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      userEmail: string;
      userName: string;
      loginFormUserStatus: number;
      loginType: string;
    }
  ) {
    this.authState
      .pipe(select(AuthQuery.getIfAuthenticated), takeUntil(this.destroy$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          this.dialogRef.close();
        }
      });
  }

  ngOnInit() {
    this.sendLoginPopUpEvent();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  close() {
    if (!!window.google) {
      window.google.accounts.id.cancel();
    }
    this.dialogRef.close('cross_clicked');
  }

  sendLoginPopUpEvent() {
    this.analyticsService.sendEvent({
      name: 'Login Popup Shown',
      category: 'Login',
      action: 'Login Popup Shown',
    });
  }
}
