import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthData } from './auth.reducer';

const selectAuth = createFeatureSelector<AuthData>('auth');

const getIfAuthenticated = createSelector(
  selectAuth,
  (auth: AuthData) => auth.isAuthenticated
);

const getIfNewUser = createSelector(
  selectAuth,
  (auth: AuthData) => auth.new_user
);
const getIfAuthenticating = createSelector(
  selectAuth,
  (auth: AuthData) => auth.isAuthenticating
);
const getAuthError = createSelector(selectAuth, (auth: AuthData) => auth.error);
const getUser = createSelector(selectAuth, (auth: AuthData) => auth.user);

const getProvider = createSelector(
  selectAuth,
  (auth: AuthData) => auth.provider
);

const getToken = createSelector(
  selectAuth,
  (auth: AuthData) => auth.user_access_token
);

const getFbMergeState = createSelector(
  selectAuth,
  (auth: AuthData) => auth.fbMergeState
);

const getFbMergeEmail = createSelector(
  selectAuth,
  (auth: AuthData) => auth.fbMergeEmail
);

const getFbMergeToken = createSelector(
  selectAuth,
  (auth: AuthData) => auth.fbMergeToken
);

const getReverseAutoLoginState = createSelector(
  selectAuth,
  (auth: AuthData) => auth.reverse_auto_login_triggered
);

const getContestValidationDetails = createSelector(
  selectAuth,
  (auth: AuthData) => {
    return {
      naukri_mobile_present: auth.naukri_mobile_present,
      naukri_gender_present: auth.naukri_gender_present,
      naukri_college_present: auth.naukri_college_present,
    };
  }
);

export const AuthQuery = {
  getIfAuthenticated,
  getIfNewUser,
  getIfAuthenticating,
  getAuthError,
  getUser,
  getProvider,
  getToken,
  getFbMergeState,
  getFbMergeEmail,
  getFbMergeToken,
  getReverseAutoLoginState,
  getContestValidationDetails,
};
