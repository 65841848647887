<div class="activity-container">
  <div [class]="theme" class="flex-row gap-8">
    <div class="left">
      <codingninjas-my-activity-icon
        [iconType]="notification.content.icon_type"
        [iconContext]="notification.content.icon_context"
        [fallbackIcon]="'icon-company-2'"
      ></codingninjas-my-activity-icon>
    </div>
    <div class="right flex-column gap-12 flex-1">
      <div>
        <div
          class="notification-header flex-row justify-content-space-between align-items-center"
        >
          <div
            style="max-width: 232px"
            class="job-title zen-typo-subtitle-small"
          >
            {{ notification.content.title }}
          </div>
          <codingninjas-my-activity-header-info
            *ngIf="theme === 'light'"
            [notification]="notification"
            [actionPerformed]="_actionPerformed"
          ></codingninjas-my-activity-header-info>
        </div>

        <div
          class="job-info pt-4 zen-typo-caption-medium flex-row gap-2 align-items-center"
        >
          <div class="ellipsis">
            {{ notification.content.position }}
          </div>
          <div class="flex-row align-items-center">
            <mat-icon
              style="width: 12px; height: 12px; font-size: 12px"
              class="separator-icon"
              fontSet="zen-icon"
              fontIcon="icon-circle-seprator"
            ></mat-icon>
          </div>
          <div class="ellipsis">
            <span class="job-company">
              {{ notification.content.company }},
            </span>
            <span class="job-location">
              {{ notification.content.location }}
            </span>
          </div>
        </div>
        <div class="flex-row gap-2 align-items-center pt-4">
          <div class="job-ctc zen-typo-caption-bold">
            {{ notification.content.ctc }}
          </div>
          <div class="flex-row align-items-center">
            <mat-icon
              style="width: 12px; height: 12px; font-size: 12px"
              class="separator-icon"
              fontSet="zen-icon"
              fontIcon="icon-circle-seprator"
            ></mat-icon>
          </div>
          <div class="job-deadline flex-row align-items-center">
            <div class="zen-typo-caption-medium">
              {{ notification.content.end_time_key_name }}
            </div>
            &nbsp;
            <div class="zen-typo-caption-bold">
              {{ notification.end_time | date : 'd MMM, y' }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <codingninjas-deadline-job-actions
          [theme]="theme"
          [notification]="notification"
          (ctaClicked)="actionPerformed()"
          [source]="source"
        ></codingninjas-deadline-job-actions>
      </div>
    </div>
  </div>
</div>
