import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TaJobActivityComponent } from './my-activity-notifications/ta-job-activity/ta-job-activity.component';
import { TaJobActionsComponent } from './my-activity-notifications/ta-job-activity/ta-job-actions/ta-job-actions.component';
import { DeadlineJobActivityComponent } from './my-activity-notifications/deadline-job-activity/deadline-job-activity.component';
import { DeadlineJobActionsComponent } from './my-activity-notifications/deadline-job-activity/deadline-job-actions/deadline-job-actions.component';
import { RegularJobActivityComponent } from './my-activity-notifications/regular-job-activity/regular-job-activity.component';
import { RegularJobActionsComponent } from './my-activity-notifications/regular-job-activity/regular-job-actions/regular-job-actions.component';
import { MyActivityIconComponent } from './my-activity-notifications/my-activity-icon/my-activity-icon.component';
import { MyActivityNotificationBaseComponent } from './my-activity-notification-base.component';
import { MonthlyRecapActivityComponent } from './my-activity-notifications/monthly-recap-activity/monthly-recap-activity.component';
import { MonthlyRecapActionsComponent } from './my-activity-notifications/monthly-recap-activity/monthly-recap-actions/monthly-recap-actions.component';
import { ShareTestimonialActivityComponent } from './my-activity-notifications/share-testimonial-activity/share-testimonial-activity.component';
import { ShareTestimonialActionsComponent } from './my-activity-notifications/share-testimonial-activity/share-testimonial-actions/share-testimonial-actions.component';
import { ActivitySwitcherComponent } from './my-activity-notifications/activity-switcher/activity-switcher.component';
import { MyActivityHeaderInfoComponent } from './my-activity-notifications/my-activity-header-info/my-activity-header-info.component';
import { ZenCtaModule } from '../../../../ninjas-utils/src/lib/zen-components/button/cta.module';
import { LottieModule } from 'ngx-lottie';
import { WebinarRegistrationActivityComponent } from './my-activity-notifications/webinar-register-activity/webinar-registration-activity.component';
import { WebinarRegistrationActionsComponent } from './my-activity-notifications/webinar-register-activity/webinar-registration-actions/webinar-registration-actions.component';
import { WebinarJoinActivityComponent } from './my-activity-notifications/webinar-join-activity/webinar-join-activity.component';
import { WebinarJoinActionsComponent } from './my-activity-notifications/webinar-join-activity/webinar-join-actions/webinar-join-actions.component';
import { BookInterviewSessionActivityComponent } from './my-activity-notifications/book-interview-session-activity/book-interview-session-activity.component';
import { BookInterviewSessionActionComponent } from './my-activity-notifications/book-interview-session-activity/book-interview-session-action/book-interview-session-action.component';
import { AttendInterviewSessionActivityComponent } from './my-activity-notifications/attend-interview-session-activity/attend-interview-session-activity.component';
import { AttendInterviewSessionActionComponent } from './my-activity-notifications/attend-interview-session-activity/attend-interview-session-action/attend-interview-session-action.component';
import { NewSkillsUnlockedActivityComponent } from './my-activity-notifications/new-skills-unlocked-activity/new-skills-unlocked-activity.component';
import { NewSkillsUnlockedActionsComponent } from './my-activity-notifications/new-skills-unlocked-activity/new-skills-unlocked-actions/new-skills-unlocked-actions.component';
import { NinjasPipesModule } from '@codingninjas/ninjas-pipes';
import { RemindReferredUserActivityComponent } from './my-activity-notifications/remind-referred-user-activity/remind-referred-user-activity.component';
import { RemindReferredUserActionsComponent } from './my-activity-notifications/remind-referred-user-activity/remind-referred-user-actions/remind-referred-user-actions.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    ZenCtaModule,
    LottieModule,
    NinjasPipesModule,
  ],
  declarations: [
    TaJobActivityComponent,
    TaJobActionsComponent,
    DeadlineJobActivityComponent,
    DeadlineJobActionsComponent,
    RegularJobActivityComponent,
    RegularJobActionsComponent,
    MyActivityIconComponent,
    MyActivityNotificationBaseComponent,
    MonthlyRecapActivityComponent,
    MonthlyRecapActionsComponent,
    ShareTestimonialActivityComponent,
    ShareTestimonialActionsComponent,
    ActivitySwitcherComponent,
    MyActivityHeaderInfoComponent,
    WebinarRegistrationActivityComponent,
    WebinarRegistrationActionsComponent,
    WebinarJoinActivityComponent,
    WebinarJoinActionsComponent,
    BookInterviewSessionActivityComponent,
    BookInterviewSessionActionComponent,
    AttendInterviewSessionActivityComponent,
    AttendInterviewSessionActionComponent,
    NewSkillsUnlockedActivityComponent,
    NewSkillsUnlockedActionsComponent,
    RemindReferredUserActivityComponent,
    RemindReferredUserActionsComponent,
  ],
  exports: [
    TaJobActivityComponent,
    DeadlineJobActivityComponent,
    RegularJobActivityComponent,
    MyActivityNotificationBaseComponent,
    MonthlyRecapActivityComponent,
    ShareTestimonialActivityComponent,
    RemindReferredUserActivityComponent,
    ActivitySwitcherComponent,
  ],
})
export class MyActivityNotificationsBaseModule {}
