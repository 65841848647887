<div class="weekoff-slack-outer-wrapper">
  <div class="slot-time-wrapper" *ngIf="mentorUser">
    <div class="slot-image-image-div">
      <img class="slot-time-image" src="assets/images/calender-shape.svg" />
    </div>
    <div class="slot-time-inner-div">
      <div class="slot-weekoff-div">
        <div
          class="slot-time-text"
          *ngIf="mentorUser.today_start_time && mentorUser.today_end_time"
        >
          Slot timing:
          <span class="slot-hour-text"
            >{{ mentorUser.today_start_time }} -
            {{ mentorUser.today_end_time }}</span
          >
        </div>
        <div
          class="online-status"
          *ngIf="mentorUser.today_start_time && mentorUser.today_end_time"
        ></div>
        <div class="week-off-text">
          Week off: <span class="week-off">{{ mentorUser.week_off_day }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- <mentor-slack [slackData]="slackData" *ngIf="slackData"></mentor-slack> -->
</div>
