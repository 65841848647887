import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { flatMap, map, skip, take } from 'rxjs/operators';
import {
  MentorData,
  MentorState,
} from '../+state/mentor/reducers/mentor.reducer';
import { Observable, of } from 'rxjs';
import { CoreService } from '../core.service';
import { MentorUser, UserRoles } from '../+state/mentor/models/mentor-user';
import { Resource } from '@codingninjas/networking';
import { MentorUserResponse } from '../+state/mentor/models/mentor-user.response';
import { MentorUserFetched } from '../+state/mentor/actions/mentor.actions';

@Injectable()
export class MentorGuard implements CanActivate, CanActivateChild {
  constructor(
    private apiService: CoreService,
    private store: Store<MentorState>,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkRoles();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkRoles();
  }

  checkRoles(): Observable<boolean> {
    return this.store.pipe(
      select('mentor'),
      take(1),
      flatMap((data: MentorData) => {
        if (!data.mentorUser) {
          const mentorUserObservable: Observable<MentorUser> = this.apiService
            .fetchMentorUser()
            .pipe(
              skip(1),
              map((resource: Resource<MentorUserResponse>) => {
                if (resource.isSuccessful()) {
                  const mentorUser = resource.data.mentor_user;
                  this.store.dispatch(new MentorUserFetched(mentorUser));
                  return mentorUser;
                } else {
                  return null;
                }
              })
            );
          return mentorUserObservable;
        } else {
          return of(data.mentorUser);
        }
      }),
      map((user: MentorUser) => {
        if (user) {
          if (user.roles && user.roles.length > 0) {
            return true;
          } else {
            this.router.navigate(['/unauthorised']);
          }
        } else {
          this.router.navigate(['/500']);
        }
        return false;
      })
    );
  }
}
