<div class="activity-container">
  <div [class]="theme" class="flex-row gap-8">
    <div class="left">
      <codingninjas-my-activity-icon
        [iconType]="notification.content.icon_type"
        [iconContext]="notification.content.icon_context"
      ></codingninjas-my-activity-icon>
    </div>
    <div class="right flex-column gap-12 flex-1">
      <div>
        <div
          class="notification-header flex-row justify-content-space-between align-items-center"
        >
          <div
            style="max-width: 232px"
            class="job-title zen-typo-subtitle-small"
          >
            {{ notification.content.title }}
          </div>
          <codingninjas-my-activity-header-info
            *ngIf="theme === 'light'"
            [notification]="notification"
            [actionPerformed]="_actionPerformed"
          ></codingninjas-my-activity-header-info>
        </div>

        <div
          class="job-info pt-4 zen-typo-caption-medium flex-row gap-2 align-items-center"
        >
          <div>Share your achievements with the world</div>
        </div>
      </div>
      <div>
        <codingninjas-monthly-recap-actions
          [theme]="theme"
          [notification]="notification"
          (ctaClicked)="actionPerformed()"
          [source]="source"
        ></codingninjas-monthly-recap-actions>
      </div>
    </div>
  </div>
</div>
