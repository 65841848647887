import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DurationPipe,
  OrdinalPipe,
  PeriodPipe,
  RoundDownToNPipe,
  SafePipe,
  DateRangePipe,
  Replace,
  RandomNumber,
  SentenceCasePipe,
  RoundToNDigitisPipe,
  ReverseArrPipe,
} from './pipes';
import { TrimContent } from './pipes/trim.pipe';

@NgModule({
  declarations: [
    PeriodPipe,
    SafePipe,
    DurationPipe,
    RoundDownToNPipe,
    OrdinalPipe,
    TrimContent,
    DateRangePipe,
    Replace,
    RandomNumber,
    SentenceCasePipe,
    RoundToNDigitisPipe,
    ReverseArrPipe,
  ],
  imports: [CommonModule],
  exports: [
    PeriodPipe,
    SafePipe,
    DurationPipe,
    RoundDownToNPipe,
    OrdinalPipe,
    TrimContent,
    DateRangePipe,
    Replace,
    RandomNumber,
    SentenceCasePipe,
    RoundToNDigitisPipe,
    ReverseArrPipe,
  ],
})
export class NinjasPipesModule {}
