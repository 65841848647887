import { Pipe, PipeTransform } from '@angular/core';

// tslint:disable-next-line:use-pipe-decorator
@Pipe({
  name: 'sentenceCase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    const sentences = value.split('. ');
    const sentenceCaseSentences = sentences.map((sentence) => {
      if (sentence.length > 0) {
        const firstChar = sentence.charAt(0).toUpperCase();
        const restOfSentence = sentence.slice(1).toLowerCase();
        return firstChar + restOfSentence;
      }
      return sentence;
    });

    return sentenceCaseSentences.join('. ');
  }
}
