import { NgModule } from '@angular/core';
import { NavbarComponent } from './navbar.component';
import { FuseSharedModule } from '../../../../../@fuse/shared.module';
import { NavbarVerticalStyle1Module } from './vertical/style-1/style-1.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [FuseSharedModule, NavbarVerticalStyle1Module],
  exports: [NavbarComponent],
})
export class NavbarModule {}
