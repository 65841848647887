import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'codingninjas-my-activity-icon',
  templateUrl: './my-activity-icon.component.html',
  styleUrls: ['./my-activity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyActivityIconComponent {
  @Input() iconType: string;
  @Input() iconContext: string;
  @Input() fallbackIcon: string = null;

  constructor(private cdr: ChangeDetectorRef) {}

  onImageNotFound() {
    if (this.fallbackIcon) {
      this.iconType = 'zen-icon';
      this.iconContext = this.fallbackIcon;
      this.cdr.markForCheck();
    }
  }
}
