import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  AnalyticsEvent,
  EcommerceAction,
} from '../models/analytics-event.model';
import { NavigationEnd, Router } from '@angular/router';
import { WebengageService } from '@codingninjas/ninjas-utils/services/webengage-init.service';
import { ClarityService } from '@codingninjas/ninjas-utils/services/clarity-init.service';
import { PosthogService } from '@codingninjas/ninjas-utils/services/posthog-init.service';
import { AlgoliaService } from '@codingninjas/ninjas-utils/services/algolia-init.service';
import { Store } from '@ngrx/store';
import { AuthState } from '@codingninjas/auth';
import { BrowserService } from '@codingninjas/ninjas-utils/services/browser.service';
import { sampleBasedOnMarketingToken } from '@codingninjas/ninjas-utils/utils/sampling.util';
import {
  DELAYED_ANALYTICS_PROVIDER_LIST,
  DELAYED_ANALYTICS_PROVIDERS,
  DelayedAnalyticsProviders,
  DelayedAnalyticsProviderType,
  DelayedInitStatus,
} from '@codingninjas/ninjas-utils/services/analytics/delayed-analytics.constants';
import { filter } from 'rxjs';

declare let dataLayer: any;
declare let gtag: any;

/**
 *
 * Wrap all the functions in this service inside
 *
 * if (isPlatformBrowser(this.platformId)) {}
 *
 * as this is required for apps with SSR and
 * this is a common service
 *
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private displayMode: string;
  userLogin;
  verifiedEmail;
  naukriFlowUser = 0;
  userSignup = 0;
  registrationUtmSource = null;
  registrationUtmMedium = null;
  registrationUtmCampaign = null;
  sourcePageUrl = null;
  sessionStartPageUrl = null;

  delayedAnalyticsProviderList = DELAYED_ANALYTICS_PROVIDER_LIST;
  delayedAnalyticsProviders: DelayedAnalyticsProviders =
    DELAYED_ANALYTICS_PROVIDERS;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private webengageService: WebengageService,
    private clarityService: ClarityService,
    @Inject('env') private environment: any,
    private router: Router,
    private posthogService: PosthogService,
    private algoliaService: AlgoliaService,
    private authStore: Store<AuthState>,
    private ngZone: NgZone,
    private browserService: BrowserService
  ) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.setDisplayMode();
    this.listenToAuthStore();
    this._setGtmScriptListener();
    this._setupDelayedEventListeners();
    this._setupDelayedAnalyticsProviders();
  }

  private _setupDelayedEventListeners() {
    if (this.environment?.app !== 'publicsection') {
      return;
    }

    this.delayedAnalyticsProviderList.forEach((provider) => {
      const delayedProvider = this.delayedAnalyticsProviders[provider];
      delayedProvider.initStatus$
        .pipe(filter((status) => status === DelayedInitStatus.COMPLETED))
        .subscribe(() => {
          delayedProvider.queue.forEach((event) => {
            switch (provider) {
              case DelayedAnalyticsProviderType.WEBENGAGE:
                this.pushPublicsectionWebengageEvents(event);
                break;
              case DelayedAnalyticsProviderType.CLARITY:
                this.pushPublicSectionClarityEvents(event);
                break;
              case DelayedAnalyticsProviderType.POSTHOG:
                this.pushPublicSectionPosthogEvents(event);
                break;
              case DelayedAnalyticsProviderType.ALGOLIA:
                this.algoliaService.sendAlgoliaEvent(event);
                break;
              default:
                throw new Error(
                  `Invalid delayed analytics provider: ${provider}`
                );
            }
          });
          delayedProvider.queue = [];
        });
    });
  }

  private _setupDelayedAnalyticsProviders() {
    // Only for publicsection app because events are pushed through data layer in other apps
    if (this.environment?.app !== 'publicsection') {
      return;
    }

    const marketing_token = this.browserService.getMarketingToken();
    const enableClarity =
      !this.isLowEndDevice() || sampleBasedOnMarketingToken(marketing_token, 5);

    this._initializeDelayedAnalyticsService(
      DelayedAnalyticsProviderType.POSTHOG
    );
    this._initializeDelayedAnalyticsService(
      DelayedAnalyticsProviderType.ALGOLIA
    );
    if (enableClarity) {
      this._initializeDelayedAnalyticsService(
        DelayedAnalyticsProviderType.CLARITY
      );
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (!event.url.includes('/library')) {
          this._initializeDelayedAnalyticsService(
            DelayedAnalyticsProviderType.WEBENGAGE
          );
        }
        this._capturePosthogTrackEvent();
      });
  }

  private _capturePosthogTrackEvent() {
    const delayedProvider =
      this.delayedAnalyticsProviders[DelayedAnalyticsProviderType.POSTHOG];
    delayedProvider.initStatus$
      .pipe(filter((status) => status === DelayedInitStatus.COMPLETED))
      .subscribe(() => {
        this.posthogService.track();
      });
  }

  private _initializeDelayedAnalyticsService(
    provider: DelayedAnalyticsProviderType
  ) {
    if (
      this.delayedAnalyticsProviders[provider] &&
      this.delayedAnalyticsProviders[provider].initStatus$.value !==
        DelayedInitStatus.UNINITIALIZED
    ) {
      return;
    }

    const serviceInitStatus$ =
      this.delayedAnalyticsProviders[provider].initStatus$;
    serviceInitStatus$.next(DelayedInitStatus.INITIATED);

    let providerService:
      | PosthogService
      | ClarityService
      | WebengageService
      | AlgoliaService;
    switch (provider) {
      case DelayedAnalyticsProviderType.POSTHOG:
        providerService = this.posthogService;
        break;
      case DelayedAnalyticsProviderType.CLARITY:
        providerService = this.clarityService;
        break;
      case DelayedAnalyticsProviderType.WEBENGAGE:
        providerService = this.webengageService;
        break;
      case DelayedAnalyticsProviderType.ALGOLIA:
        providerService = this.algoliaService;
        break;
      default:
        throw new Error(`Invalid delayed analytics provider: ${provider}`);
    }

    const loadDelay = this.delayedAnalyticsProviders[provider].loadDelay || 0;
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        providerService
          .init({})
          .then(() => {
            serviceInitStatus$.next(DelayedInitStatus.COMPLETED);
          })
          .catch(() => {
            serviceInitStatus$.next(DelayedInitStatus.FAILED);
          });
      }, loadDelay);
    });
  }

  private _setGtmScriptListener() {
    if ((window as any).pushEventsToQueue) {
      window.addEventListener(
        'gtm_script_loaded',
        () => {
          while ((window as any).gtmEventQueue.length > 0) {
            this.pushEventToDatalayer((window as any).gtmEventQueue[0]);
            (window as any).gtmEventQueue.shift();
          }
          if (!(window as any).gtmEventQueue.length) {
            delete (window as any).gtmEventQueue;
          }
          while ((window as any).gtmEcommerceQueue.length > 0) {
            if (dataLayer) {
              dataLayer.push((window as any).gtmEcommerceQueue[0]);
              (window as any).gtmEcommerceQueue.shift();
            }
          }
          if (!(window as any).gtmEcommerceQueue.length) {
            delete (window as any).gtmEcommerceQueue;
          }
        },
        { once: true }
      );
    }
  }

  listenToAuthStore() {
    this.authStore.select('auth').subscribe((authData) => {
      if (authData.isAuthenticated) {
        this.userLogin = 'logged in';
        this.verifiedEmail = true;
        this.naukriFlowUser = authData.naukriFlowUser;
        this.userSignup = authData.user?.ps_new_user ? 1 : 0;
        this.registrationUtmSource = authData.registrationUtmSource;
        this.registrationUtmMedium = authData.registrationUtmMedium;
        this.registrationUtmCampaign = authData.registrationUtmCampaign;
        this.sourcePageUrl = authData.sourcePageUrl;
        this.sessionStartPageUrl = authData.sessionStartPageUrl;
      } else {
        if (authData.naukriUnverified) {
          this.userLogin = 'logged in';
          this.verifiedEmail = false;
        } else {
          this.userLogin = 'logged out';
        }
      }
    });
  }

  sendEvent(eventAttributes: AnalyticsEvent) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        (window as any).pushEventsToQueue ||
        ((window as any).gtmEventQueue &&
          (window as any).gtmEventQueue.length > 0)
      ) {
        (window as any).gtmEventQueue.push(eventAttributes);
      } else {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.pushEventToDatalayer(eventAttributes);
          }, 0);
        });
      }
    }
  }
  pushEventToDatalayer(eventAttributes) {
    const eventMap = {
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventLabel: eventAttributes.label,
      eventValue: eventAttributes.value,
      eventData: eventAttributes.data,
      displayMode: this.displayMode,
      userLogin: this.userLogin,
      verifiedEmail: this.verifiedEmail,
      naukriFlowUser: this.naukriFlowUser,
      userSignup: this.userSignup,
      registrationUtmSource: this.registrationUtmSource,
      registrationUtmMedium: this.registrationUtmMedium,
      registrationUtmCampaign: this.registrationUtmCampaign,
      sourcePageUrl: this.sourcePageUrl,
      sessionStartPageUrl: this.sessionStartPageUrl,
      ...eventAttributes.data,
    };
    if (this.environment?.app === 'publicsection') {
      this.pushPublicsectionWebengageEvents(eventAttributes);
      this.pushPublicSectionClarityEvents(eventAttributes);
      this.pushPublicSectionPosthogEvents(eventAttributes);
    }
    if (dataLayer) {
      dataLayer.push(eventMap);
    }
  }

  private pushPublicsectionWebengageEvents(eventAttributes) {
    const delayedProvider =
      this.delayedAnalyticsProviders[DelayedAnalyticsProviderType.WEBENGAGE];
    if (
      delayedProvider &&
      delayedProvider.initStatus$.value !== DelayedInitStatus.COMPLETED
    ) {
      delayedProvider.queue.push(eventAttributes);
      return;
    }

    this.webengageService.pushWebEngageEvent({
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventData: eventAttributes.data,
    });
  }

  private pushPublicSectionClarityEvents(eventAttributes) {
    const delayedProvider =
      this.delayedAnalyticsProviders[DelayedAnalyticsProviderType.CLARITY];
    if (
      delayedProvider &&
      delayedProvider.initStatus$.value !== DelayedInitStatus.COMPLETED
    ) {
      delayedProvider.queue.push(eventAttributes);
      return;
    }

    this.clarityService.pushClarityEvent({
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventData: eventAttributes.data,
    });
  }

  private pushPublicSectionPosthogEvents(eventAttributes) {
    const delayedProvider =
      this.delayedAnalyticsProviders[DelayedAnalyticsProviderType.POSTHOG];
    if (delayedProvider.initStatus$.value !== DelayedInitStatus.COMPLETED) {
      delayedProvider.queue.push(eventAttributes);
      return;
    }

    this.posthogService.pushPosthogEvent({
      event: eventAttributes.name,
      eventCategory: eventAttributes.category,
      eventAction: eventAttributes.action,
      eventData: {
        ...eventAttributes.data,
        userLogin: this.userLogin,
      },
    });
  }

  sendEcommerceEvent(
    event: string,
    action: string,
    label: string,
    ecommerce: EcommerceAction
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const sendData = {
        event,
        eventCategory: 'Ecommerce',
        eventAction: action,
        eventLabel: label,
        ecommerce,
      };
      if (dataLayer) {
        dataLayer.push(sendData);
      }
    }
  }

  sendGA4EcommerceEvent(event_name, ecommerceData) {
    if (isPlatformBrowser(this.platformId)) {
      const sendData = {
        event: event_name,
        ecommerce: ecommerceData,
      };
      if (
        (window as any).pushEventsToQueue ||
        ((window as any).gtmEcommerceQueue &&
          (window as any).gtmEcommerceQueue.length > 0)
      ) {
        (window as any).gtmEcommerceQueue.push({ ecommerce: null });
        (window as any).gtmEcommerceQueue.push(sendData);
      } else {
        if (dataLayer) {
          dataLayer.push({ ecommerce: null });
          dataLayer.push(sendData);
        }
      }
    }
  }

  getGoogleClickId() {
    if (isPlatformBrowser(this.platformId)) {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const gclid = url.searchParams.get('gclid') || '';
      return gclid;
    } else {
      return '';
    }
  }

  setCustomVariables(customVariablesMap: {}) {
    if (isPlatformBrowser(this.platformId)) {
      if ((window as any).pushEventsToQueue) {
        window.addEventListener(
          'gtm_script_loaded',
          () => {
            if (dataLayer) {
              dataLayer.push({ ...customVariablesMap });
            }
          },
          { once: true }
        );
      } else {
        if (dataLayer) {
          dataLayer.push({ ...customVariablesMap });
        }
      }
    }
  }

  clickEvent(name, config: any = {}) {
    this.sendEvent({
      name,
      category: 'click',
      action: config?.action || name,
      label: config?.label,
      data: {
        ...config.data,
      },
    });
  }

  sendAlgoliaEvent(event) {
    const delayedProvider =
      this.delayedAnalyticsProviders[DelayedAnalyticsProviderType.ALGOLIA];
    if (
      delayedProvider &&
      delayedProvider.initStatus$.value !== DelayedInitStatus.COMPLETED
    ) {
      delayedProvider.queue.push(event);
      return;
    }

    this.algoliaService.sendAlgoliaEvent(event);
  }

  setAlgoliaUser(token) {
    this._captureDelayedUserDefineEvent(
      DelayedAnalyticsProviderType.ALGOLIA,
      token
    );
  }

  sendPSUserDefined(data) {
    this._captureDelayedUserDefineEvent(
      DelayedAnalyticsProviderType.WEBENGAGE,
      data
    );
    this._captureDelayedUserDefineEvent(
      DelayedAnalyticsProviderType.POSTHOG,
      data
    );
    this._captureDelayedUserDefineEvent(
      DelayedAnalyticsProviderType.CLARITY,
      data
    );
  }

  private _captureDelayedUserDefineEvent(
    provider: DelayedAnalyticsProviderType,
    data
  ) {
    const delayedProvider = this.delayedAnalyticsProviders[provider];
    if (!delayedProvider) {
      return;
    }

    delayedProvider.initStatus$
      .pipe(filter((status) => status === DelayedInitStatus.COMPLETED))
      .subscribe(() => {
        switch (provider) {
          case DelayedAnalyticsProviderType.POSTHOG:
            this.posthogService.userDefine(data);
            break;
          case DelayedAnalyticsProviderType.CLARITY:
            this.clarityService.userDefine(data);
            break;
          case DelayedAnalyticsProviderType.WEBENGAGE:
            this.webengageService.userDefine(data);
            break;
          case DelayedAnalyticsProviderType.ALGOLIA:
            this.algoliaService.setAlgoliaUser(data);
            break;
          default:
            throw new Error(`Invalid delayed analytics provider: ${provider}`);
        }
      });
  }

  sendGTAGEvents(event, eventKey, eventData) {
    gtag(event, eventKey, eventData);
  }

  webengageLogout() {
    this.webengageService.logout();
  }

  posthogLogout() {
    this.posthogService.logout();
  }

  private setDisplayMode() {
    if (isPlatformBrowser(this.platformId)) {
      this.displayMode = 'browser';
      const mqStandAlone = '(display-mode: standalone)';
      if (window.matchMedia(mqStandAlone).matches) {
        this.displayMode = 'pwa';
      }
    }
  }

  private isLowEndDevice() {
    const logicalProcessors = navigator.hardwareConcurrency || 8;
    const deviceMemory = (navigator as any).deviceMemory || 8;
    return deviceMemory <= 2 || logicalProcessors < 4;
  }
}
