import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  NotificationTheme,
  TaJobActivityNotification,
} from '../../../../../constants/my-activities-notification.constants';
import { MyActivitiesNotificationService } from '@codingninjas/notification';

@Component({
  selector: 'codingninjas-remind-referred-user-actions',
  templateUrl: './remind-referred-user-actions.component.html',
  styleUrls: ['./remind-referred-user-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemindReferredUserActionsComponent {
  @Input() notification: TaJobActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;
  @Output() ctaClicked = new EventEmitter();
  defaultRedirectionUrl = 'https://classroom.codingninjas.com/app/referral';

  constructor(
    private myActivityNotificationService: MyActivitiesNotificationService,
    private myActivityService: MyActivitiesNotificationService
  ) {}

  openUrl(ctaText: string) {
    const redirectionURL =
      this.notification.content.primary_cta_url || this.defaultRedirectionUrl;

    window.open(redirectionURL, '_blank');
    this._dismissActivity();

    this.ctaClicked.emit();
    this.myActivityNotificationService.triggerCTAClickAnalytics(
      ctaText,
      this.notification,
      this.source
    );
  }

  private _dismissActivity() {
    const activityIds = [this.notification.id];
    this.myActivityService
      .markAsDismissed(activityIds, 'action_fulfilled')
      .subscribe();
  }
}
