import { Injectable } from '@angular/core';

declare var aa: any;

@Injectable({
  providedIn: 'root',
})
export class AlgoliaService {
  constructor() {}
  private loader: Promise<boolean>;

  /* tslint:disable */
  public init(option: any): Promise<any> {
    this.loader = new Promise((resolve, reject) => {
      var ALGOLIA_INSIGHTS_SRC =
        'https://cdn.jsdelivr.net/npm/search-insights@2.13.0/dist/search-insights.min.js';

      !(function (e, a, t, n, s, i, c): any {
        ((e as any).AlgoliaAnalyticsObject = s),
          (e[s] =
            e[s] ||
            function (): any {
              (e[s].queue = e[s].queue || []).push(arguments);
            }),
          (e[s].version = (n.match(/@([^\/]+)\/?/) || [])[1]),
          ((i as any) = a.createElement(t)),
          ((c as any) = a.getElementsByTagName(t)[0]),
          ((i as any).async = 1),
          ((i as any).src = n),
          (c as any).parentNode.insertBefore(i, c);
      })(window, document, 'script', ALGOLIA_INSIGHTS_SRC, 'aa');
      resolve(true);
    });

    this.load();
    return this.loader;
  }

  public load() {
    if (this.loader) {
      this.loader.then((loaded) => {
        if (loaded) {
          aa('init', {
            appId: 'YBDR7NKX52',
            apiKey: 'fab9773d8ee0f76aa3097cf6a21eec2b',
          });
        }
      });
    }
  }

  public sendAlgoliaEvent(event) {
    aa('clickedObjectIDsAfterSearch', event);
  }

  public setAlgoliaUser(token) {
    aa('setAuthenticatedUserToken', token);
  }
  /* tslint:enable */
}
