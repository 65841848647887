import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  BookInterviewActivityNotification,
  NotificationTheme,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-book-interview-session-activity',
  templateUrl: './book-interview-session-activity.component.html',
  styleUrls: ['./book-interview-session-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookInterviewSessionActivityComponent implements OnInit {
  @Input() notification: BookInterviewActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;

  _actionPerformed = false;
  text;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.theme === 'light') {
      this.text = 'This will help you enhance your skillsets';
    } else {
      this.text = 'Take a step towards getting job ready';
      this.notification.content.icon_context += '-fill';
    }
  }

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }
}
