import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
