import { Injectable } from '@angular/core';
import { AnalyticsService } from '@codingninjas/ninjas-utils';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DetectPhoneNumberService {
  constructor(
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}

  private detectPhoneNumberAndSendWebengageEvent() {
    const current_page_url = window.location.href;
    const regex =
      /(phone_number|phone|mobile_number|phoneNumber|mobile|mobileNumber)/i;

    if (regex.test(current_page_url)) {
      this.analyticsService.sendEvent({
        name: 'Phone Number Detected',
        action: 'Phone Number Detected',
        category: 'View',
        data: {
          location: current_page_url,
        },
      });
    }
  }

  detectPhoneNumberOnRouteChange() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.detectPhoneNumberAndSendWebengageEvent();
      });
  }
}
