import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'auth-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss'],
})
export class LoginSignupComponent implements OnInit {
  LOGIN_FORM = {
    INITIAL: 0,
    NEW_USER: 1,
    REGISTERED_USER_WITH_PASSWORD: 2,
    REGISTERED_USER_WITHOUT_PASSWORD: 3,
    FACEBOOK_MERGE: 4,
    FACEBOOK_MERGED: 5,
  };

  loginData: LoginData;
  @Input() showOptInCheckBox: boolean = null;

  _loginForm: number = this.LOGIN_FORM.INITIAL;
  @Input() set loginForm(loginFormType: number) {
    if (loginFormType) {
      this._loginForm = loginFormType;
    }
  }

  @Input() set inputEmail(email: string) {
    this.loginData = { ...this.loginData, email };
  }

  @Input() set inputName(name: string) {
    this.loginData = { ...this.loginData, name };
  }

  @Input() set loginType(loginType: string) {
    this.loginData = { ...this.loginData, loginType };
  }

  constructor() {}

  ngOnInit() {}

  updateLoginData(data: LoginData) {
    this.loginData = data;
    // this.inputEmail = data.email;
    // this.phoneCountryCode = data.phoneCountryCode;
    // this.phoneNumber = data.phoneNumber;
    // this.loginType = data.loginType;
  }

  changeForm(form: number) {
    this._loginForm = form;
  }
}

export interface LoginData {
  name?: string;
  email: string;
  phoneNumber: string;
  phoneCountryCode: string;
  loginType: string;
}
