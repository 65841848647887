import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MyActivityNotificationModel } from '../../../../constants/my-activities-notification.constants';
import { isNullOrUndefined } from '../../../../../../ninjas-utils/src/lib/utils/blank-check.util';
import { AnimationOptions } from 'ngx-lottie';
import { UNREAD_NOTIFICATION } from '../../../../constants/lottie-animations/unread-notification';

@Component({
  selector: 'codingninjas-my-activity-header-info',
  templateUrl: './my-activity-header-info.component.html',
  styleUrls: ['./my-activity-header-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyActivityHeaderInfoComponent implements OnInit {
  @Input() notification: MyActivityNotificationModel;
  @Input() actionPerformed: boolean;
  showNotificationDot = true;
  unreadNotificationAnimationOptions: AnimationOptions;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.setNotificationDotVisibility();
    this.unreadNotificationAnimationOptions = {
      animationData: UNREAD_NOTIFICATION,
    };
  }

  setNotificationDotVisibility() {
    this.showNotificationDot =
      isNullOrUndefined(this.notification.read_at) ||
      this.notification.read_at === 0;
    this.cdr.markForCheck();
  }
}
