<div
  class="navbar-top"
  [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
>
  <div class="logo">
    <img
      src="https://files.codingninjas.in/cn-logo-1722258113.svg"
      alt="Coding Ninjas Logo"
    />
  </div>

  <div class="buttons">
    <button
      mat-icon-button
      class="toggle-sidebar-folded"
      (click)="toggleSidebarFolded()"
      fxHide.lt-lg
    >
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button
      mat-icon-button
      class="toggle-sidebar-opened"
      (click)="toggleSidebarOpened()"
      fxHide.gt-md
    >
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div
  class="navbar-scroll-container"
  [ngClass]="fuseConfig.layout.navbar.primaryBackground"
  fusePerfectScrollbar
  [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div
    *ngIf="mentorUser"
    class="user"
    fxLayout="column"
    [ngClass]="fuseConfig.layout.navbar.secondaryBackground"
  >
    <div *ngIf="mentorUser.name" class="h3 username">{{ mentorUser.name }}</div>
    <div *ngIf="mentorUser.email" class="h5 email hint-text mt-8">
      {{ mentorUser.email }}
    </div>
    <div
      *ngIf="mentorUser.image"
      class="avatar-container"
      [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    >
      <img class="avatar" [src]="mentorUser.image" />
    </div>
  </div>

  <div class="navbar-content">
    <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
  </div>
</div>
