import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NinjasNotification,
  NinjasNotificationOptions,
} from '../ninjas-notification';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NinjasNotificationService } from '../ninjas-notification.service';
import { NinjasNotificationConfig } from '../ninjas-notification.config';
import { isNullOrUndefined } from '../../../../ninjas-utils/src/lib/utils/blank-check.util';

@Component({
  selector: 'codingninjas-notification',
  animations: [
    trigger('enterLeave', [
      state('enterRight', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('* => enterRight', [
        style({ opacity: 0, transform: 'translateX(5%)' }),
        animate('100ms linear'),
      ]),
      state('enterLeft', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('* => enterLeft', [
        style({ opacity: 0, transform: 'translateX(-5%)' }),
        animate('100ms linear'),
      ]),
      state(
        'leave',
        style({
          opacity: 0,
          transform: 'scaleY(0.8)',
          transformOrigin: '0% 0%',
        })
      ),
      transition('* => leave', [
        style({
          opacity: 1,
          transform: 'scaleY(1)',
          transformOrigin: '0% 0%',
        }),
        animate('100ms linear'),
      ]),
    ]),
  ],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() notification: NinjasNotification;
  @Input() index: number;

  config: NinjasNotificationConfig = {};

  protected _options: NinjasNotificationOptions; // Shortcut reference to nzMessage.options

  // For auto erasing(destroy) self
  private _autoErase: boolean; // Whether record timeout to auto destroy self
  private _eraseTimer: number = null;
  private _eraseTimingStart: number;
  private _eraseTTL: number; // Time to live
  onClickCallbackPresent = false;

  constructor(private notificationService: NinjasNotificationService) {
    this.config = notificationService.getConfig();
  }

  ngOnInit(): void {
    this._options = this.notification.options;

    if (this._options.animate) {
      this.notification.state = 'enter';
    }

    this._autoErase = this._options.duration > 0;

    if (this._autoErase) {
      this._initErase();
      this._startEraseTimeout();
    }

    if (!isNullOrUndefined(this._options.audio)) {
      this.playAudio();
    }

    if (!isNullOrUndefined(this._options.onClickCallback)) {
      this.onClickCallbackPresent = true;
    }
  }

  playAudio() {
    const audio = new Audio(this._options.audio);
    audio.play().catch(() => {});
  }

  ngOnDestroy(): void {
    if (this._autoErase) {
      this._clearEraseTimeout();
    }
  }

  onEnter(): void {
    if (this._autoErase && this._options.pauseOnHover) {
      this._clearEraseTimeout();
      this._updateTTL();
    }
  }

  onLeave(): void {
    if (this._autoErase && this._options.pauseOnHover) {
      this._startEraseTimeout();
    }
  }

  onClick(): void {
    if (this.onClickCallbackPresent) {
      this._options.onClickCallback();
    }
  }

  // Remove self
  protected _destroy(): void {
    if (this._options.animate) {
      this.notification.state = 'leave';
      setTimeout(
        () =>
          this.notificationService.removeNotification(
            this.notification.notificationId
          ),
        200
      );
    } else {
      this.notificationService.removeNotification(
        this.notification.notificationId
      );
    }
  }

  private _initErase(): void {
    this._eraseTTL = this._options.duration;
    this._eraseTimingStart = Date.now();
  }

  private _updateTTL(): void {
    if (this._autoErase) {
      this._eraseTTL -= Date.now() - this._eraseTimingStart;
    }
  }

  private _startEraseTimeout(): void {
    if (this._eraseTTL > 0) {
      this._clearEraseTimeout(); // To prevent calling _startEraseTimeout() more times to create more timer
      this._eraseTimer = window.setTimeout(
        () => this._destroy(),
        this._eraseTTL
      );
      this._eraseTimingStart = Date.now();
    } else {
      this._destroy();
    }
  }

  private _clearEraseTimeout(): void {
    if (this._eraseTimer !== null) {
      window.clearTimeout(this._eraseTimer);
      this._eraseTimer = null;
    }
  }

  close(): void {
    this._destroy();
  }

  get state(): string {
    if (this.notification.state === 'enter') {
      if (
        this.config.placement === 'topLeft' ||
        this.config.placement === 'bottomLeft'
      ) {
        return 'enterLeft';
      } else {
        return 'enterRight';
      }
    } else {
      return this.notification.state;
    }
  }
}
