import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'shared-ui-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.css'],
})
export class InternalErrorComponent implements OnInit, OnDestroy {
  @Input() redirectUrl = '/';

  constructor(
    private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: any
  ) {}

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      if (this.response) {
        this.response.statusCode = 500;
      }
    }
    this.metaService.removeTag('name="robots"');
    this.metaService.addTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  ngOnDestroy() {
    this.metaService.removeTag('name="robots"');
  }
}
