import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ALL_ACTIVITIES_MAP,
  MyActivityNotificationModel,
  NotificationTheme,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'classroom-activity-switcher',
  templateUrl: './activity-switcher.component.html',
  styleUrls: ['./activity-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivitySwitcherComponent {
  @Input() notification: MyActivityNotificationModel;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;

  activitiesMap = ALL_ACTIVITIES_MAP;
}
