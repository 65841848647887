export enum Breakpoints {
  MOBILE = '(max-width: 1024px)',
  DESKTOP = '(min-width: 1024px)',
  MOBILEV3 = '(max-width: 980px)',
  DESKTOPV3 = '(min-width: 980px)',
}

export enum Devices {
  DESKTOP = 'desktop',
  TAB = 'tablet',
  MOBILE = 'mobile',
  UNKNOWN = 'unknown',
}
