import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
  state,
} from '@angular/animations';

export const MyActivityNotificationAnimation: AnimationTriggerMetadata =
  trigger('enterLeave', [
    state('enterRight', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('* => enterRight', [
      style({ opacity: 0, transform: 'translateX(5%)' }),
      animate('100ms linear'),
    ]),

    state('enterLeft', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('* => enterLeft', [
      style({ opacity: 0, transform: 'translateX(-5%)' }),
      animate('100ms linear'),
    ]),

    state(
      'leave',
      style({
        opacity: 0,
        transform: 'translateX(10%)',
        transformOrigin: 'center',
      })
    ),
    transition('* => leave', [
      style({
        opacity: 1,
        transform: 'translateX(0)',
        transformOrigin: 'center',
      }),
      animate('100ms linear'),
    ]),
  ]);
