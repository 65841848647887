<div class="leave-requested-main-div">
  <div style="text-align: center;">
    <div class="image-div">
      <img class="calendar-image" src="assets/images/leave_calendar.svg">
    </div>
    <div class="leave-requested-div">Leave Requested</div>
    <div class="under-review">UNDER REVIEW</div>
  </div>
</div>
<div class="line"></div>

<div class="detail">
  <div>
    <div class="requested-div">
      You have requested leave from <span class="time-span">{{requestLeaveResult.requested_from_date}} to {{requestLeaveResult.requested_to_date}} ({{requestLeaveResult.no_of_days}})</span>.
    </div>
    <div class="support-text">
      Our support team will review your request in a few hours. You will be notified on your registered email address.
    </div>
  </div>
</div>
<div></div>

<!--<div class="note-div">-->
<!--  <div>-->
<!--    <img class="slot-time-image" src="assets/images/info-icon.svg">-->
<!--  </div>-->
<!--  <div class="note-text">-->
<!--    Did you make this request by mistake or want to change this? <span class="contact-support" (onClick)="closeModal()">Contact Support</span>-->
<!--  </div>-->
<!--</div>-->

<div class="dismiss-div">
  <div class="dismiss_button" (click)="closeModal()" >
    <span class="dismiss-text">Dismiss</span>
  </div>
</div>


