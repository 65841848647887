import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MARKETING_TOKEN_KEY } from '@codingninjas/ninjas-utils/constants/marketing-token.constants';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  req: any;
  constructor(
    @Inject(PLATFORM_ID) private _platformId: object,
    private injector: Injector
  ) {
    if (isPlatformServer(_platformId)) {
      this.req = this.injector.get('req');
    }
  }

  public setCookie(
    name: string,
    value: string,
    expiresAt: Date,
    setNaukriCookie = false
  ) {
    let expires = '';
    if (expiresAt) {
      expires = '; expires=' + expiresAt.toUTCString();
    }

    const cookieText = `${name}=${value || ''}` + expires;
    document.cookie = cookieText + `; path=/ ; domain=.codingninjas.com;`;
    document.cookie = cookieText + `; path=/ ; domain=.codingninjas.in;`;
    document.cookie = cookieText + `; path=/ ; domain=localhost;`;
    if (setNaukriCookie) {
      document.cookie = cookieText + `; path=/ ; domain=.naukri.com;`;
    }
  }

  public getCookie(name: string): string {
    if (isPlatformBrowser(this._platformId)) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let c of ca) {
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    if (isPlatformServer(this._platformId)) {
      const cookies = this.req.cookies;
      const cookieStore = {};
      if (!!cookies === false) {
        return null;
      }
      const cookiesArr = cookies.split('; ');
      for (const cookie of cookiesArr) {
        const index = cookie.indexOf('=');
        const cookieArr = [cookie.slice(0, index), cookie.slice(index + 1)];
        cookieStore[cookieArr[0]] = cookieArr[1];
      }
      return cookieStore[name];
    }
    return null;
  }

  public eraseCookie(name, eraseNaukriCookie = false) {
    if (isPlatformBrowser(this._platformId)) {
      document.cookie =
        name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      document.cookie =
        name +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.codingninjas.com;';
      document.cookie =
        name +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.codingninjas.in;';
      document.cookie =
        name +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=localhost;';
      if (eraseNaukriCookie) {
        document.cookie =
          name +
          '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.naukri.com;';
      }
    }
  }

  public getMarketingToken() {
    return this.getCookie(MARKETING_TOKEN_KEY);
  }
}
