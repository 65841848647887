import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  JobActivityNotification,
  NotificationTheme,
} from '../../../../../constants/my-activities-notification.constants';
import { MyActivitiesNotificationService } from '@codingninjas/notification';

@Component({
  selector: 'codingninjas-deadline-job-actions',
  templateUrl: './deadline-job-actions.component.html',
  styleUrls: ['./deadline-job-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeadlineJobActionsComponent {
  @Input() notification: JobActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;
  @Output() ctaClicked = new EventEmitter();

  constructor(
    private myActivityNotificationService: MyActivitiesNotificationService
  ) {}

  openUrl(url, ctaText) {
    window.open(url, '_blank');
    this.ctaClicked.emit();
    this.myActivityNotificationService.triggerCTAClickAnalytics(
      ctaText,
      this.notification,
      this.source
    );
  }
}
