import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MyActivityNotificationConfig,
  MyActivityNotificationOptions,
  NinjasNotification,
  NinjasNotificationOptions,
} from '@codingninjas/notification';
import { NinjasNotificationConfig } from '../ninjas-notification.config';
import {
  ADD_NOTIFICATION,
  CLEAR_ALL,
  NinjasNotificationService,
  NotificationActions,
  REMOVE_NOTIFICATION,
} from '../ninjas-notification.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import {
  ADD_CUSTOM_NOTIFICATION,
  REMOVE_CUSTOM_NOTIFICATION,
  CLEAR_ALL_CUSTOM_NOTIFICATION,
  CustomNinjasNotificationService,
  CustomNotificationActions,
} from '../custom-ninjas-notification.service';
import { CustomNinjasNotification } from '../custom-ninja-notification';
import {
  ADD_TOAST_NOTIFICATION,
  REMOVE_TOAST_NOTIFICATION,
  ToastNotificationActions,
  ToastNotificationService,
} from '../toast-ninjas-notification.service';
import {
  ToastNinjasNotification,
  ToastNotificationOptions,
} from '../toast-ninjas-notification';
import { ToastNotificationConfig } from '../toast-ninjas-notification.config';
import { map, takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ActivityNotificationActions,
  ADD_ACTIVITY_NOTIFICATION,
  REMOVE_ACTIVITY_NOTIFICATION,
  CLEAR_ALL_ACTIVITY_NOTIFICATION,
  MyActivitiesNotificationService,
} from '../my-activities-notification.service';
import { MyActivityNotification } from '../my-activity-notification';

enum ProductBreakpoints {
  MOBILE = '(max-width: 767.98px)',
  IPAD = '(min-width: 768px) and (max-width: 1023.98px)',
  IPAD_EXTENDED = '(min-width: 1024px) and (max-width: 1279.98px)',
  DESKTOP = '(min-width: 1280px) and (max-width: 1563.98px)',
  DESKTOP_EXTENDED = '(min-width: 1564px)',
}

const PRODUCT_BREAKPOINTS: string[] = [
  ProductBreakpoints.MOBILE,
  ProductBreakpoints.IPAD,
  ProductBreakpoints.IPAD_EXTENDED,
  ProductBreakpoints.DESKTOP,
  ProductBreakpoints.DESKTOP_EXTENDED,
];

@Component({
  selector: 'codingninjas-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
})
export class NotificationContainerComponent implements OnInit, OnDestroy {
  regularNotifications: NinjasNotification[] = [];
  customNotifications: CustomNinjasNotification[] = [];
  toastNotifications: ToastNinjasNotification[] = [];
  activityNotifications: MyActivityNotification[] = [];
  isMobileOrIpad$: Observable<boolean>;
  productBreakpointDimensions = ProductBreakpoints;
  config:
    | NinjasNotificationConfig
    | ToastNotificationConfig
    | MyActivityNotificationConfig = {};
  private _sub: Subscription;
  destroy$ = new Subject();

  private productBreakpointsSource: BehaviorSubject<ProductBreakpoints> =
    new BehaviorSubject(null);
  public productBreakpoints: Observable<ProductBreakpoints> =
    this.productBreakpointsSource.asObservable();

  constructor(
    private notificationService: NinjasNotificationService,
    private customNinjasNotificationService: CustomNinjasNotificationService,
    private toastNotificationService: ToastNotificationService,
    private myActivitiesNotificationService: MyActivitiesNotificationService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.config = notificationService.getConfig();
    this.breakpointObserver.observe(PRODUCT_BREAKPOINTS).subscribe((result) => {
      if (result.matches) {
        for (const breakpoint of PRODUCT_BREAKPOINTS) {
          if (result.breakpoints[breakpoint]) {
            const matchedBreakpoint = breakpoint as ProductBreakpoints;
            this.productBreakpointsSource.next(matchedBreakpoint);
            break;
          }
        }
      }
    });
    this.isMobileOrIpad$ = this.productBreakpoints.pipe(
      map(
        (bp) =>
          bp === this.productBreakpointDimensions.MOBILE ||
          bp === this.productBreakpointDimensions.IPAD
      )
    );
  }

  ngOnInit() {
    this.subscribeToBreakPoints();
    this.manageRegularNinjasNotificationActions();
    this.manageCustomNinjasNotificationActions();
    this.manageToastNinjasNotificationActions();
    this.manageMyActivityNotificationActions();
  }

  subscribeToBreakPoints() {
    this.isMobileOrIpad$.subscribe((value) => {
      if (value) {
        this.config = this.toastNotificationService.getConfig();
      } else {
        this.config = this.notificationService.getConfig();
      }
    });
  }

  manageRegularNinjasNotificationActions() {
    this._sub = this.notificationService.$actions.subscribe(
      (action: NotificationActions) => {
        if (action) {
          switch (action.type) {
            case ADD_NOTIFICATION:
              this.createRegularNotification(action.payload);
              break;
            case REMOVE_NOTIFICATION:
              this.removeRegularNotification(action.payload);
              break;
            case CLEAR_ALL:
              this.removeAllRegular();
              break;
          }
        }
      }
    );
  }
  manageToastNinjasNotificationActions() {
    this.toastNotificationService.$toastNotificationActions
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: ToastNotificationActions) => {
        if (action) {
          this.config = this.toastNotificationService.getConfig();
          switch (action.type) {
            case ADD_TOAST_NOTIFICATION:
              if (action.payload instanceof ToastNinjasNotification) {
                this.createToastNotification(action.payload);
              }
              break;
            case REMOVE_TOAST_NOTIFICATION:
              this.removeToastNotification(action.payload);
              break;
          }
        }
      });
  }
  manageCustomNinjasNotificationActions() {
    this.customNinjasNotificationService.$customNotificationAction.subscribe(
      (action: CustomNotificationActions) => {
        if (action) {
          switch (action.type) {
            case ADD_CUSTOM_NOTIFICATION:
              this.createCustomNotification(action.payload);
              break;
            case REMOVE_CUSTOM_NOTIFICATION:
              this.removeCustomNotification(action.payload);
              break;
            case CLEAR_ALL_CUSTOM_NOTIFICATION:
              this.removeAllCustom();
              break;
          }
        }
      }
    );
  }

  manageMyActivityNotificationActions() {
    this.myActivitiesNotificationService.$activityNotificationAction.subscribe(
      (action: ActivityNotificationActions) => {
        if (action) {
          this.config = this.myActivitiesNotificationService.getConfig();
          switch (action.type) {
            case ADD_ACTIVITY_NOTIFICATION:
              this.createActivityNotification(action.payload);
              break;
            case REMOVE_ACTIVITY_NOTIFICATION:
              this.removeActivityNotification(action.payload);
              break;
            case CLEAR_ALL_ACTIVITY_NOTIFICATION:
              this.removeAllActivityNotification();
              break;
          }
        }
      }
    );
  }

  // ------------------------------ FOR REGULAR NOTIFICATIONS ---------------------------- //

  createRegularNotification(notification: NinjasNotification): void {
    if (this.regularNotifications.length >= this.config.maxStack) {
      this.regularNotifications.splice(0, 1);
    }
    notification.setOptions(this._mergeMessageOptions(notification.options));
    this.regularNotifications.push(notification);
  }

  removeRegularNotification(notificationId: string): void {
    this.regularNotifications.some((notification, index) => {
      if (notification.notificationId === notificationId) {
        this.regularNotifications.splice(index, 1);
        return true;
      }
    });
  }

  removeAllRegular(): void {
    this.regularNotifications = [];
  }

  // ------------------------------ FOR CUSTOM NOTIFICATIONS ---------------------------- //

  createCustomNotification(notification: CustomNinjasNotification): void {
    // 1 being here maxStack count
    if (this.customNotifications.length > 1) {
      this.customNotifications.splice(0, 1);
    }
    notification.setOptions(this._mergeMessageOptions(notification.options));
    this.customNotifications.push(notification);
  }

  removeCustomNotification(notificationId: string): void {
    this.customNotifications.some((notification, index) => {
      if (notification.notificationId === notificationId) {
        this.customNotifications.splice(index, 1);
        return true;
      }
    });
  }

  removeAllCustom(): void {
    this.customNotifications = [];
  }

  // ------------------------------ FOR TOASTNOTIFICATIONS ---------------------------- //

  createToastNotification(notification: ToastNinjasNotification): void {
    if (this.toastNotifications.length > 1) {
      this.toastNotifications.splice(0, 1);
    }
    notification.setOptions(
      this._mergeToastMessageOptions(notification.options)
    );
    this.toastNotifications.push(notification);
  }

  removeToastNotification(
    notificationId: ToastNinjasNotification | string
  ): void {
    this.toastNotifications.some((notification, index) => {
      if (notification.notificationId === notificationId) {
        this.toastNotifications.splice(index, 1);
        return true;
      }
    });
  }

  // ------------------------------ FOR ACTIVITY NOTIFICATIONS ---------------------------- //

  createActivityNotification(notification: MyActivityNotification): void {
    if (this.activityNotifications.length >= this.config.maxStack) {
      this.activityNotifications.splice(0, 1);
    }
    notification.setOptions(
      this._mergeMyActivityMessageOptions(notification.options)
    );
    this.activityNotifications.push(notification);
  }

  removeActivityNotification(notificationId: string): void {
    this.activityNotifications.some((notification, index) => {
      if (notification.notificationId === notificationId) {
        this.activityNotifications.splice(index, 1);
        return true;
      }
    });
  }

  removeAllActivityNotification(): void {
    this.activityNotifications = [];
  }

  // ------------------ Merge default options and custom message options ------------------ //

  protected _mergeMessageOptions(
    options: NinjasNotificationOptions
  ): NinjasNotificationOptions {
    const defaultOptions: NinjasNotificationOptions = {
      duration: this.config.duration,
      animate: this.config.animate,
      pauseOnHover: this.config.pauseOnHover,
      showClose: this.config.showClose,
    };
    return { ...defaultOptions, ...options };
  }

  protected _mergeToastMessageOptions(
    options: ToastNotificationOptions
  ): ToastNotificationOptions {
    const defaultOptions: ToastNotificationOptions = {
      duration: this.config.duration,
      animate: this.config.animate,
      pauseOnHover: this.config.pauseOnHover,
      showClose: this.config.showClose,
    };
    return { ...defaultOptions, ...options };
  }

  protected _mergeMyActivityMessageOptions(
    options: MyActivityNotificationOptions
  ): MyActivityNotificationOptions {
    const defaultOptions: MyActivityNotificationOptions = {
      duration: this.config.duration,
      animate: this.config.animate,
      pauseOnHover: this.config.pauseOnHover,
      showClose: this.config.showClose,
      audio: this.config.audio,
    };
    return { ...defaultOptions, ...options };
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
