<div class="creation-at zen-typo-caption-medium flex-row align-items-center">
  <ng-lottie
    class="separator-icon"
    width="16px"
    height="16px"
    *ngIf="showNotificationDot && !actionPerformed"
    [options]="unreadNotificationAnimationOptions"
  >
  </ng-lottie>
  {{ notification.creation_at }}
</div>
