import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'shared-ui-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: [
    './../common-scss/social-buttons.scss',
    './whatsapp-button.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatsappButtonComponent {
  @Input() disabled = false;
}
