import {
  ApiClient,
  Call,
  IResourceFactory,
  NetworkingError,
  Resource,
} from '@codingninjas/networking';
import { Observable, of } from 'rxjs';
import { isNullOrUndefined } from '../../lib/utils/blank-check.util';
import { catchError, map, startWith } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { NinjasResponse } from './ninjas.response';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class NinjasResourceFactory implements IResourceFactory {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject('env') private environment,
    @Inject(PLATFORM_ID) private _platformId: object
  ) {}

  createResource<T>(apiClient: ApiClient, call: Call): Observable<Resource<T>> {
    const request: Observable<NinjasResponse<T>> =
      apiClient.request<NinjasResponse<T>>(call);
    if (request != null) {
      return request.pipe(
        startWith(null),
        map((apiResponse: NinjasResponse<T>, index) => {
          if (index === 0) {
            return Resource.loading<T>();
          } else if (apiResponse != null) {
            if (apiResponse.status === 200) {
              if (apiResponse.data != null) {
                return Resource.success<T>(apiResponse.data);
              } else if (apiResponse.error != null) {
                // Custom error handling logic i.e expired token
                if (
                  apiResponse.error.code === 101 ||
                  apiResponse.error.code === 102
                ) {
                  if (!this.activatedRoute.snapshot.queryParams['logged_out']) {
                    const authUrl = this.environment.authUrl || '/auth';
                    const redirect_url =
                      (window.location.pathname || this.router.url) +
                      '?' +
                      new HttpParams({
                        fromObject:
                          this.router.getCurrentNavigation()?.extractedUrl
                            ?.queryParams || {},
                      }).toString();

                    const paramMap = {
                      queryParams: {
                        redirect: redirect_url,
                        logged_out: true,
                      },
                    };
                    // Check if intrupted due to naukri access token expiration
                    if (apiResponse.error.code === 102) {
                      paramMap.queryParams['continue_with_naukri'] = 1;
                    }
                    // To trigger navigation events as mentor app uses NavigationEnd to hide loader
                    setTimeout(() => {
                      this.router.navigate([authUrl], paramMap);
                    });
                  }
                }
                return Resource.error<T>(apiResponse.error);
              }
            }
          }
          return Resource.error<T>(NetworkingError.INTERNAL_SERVER_ERROR);
        }),
        catchError((err) => {
          const defaultError = NetworkingError.INTERNAL_SERVER_ERROR;
          if (!isNullOrUndefined(err)) {
            if (err instanceof NetworkingError) {
              // Error sent via map function above
              return of(Resource.error<T>(err));
            } else if (err instanceof HttpErrorResponse) {
              if (isPlatformServer(this._platformId)) {
                return of(
                  Resource.error<T>(
                    new NetworkingError(err.status, err.error.message)
                  )
                );
              }
              if (err.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                return of(Resource.error<T>(NetworkingError.NETWORK_ERROR));
              } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,

                // Response was not parsed due to invalid headers(server code break)
                if (err.status === 0) {
                  return of(Resource.error<T>(defaultError));
                } else {
                  return of(
                    Resource.error<T>(
                      new NetworkingError(err.status, err.error.message)
                    )
                  );
                }
              }
            }
          }
          return of(Resource.error<T>(defaultError));
        })
      );
    }
    return null;
  }
}
