import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  URLS,
} from '@codingninjas/networking';
import { isNullOrUndefined } from '../utils/blank-check.util';
import { Router, NavigationEnd } from '@angular/router';
import { skip, filter } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { UTMData } from '../models/utm-data.model';
import { NinjasWebSession } from '../models/ninjas-web-session.model';
import { BrowserService } from './browser.service';
import { WebSessionService } from './web-session.service';
import { getQueryParams } from '../utils/get-query-params.util';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UtmTrackingService {
  private MIN_TOKEN_LENGTH = 25;
  private domainRoot = '';
  private utmData: UTMData = {
    source: '',
    medium: '',
    campaign: '',
    content: '',
  };

  constructor(
    @Inject(PLATFORM_ID) private _platformId: object,
    private apiClient: ApiClient,
    private analyticsService: AnalyticsService,
    private browserService: BrowserService,
    private webSessionService: WebSessionService,
    private router: Router
  ) {
    if (isPlatformBrowser(this._platformId)) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          skip(1)
        )
        .subscribe(() => {
          this.updateSessionExpiry();
        });
    }
  }

  getUtmData(): UTMData {
    return this.utmData;
  }

  setUtmData(utmData: UTMData): void {
    this.utmData = utmData;
  }

  public captureUtmParameters(domainRoot) {
    this.domainRoot = domainRoot;
    const params = getQueryParams();
    const utmSource = params['utm_source'] || params['source'];
    const utmMedium = params['utm_medium'] || params['medium'];
    const utmCampaign = params['utm_campaign'] || params['campaign'];
    const utmContent = params['utm_content'];
    const referrer = document.referrer;
    const landingUrl =
      params['source_url'] || window.location.host + window.location.pathname;
    let internalReferral = false;

    if (referrer) {
      const referrer_host = new URL(referrer).hostname;
      if (referrer_host && referrer_host.includes(this.domainRoot)) {
        internalReferral = true;
      }
    }

    const utmData: UTMData = {
      source: '',
      medium: '',
      campaign: '',
      content: '',
    };

    if (utmSource && utmMedium && utmCampaign) {
      utmData.source = utmSource;
      utmData.medium = utmMedium;
      utmData.campaign = utmCampaign;
    }

    if (utmContent) {
      utmData.content = utmContent;
    }

    this.setUtmData(utmData);

    const token = this.getToken();
    if (this.isValidToken(token)) {
      this.sendUtmParameters(
        utmData,
        referrer,
        landingUrl,
        token,
        internalReferral
      );
    }
  }

  private isValidToken(token) {
    return !isNullOrUndefined(token) && token.length > this.MIN_TOKEN_LENGTH;
  }

  getToken(): string {
    const marketingTokenString = this.browserService.getMarketingToken();
    if (isNullOrUndefined(marketingTokenString)) {
      return null;
    }
    return marketingTokenString;
  }

  private sendUtmParameters(
    utmData: UTMData,
    referrer: string,
    landing_url: string,
    marketingToken: string,
    internalReferral: boolean
  ) {
    if (internalReferral) {
      return;
    }

    const isFreshSession = this.webSessionService.isFreshSession(
      utmData,
      referrer
    );

    if (isFreshSession) {
      this.webSessionService.setWebSessionCookie(referrer, utmData);
    } else {
      this.updateSessionExpiry();
    }

    if (isFreshSession) {
      const call: Call = new CallBuilder(CallMethod.Post, URLS.V2.UTM_TACKING)
        .setParam('source', utmData.source)
        .setParam('medium', utmData.medium)
        .setParam('campaign', utmData.campaign)
        .setParam('content', utmData.content)
        .setParam('referrer', referrer)
        .setParam('landing_url', landing_url)
        .setParam('marketing_token', marketingToken)
        .build();

      this.apiClient.enqueue<any>(call).subscribe();
    }
  }

  private updateSessionExpiry() {
    const webSession: NinjasWebSession =
      this.webSessionService.getWebSessionFromCookie();
    const token: string = this.getToken();
    if (isNullOrUndefined(webSession) || isNullOrUndefined(token)) {
      this.captureUtmParameters(this.domainRoot);
    } else {
      this.webSessionService.setWebSessionCookie(
        webSession.referrerHost,
        webSession.utmData
      );
    }
  }
}
