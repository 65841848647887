<div
  class="custom-ninjas-notification-container mb-12"
  [@enterLeave]="state"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
  [ngStyle]="notification.options.style"
>
  <div
    class="ninjas-notification-notice pr-20 py-12"
    [ngClass]="notification.backgroundClass"
    [ngStyle]="{
      'border-bottom-left-radius': showNotificationActions ? '0px' : '12px'
    }"
  >
    <a
      class="ninjas-notification-notice-close cursor-pointer"
      (click)="close(true)"
    >
      <mat-icon>close</mat-icon>
    </a>

    <a
      *ngIf="showReportFlag"
      class="ninjas-notification-notice-report-icon cursor-pointer"
      (click)="showActionable()"
    >
      <img
        src="https://files.codingninjas.in/vector-16912.svg"
        alt="report-flag"
      />
    </a>

    <div class="ninjas-notification-notice-content flex-row align-items-center">
      <div *ngIf="notification.icon" class="ninjas-notification-icon">
        <img [src]="notification.icon" alt="notification-icon" />
      </div>

      <div
        *ngIf="!notification.icon && notification.animatedIcon"
        class="ninjas-notification-icon"
      >
        <ng-lottie
          width="100px"
          height="100px"
          [options]="notification.animatedIcon"
        >
        </ng-lottie>
      </div>

      <div class="content-right-side flex-column">
        <div
          class="ninjas-notification-notice-message zen-subtitle-1"
          [innerHTML]="notification.title"
        ></div>
        <div
          class="ninjas-notification-notice-description zen-body-2"
          [innerHTML]="notification.description"
        ></div>
      </div>
    </div>
  </div>

  <codingninjas-custom-notification-actionable
    [showActionables]="showNotificationActions"
    [notification]="notification"
    (closeEvent)="close($event)"
    (collapseActionableEvent)="showNotificationActions = false"
  >
  </codingninjas-custom-notification-actionable>
</div>
