import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderDotsComponent } from './loader-dots.component';

@NgModule({
  declarations: [LoaderDotsComponent],
  imports: [CommonModule],
  exports: [LoaderDotsComponent],
})
export class LoaderDotsModule {}
