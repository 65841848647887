<ng-container [ngSwitch]="notification.activity_type">
  <codingninjas-regular-job-activity
    *ngSwitchCase="activitiesMap.CAREER_OPPORTUNITY_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-regular-job-activity>
  <codingninjas-deadline-job-activity
    *ngSwitchCase="activitiesMap.LAST_DAY_OPPORTUNITY_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-deadline-job-activity>
  <codingninjas-ta-job-activity
    *ngSwitchCase="activitiesMap.CAREER_OPPORTUNITY_TA_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-ta-job-activity>
  <codingninjas-monthly-recap-activity
    *ngSwitchCase="activitiesMap.MONTHLY_RECAP_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-monthly-recap-activity>
  <codingninjas-share-testimonial-activity
    *ngSwitchCase="activitiesMap.SHARE_TESTIMONIAL_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-share-testimonial-activity>
  <codingninjas-webinar-registration-activity
    *ngSwitchCase="activitiesMap.WEBINAR_REGISTER_USER_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-webinar-registration-activity>
  <codingninjas-webinar-join-activity
    *ngSwitchCase="activitiesMap.WEBINAR_JOIN_USER_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-webinar-join-activity>
  <codingninjas-new-skills-unlocked-activity
    *ngSwitchCase="activitiesMap.SKILL_TEST_UNLOCKED_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-new-skills-unlocked-activity>
  <codingninjas-book-interview-session-activity
    *ngSwitchCase="activitiesMap.BOOK_INTERVIEW_SESSION_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-book-interview-session-activity>
  <codingninjas-attend-interview-session-activity
    *ngSwitchCase="activitiesMap.ATTEND_INTERVIEW_SESSION_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-attend-interview-session-activity>
  <codingninjas-remind-referred-user-activity
    *ngSwitchCase="activitiesMap.REMIND_REFERRED_USER_ACTIVITY"
    [notification]="notification"
    [theme]="theme"
    [source]="source"
  ></codingninjas-remind-referred-user-activity>
</ng-container>
