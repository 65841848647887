import { InjectionToken } from '@angular/core';

export interface ToastNotificationConfig {
  top?: string;
  left?: string;
  bottom?: string;
  placement?:
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'bottomMiddle';
  duration?: number;
  maxStack?: number;
  pauseOnHover?: boolean;
  animate?: boolean;
  showClose?: boolean;
  audio?: string;
}

export const TOAST_NOTIFICATION_DEFAULT_CONFIG =
  new InjectionToken<ToastNotificationConfig>(
    'TOAST_NOTIFICATION_DEFAULT_CONFIG'
  );

export const TOAST_NOTIFICATION_CONFIG =
  new InjectionToken<ToastNotificationConfig>('TOAST_NOTIFICATION_CONFIG');

export const TOAST_NOTIFICATION_DEFAULT_CONFIG_PROVIDER = {
  provide: TOAST_NOTIFICATION_DEFAULT_CONFIG,
  useValue: {
    top: '25px',
    right: '24px',
    left: 'calc(50% - 164px)',
    placement: 'bottomMiddle',
    duration: 4500,
    maxStack: 3,
    pauseOnHover: true,
    animate: true,
    showClose: true,
  },
};
