import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AttendInterviewActivityNotification,
  NotificationTheme,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-attend-interview-session-activity',
  templateUrl: './attend-interview-session-activity.component.html',
  styleUrls: ['./attend-interview-session-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendInterviewSessionActivityComponent implements OnInit {
  @Input() notification: AttendInterviewActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;

  _actionPerformed = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.notification.content.icon_context += '-fill';
  }

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }

  toSentenceCase(str) {
    return (
      str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
  }
}
