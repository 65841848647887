import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthState } from './+state';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public token: string;

  constructor(
    public store: Store<AuthState>,
    @Inject('env') private environment
  ) {
    store
      .select('auth')
      .pipe(map((auth) => auth.user_access_token))
      .subscribe((token) => {
        this.token = token;
      });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.environment.app) {
      const newParams = req.params.append('app_context', this.environment.app);
      req = req.clone({
        params: newParams,
      });
    }
    if (this.environment?.code360 || this.environment?.naukriProduction) {
      const newParams = req.params.append('naukri_request', true);
      const newReq = req.clone({
        params: newParams,
      });
      req = newReq;
    } else if (this.token) {
      const newHeaders = req.headers.append(
        'Authorization',
        'Token ' + this.token
      );
      const newReq = req.clone({
        headers: newHeaders,
      });
      return next.handle(newReq);
    }

    return next.handle(req);
  }
}
