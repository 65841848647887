import { CallBuilder } from './call.builder';
import { CallMethod } from './call-method';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export class Call {
  private _url;
  private _id: string;
  private _method: CallMethod;
  private _headers: HttpHeaders;
  private _params: HttpParams;
  private _body: any;
  private _enableLoading: boolean;
  private _shouldCache: boolean;
  private _retryCount: number;

  public constructor(private callBuilder: CallBuilder) {
    this.callBuilder = this.callBuilder.shouldCache
      ? this.callBuilder
      : callBuilder.method === CallMethod.Get
      ? this.callBuilder.forcePreventCache()
      : this.callBuilder;
    this._id = Call.uuidv4();
    this._url = callBuilder.url;
    this._method = callBuilder.method;
    this._headers = callBuilder.httpHeaders;
    this._params = callBuilder.httpParams;
    this._body = callBuilder.callBody;
    this._enableLoading = callBuilder.wouldLoad;
    this._shouldCache = callBuilder.shouldCache;
    this._retryCount = callBuilder.callRetryCount;
  }

  public static uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  get url(): string {
    return this._url;
  }

  get id(): string {
    return this._id;
  }

  get method(): CallMethod {
    return this._method;
  }

  get headers(): HttpHeaders {
    return this._headers;
  }

  get params(): HttpParams {
    return this._params;
  }

  get body(): any {
    return this._body;
  }

  get wouldLoad(): boolean {
    return this._enableLoading;
  }

  get wouldCache(): boolean {
    return this._shouldCache;
  }

  get retryCount(): number {
    return this._retryCount;
  }
}
