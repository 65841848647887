import { Routes } from '@angular/router';
import { AuthGuard, AuthPageComponent } from '@codingninjas/auth';
import {
  InternalErrorComponent,
  NotFoundComponent,
} from '@codingninjas/shared-ui';
import { UnauthorisedComponent } from '../../../../libs/shared-ui/src/lib/unauthorised/unauthorised.component';
import { MainComponent } from './core/components/app-shell/main/main.component';
import { MentorsAppComponent } from './core/components/mentors-app/mentors-app.component';
import { MentorGuard } from './core/guards/mentor.guard';
import { AdminGuard } from './core/guards/admin.guard';
import { HomeRedirectGuard } from './core/guards/home-redirect.guard';

export const routes: Routes = [
  {
    path: '',
    component: MentorsAppComponent,
    canActivate: [AuthGuard, MentorGuard],
    canActivateChild: [AuthGuard, MentorGuard, HomeRedirectGuard],
    children: [
      {
        path: '',
        component: MainComponent,
        children: [
          {
            path: 'problem-evaluation',
            loadChildren: () =>
              import('./problem-evaluation/problem-evaluation.module').then(
                (m) => m.ProblemEvaluationModule
              ),
          },
          {
            path: 'flags',
            loadChildren: () =>
              import('./flagging/flagging.module').then(
                (m) => m.FlaggingModule
              ),
          },
          {
            path: 'incomplete-contents',
            loadChildren: () =>
              import('./incomplete-contents/incomplete-contents.module').then(
                (m) => m.IncompleteContents
              ),
          },
          {
            path: 'dashboard',
            loadChildren: () =>
              import('./dashboard/dashboard.module').then(
                (m) => m.DashboardModule
              ),
          },
          {
            path: 'doubts',
            loadChildren: () =>
              import('./doubts/doubts.module').then((m) => m.DoubtsModule),
          },
          {
            path: 'calendar',
            loadChildren: () =>
              import('./calendar/calendar.module').then(
                (m) => m.CalendarModule
              ),
          },
          {
            path: 'personalized-schedule',
            loadChildren: () =>
              import(
                './personalized-schedule/personalized-schedule.module'
              ).then((m) => m.PersonalizedScheduleModule),
          },
          {
            path: 'project-evaluation',
            loadChildren: () =>
              import('./project-evaluation/project-evaluation.module').then(
                (m) => m.ProjectEvaluationModule
              ),
          },
          {
            path: 'taship',
            loadChildren: () =>
              import('./taship/taship.module').then((m) => m.TashipModule),
          },
          {
            path: 'slot-schedule',
            loadChildren: () =>
              import(
                './slot-schedule-request/slot-schedule-request.module'
              ).then((m) => m.SlotScheduleRequestModule),
          },
          {
            path: 'stats',
            loadChildren: () =>
              import('./ta-stats/ta-stats.module').then((m) => m.TaStatsModule),
            canActivate: [AdminGuard],
          },
          {
            path: 'tasks',
            loadChildren: () =>
              import('./tasks/tasks.module').then((m) => m.TasksModule),
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    component: AuthPageComponent,
  },
  { path: 'fourofour', component: NotFoundComponent },
  { path: 'unauthorised', component: UnauthorisedComponent },
  { path: '500', component: InternalErrorComponent },
  { path: '**', redirectTo: '/fourofour' },
];
