export const URLS = {
  GET_PINCODE_DETAILS:
    'https://shipping.codingninjas.in/api/v1/get_postal_code_details',
  AWS: {
    UPLOAD_SCREENSHOT: 'https://api-gateway.codingninjas.in/upload-data',
  },
  CREATE_HINT_VIDEO_FEEDBACK: 'v3/video_feedback/create',
  RECORD_OFFERING_COMPLETION: 'v4/user_completion/record_offering_completion',
  GET_WEB_SCAFFOLD: 'v2/get_web_scaffold',
  FRONTEND_SOLUTION_FILE: 'v2/get_frontend_solution_file',
  PROBLEM_ASSETS: 'v2/problem_assets',
  NODE_URL: 'https://node.codingninjas.com/users',
  FRONTEND_SUBMISSION: 'submission/submit_frontend_submission',
  FRONTEND_SUBMISSION_V3: 'v3/submission/frontend',
  V2: {
    VIDEO_OTP: 'v2/video/otp',
    TEST_CASES: 'v2/testcases',
    GET_HINT_VIDEO: 'v2/hint_video/get',
    HINT_FILE: 'v2/hint_file/get',
    VIDEO_CODE: 'v2/video/code',
    GET_RANDOM_VIDEO_FEEDBACK_QUESTION: 'v2/video_feedback/get_random_question',
    LOGIN: 'v2/users/login',
    AUTH_DETAILS: 'v2/users/auth_details',
    ALL_PEERS: 'v2/users/all_peers',
    RECENTLY_MESSAGED_PEERS: 'v2/users/recently_messaged_peers',
    USER_PROFILE: 'v2/users/profile',
    SET_USER_WE_PARAMS: 'v2/users/set_we_params',
    LOGOUT: 'v2/users/logout',
    EMAIL_LOGIN: 'v2/users/email_login',
    FB_EMAIL_MERGE_INIT: 'v2/users/merge_fb_email_otp',
    FB_EMAIL_MERGE_VERIFY: 'v2/users/merge_fb_email_otp_verify',
    PHONE_LOGIN: 'v2/users/phone_login',
    SEARCH_COLLEGES: 'v2/colleges/search',
    MARKETING_ADD_SOURCE: 'v2/users/marketing/add_source',
    UTM_TACKING: 'v2/users/marketing/utm_tracking',
    SEND_FEEDBACK: 'v2/users/send_feedback',
    UPDATE_STATUS: 'v2/users/availability_status',
    FETCH_TIME_ZONE: 'v2/fetch_time_zone',
    RESET_PASSWORD: 'v2/users/reset_password',
    LOGIN_STATUS: 'v2/users/login_status',
    NAUKRI_VERIFICATION: 'v2/users/naukri_verification',
    USER_LOCATION: 'v2/users/user_location',
    FETCH_STATES: 'v2/users/fetch_states',
    FETCH_CITIES: 'v2/users/fetch_cities',
    USER_TRACKING_TYPE: 'v2/users/get_user_tracking_type',
    GET_DAILY_EVENT_CTA: 'v2/users/get_daily_event_cta',
    GET_UNVERIFIED_EMAIL_STATUS: 'v2/users/get_unverified_email_status',
    ADD_NEW_EMAIL_AND_GET_OTP: 'v2/users/get_add_email_otp',
    ADD_NEW_EMAIL_RESEND_OTP: 'v2/users/add_email_resend_otp',
    ADD_NEW_EMAIL_OTP_VERIFY: 'v2/users/add_email_otp_verify',
    IDENTITY_SELECTION: 'v2/users/identities_selection',
    GET_USER_INFO: 'v2/users/info',
    START_LEARNING_DATA: 'v2/start_learning_data',
    APPLY_REFERRAL_CODE: 'v2/users/apply_referral_code',
    DOWNLOAD_SQL_OUTPUT: 'v2/download_sql_output',
    DOWNLOAD_SQL_DATA: 'v2/download_sql_data',
    PLACEMENT_PREP_REGISTRATION: 'v2/users/placement-prep',
    IS_USER_ALUMNI: 'v2/user/is_alumni',
    ADD_BOOKMARK: 'v2/add_bookmark',
    REMOVE_BOOKMARK: 'v2/remove_bookmark',
    FOLLOWER_FOLLOWING_LIST: 'v2/users/followers',
    FOLLOW_UNFOLLOW: 'v2/users/follow_unfollow',
    CHECK_FOLLOWING: 'v2/users/check_following',
    REFER_BY_EMAIL: 'v2/users/send_referral_email',
    REFERRAL_CONFIRMATION: 'v2/users/send_referral_confirmation',
    REFER_BY_PHONE_NUMBER: 'v2/users/send_referee_communication',
    CHECK_VALID_NAME: 'v2/users/check_valid_name',
    FLASH_CARD: 'v2/flash_cards',
    ALL_FLASH_CARD: 'v2/all_flash_cards',
    CONTENT_ASKED_DOUBTS: 'v2/users/content_doubts',
    NOTE: 'v2/notes/get_note',
    PROBLEM_SCAFFOLD_FILE: '/v2/problem/scaffold',
    PROBLEM_SUBMISSION: {
      SUBMIT_MCQ: 'v2/submission/submit_mcq',
      SUBMIT_FILL_UP: 'v2/submission/submit_fill_up',
    },
    VIEW_MCQ_SOLUTION: 'v2/solution/view_solution_mcq',
    RUN_PROBLEM: 'v2/run_problem',
    PROJECT_SUBMIT: 'v2/project/submit',
    PROJECT_LINK_SUBMISSION: 'v2/project_submissions/project_link_submission',
    MARK_FOR_EVALUATION: 'v2/project/mark_for_evaluation',
    DATASCIENCE_DATA: 'v2/download_datascience_data',
    DATASCIENCE_SUBMISSION: 'v2/submission/datascience',
    COURSE_EXTENSION_URLS: {
      FREE_PRODUCT_EXTENSION: 'v2/products/free_extension',
      COURSE_PRODUCT_PAID_EXTENSION_PAYMENT: 'v2/products/extension',
    },
    ML_DATA: 'v2/ml_data',
    ML_SUBMISSION: 'v2/submission/submit_ml',
    ML_LEADERBOARD: 'v2/submission/ml_leaderboard',
    ML_SUBMISSION_FILE: 'v2/submission/ml_submitted_file',
    REPORT_ML_SOLUTION: 'v2/submission/report_ml_project_solution',
    ML_NOTEBOOK_FILE: 'v2/submission/ml_notebook_file',
    CONFIRM_SOLUTION: 'v2/solution/confirm',
    SUBMIT_TEST: 'v2/test/submit',
  },
  V3: {
    REFERRAL_TRIGGER_DATA: 'v3/referral/trigger_data',
    APPLY_USER_UTM_DISCOUNT:
      'v3/user_discount_coupon_mappings/apply_user_utm_discount',
    USER_REFERRED_INFO: 'v3/referral/user_referred_info',
    SCHOLARSHIP_PAGE_DATA: 'v3/scholarship_page_data',
    ABOUT_US: 'v3/about_us',
    REFEREE_DATA: 'v3/referee_page_data',
    ABOUT_V2_SCHEMA: 'v3/about_v2_schema',
    ABOUT_US_V2: 'v3/about_us_v2',
    AFFILIATE_PAGE_DATA: 'v3/affiliate_page',
    FETCH_CARD_DETAILS: 'v3/library_page_card',
    HOME_DATA: 'v3/home_data',
    HOME_DATA_V2: 'v3/home_data_v2',
    CUSTOMERS: 'v3/customers',
    CUSTOMER_TESTIMONIALS: 'v4/testimonials',
    TESTIMONIALS_COURSE_DETAILS: 'v4/testimonials/course_details',
    COLLABORATIONS: 'v3/collaborations/apply',
    AICTE_SPOC_REGISTRATION: 'v3/collaborations/aicte/spoc/registration',
    PUBLIC_RELATION_CARDS: 'v3/public_relations',
    PRESS_RELEASE_ARTICLES: 'v3/press_release_articles',
    PRESS_RELEASE_PAGE_DATA: 'v3/press_release_page_data',
    BANNER_DATA: 'v3/banner_data',
    NEW_SEARCH_COLLEGES: 'v3/colleges/new_search',
    NEW_SEARCH_COMPANIES: 'v3/company/new_search',
    ADD_COMPANY: 'v3/company/add_company',
    SEARCH_ADVANCED_COLLEGES: 'v3/colleges/advanced_search',
    NEW_SEARCH_SCHOOLS: 'v3/schools/new_search',
    SEARCH_ADVANCED_SCHOOLS: 'v3/schools/advanced_search',
    EXTRA_INFO_BANNER: 'v3/extra_info_banner_data',
    COURSES: 'v3/courses',
    COURSE: 'v3/course',
    FREE_TRIAL_COURSE: 'v3/free_trial_course',
    SEND_CURRICULUM: 'v3/send_curriculum',
    PRODUCT: 'v3/product',
    PRODUCT_COMMON: 'v3/common_product',
    CHECK_COUPON: 'v3/products/check_coupon',
    ADD_ON_SIBLING_PRODUCT: 'v3/add_on_sibling_products',
    FETCH_RAZORPAY_EMI_PLANS: 'v3/products/razorpay_emi_plans',
    FETCH_NBFC_EMI_PLANS: 'v3/products/nbfc_emi_plans',
    FETCH_NET_BANKING_BANKS: 'v3/products/fetch_net_banking_banks',
    FETCH_WALLETS: 'v3/products/fetch_wallets',
    ADD_ON_MOCK_INTERVIEW_PRODUCTS: 'v3/add_on_mock_interview_products',
    LOG_PREMIUM_PAYMENT: 'v3/log_premium_payment',
    REGISTER: 'v3/products/register',
    VISIT_CHECKOUT: 'v3/products/checkout_visit',
    EVENTS_LIST: 'v3/events',
    GET_EVENT_TAGS: 'v3/event_tags',
    GET_LANDING_POPUP: 'v3/get_popup',
    EVENTS_DETAILS: 'v3/event_details',
    CS_EVENTS_DETAILS: 'v3/cs_event_details',
    SEND_LINK_TO_MYSELF: 'v3/send_link_to_self',
    EVENTS_DESCRIPTION: 'v3/event_description',
    MARK_ATTENDANCE: 'v3/event_mark_attendance',
    COURSE_PAGE_CONTACT_FORM: 'v3/course/add_contact_course_page_form',
    CODESTUDIO_HOME_COURSE_PUCHASE_CONTACT_FORM:
      'v3/course/add_contact_codestudio_page_form',
    PARTNER_USER_CAPTURE_DATA: 'v3/partners/partner_analytics_data',
    USER: {
      MARK_USER_EVENT: 'v3/users/events',
      CREATE_ACTION_RECORD: 'v3/users/action_records',
    },
    CONTACT_SUPPORT: {
      FORM_SUBMITTED: 'v3/batch/contact_support',
      NOTIFICATION_DISMISSED: 'v3/batch/contact_support_dismissed',
    },
    COURSE_PAUSE_UPDATE_BANNER: {
      GET_STATUS: 'v3/batch/course_pause_update_banner_status',
      DISMISS: 'v3/batch/course_pause_update_banner_dismiss',
    },
    DEADLINE_MISS_SUPPORT: {
      FORM_SUBMITTED: 'v3/batch/deadline_miss_support',
      NOTIFICATION_DISMISSED: 'v3/batch/deadline_miss_support_dismissed',
    },
    INDUSTRY_MENTORS: {
      INTERVIEWS: {
        DATA: 'v3/industry_mentor/interviews',
        ALL_SLOTS: 'v3/industry-mentors/interviews/all-slots',
        NEW_SLOTS: {
          RANGE: 'v3/industry-mentors/interviews/new-slots/range',
          CUSTOM: 'v3/industry-mentors/interviews/new-slots/custom',
        },
        GET_STUDENT_REMARKS:
          'v3/industry-mentors/interviews/get_student_remarks',
        INTERVIEW_FEEDBACK: 'v3/industry-mentors/interviews/feedback',
      },
    },
    FREE_CONTENT: {
      CONTENTS: 'v3/free_content/contents',
      CONTENT: 'v3/free_content/content',
      VIDEO_DATA: 'v3/free_content/video_data',
      SEND_NOTES: 'v3/free_content/send_notes',
    },
    INTERVIEWS: {
      INTERVIEWER: {
        CANCEL_INTERVIEW: 'v3/interviews/interviewer/cancel',
        DELETE_INTERVIEW_SLOT: 'v3/interviews/interviewer/delete-slot',
        DELETE_ALL_INTERVIEW_SLOTS:
          'v3/interviews/interviewer/delete-all-slots',
        MARK_COMPLETED: 'v3/interviews/interviewer/mark_completed',
        MARK_INTERVIEWER_ABSENT:
          'v3/interviews/interviewer/mark-interviewer-absent',
        MARK_INTERVIEWEE_ABSENT:
          'v3/interviews/interviewer/mark-interviewee-absent',
        MARK_INTERRUPTED: 'v3/interviews/interviewer/mark_interrupted',
      },
      INTERVIEWEE: {
        CANCEL_INTERVIEW: 'v3/interviews/interviewee/cancel',
        MARK_COMPLETED: 'v3/interviews/interviewee/mark_completed',
        MARK_INTERVIEWER_ABSENT:
          'v3/interviews/interviewee/mark-interviewer-absent',
        MARK_INTERRUPTED: 'v3/interviews/interviewee/mark_interrupted',
      },
      RAISE_CONCERN: 'v3/interviews/raise_concern',
    },
    CAREER_CAMP: {
      FETCH_CC_TEST_EVALUATION_STATUS:
        'v3/career_camp/freshers/fetch_cc_test_evaluation_status',
      FETCH_DOCUMENT_STATUS: 'v3/career_camp/fetch_document_status',
      FRESHERS_HOMEPAGE: 'v3/career_camp/freshers/homepage',
      FETCH_MILESTONE_DATA: 'v3/career_camp/freshers/milestone_data',
      FRESHER_CALLBACK_LSQ_EVENT: 'v3/career_camp/freshers/callback_lsq_event',
      FETCH_CURRENT_UNLOCKED_MILESTONE:
        'v3/career_camp/freshers/current_unlocked_milestone',
      UPLOAD_MARKSHEET: 'v3/career_camp/freshers/upload_marksheet',
      SELF_INITIATE_SIGN_DOCUMENT: 'v3/career_camp/self_initiate_sign_document',
      HOME: {
        RECENT_OPENINGS: 'v3/career_camp/home/recent_openings',
        REGISTRATION_DATA: 'v3/career_camp/home/registration_data',
      },
      APPLICATION: {
        AVAILABLE_PRODUCTS: 'v3/career_camp/application/available_products',
        APPLICATION_DETAILS: 'v3/career_camp/application/application_details',
        FETCH_COURSE_PRODUCT: 'v3/career_camp/application/fetch_course_product',
        PERSONAL_INFO: {
          DETAILS: 'v3/career_camp/application/personal_info/details',
          SUBMIT: 'v3/career_camp/application/personal_info/submit',
        },
        EDUCATION_INFO: {
          DETAILS: 'v3/career_camp/application/education_info/details',
          SUBMIT: 'v3/career_camp/application/education_info/submit',
        },
        WORK_EXP_INFO: {
          DETAILS: 'v3/career_camp/application/work_exp_info/details',
          SUBMIT: 'v3/career_camp/application/work_exp_info/submit',
        },
        LEAD_SQUARED: {
          REGISTRATION_INITIATED:
            'v3/career_camp/lead_squared/registration_initiated',
        },
      },
      PROFESSIONAL_PLAN_DETAILS: 'v3/career_camp/professional_plan/details',
      PROFESSIONAL_APPLICATION: {
        AVAILABLE_PRODUCTS:
          'v3/career_camp/professional_application/available_professional_products',
        APPLICATION_DETAILS:
          'v3/career_camp/professional_application/professional_application_details',
        PERSONAL_INFO: {
          DETAILS:
            'v3/career_camp/professional_application/personal_info/details',
          SUBMIT:
            'v3/career_camp/professional_application/personal_info/submit',
        },
        EDUCATION_INFO: {
          DETAILS:
            'v3/career_camp/professional_application/education_info/details',
          SUBMIT:
            'v3/career_camp/professional_application/education_info/submit',
        },
        WORK_EXP_INFO: {
          DETAILS:
            'v3/career_camp/professional_application/work_exp_info/details',
          SUBMIT:
            'v3/career_camp/professional_application/work_exp_info/submit',
        },
        LEAD_SQUARED: {
          APPLICATION_STARTED:
            'v3/career_camp/professionals_application/lead_squared/application_started',
        },
      },
      COURSE_INFO: 'v4/career_camp/course/bootcamp_courses_info',
    },
    CLASSROOM: {
      CONTENTS: 'v3/classroom/contents',
      CONTENT: 'v3/classroom/content',
      PROJECT_SCAFFOLD: 'v3/classroom/project/scaffold',
      PROJECT_SUBMISSION_FILE: 'v3/classroom/download_project_file',
      SUBMISSION: {
        CODE: 'v2/submission/submit_code',
        SQL: 'v2/submission/sql',
      },
    },
    PROFILE: {
      GET_INFO: 'v3/profile/get_info',
      UPDATE_SKILLS: 'v3/profile/update_skills',
      UPDATE_ABOUT_ME: 'v3/profile/update_about_me',
      UPDATE_OR_ADD_EXPERIENCE: 'v3/profile/update_or_add_work_experience',
      UPDATE_OR_ADD_PROJECT: 'v3/profile/update_or_add_project',
      UPDATE_OR_ADD_EDUCATION: 'v3/profile/update_or_add_education',
      UPDATE_DP: 'v3/profile/update_dp',
      UPDATE_NAME: 'v3/profile/update_name',
      UPDATE_BASIC_INFO: 'v3/profile/update_basic_info',
      DELETE_EDUCATION: 'v3/profile/delete_education',
      DELETE_PROJECT: 'v3/profile/delete_project',
      DELETE_WORK_EXPERIENCE: 'v3/profile/delete_work_experience',
      DOWNLOAD_RESUME: 'v3/profile/download_resume',
      SET_DEFAULT_LANGUAGE: 'v3/profile/set_default_langugage',
      UPDATE_USER_PREFERENCES: 'v3/profile/update_user_preferences',
      FETCH_USER_PREFERENCES: 'v3/profile/user_preferences',
    },
    CAMPUS: {
      TOP_CONTRIBUTORS: 'v3/campus-top-contributors',
      PRIZES_WINNERS: 'v3/campus-prizes-winners',
      EVENT_DASHBOARD: 'v3/campus/event_registration_dashboard',
      UPCOMING_EVENTS: 'v3/campus/upcoming_events',
      FETCH_CAMPUS_AMBASSADOR: 'v3/campus/fetch_campus_ambassador',
      CAMPUS_TASK_ALL: 'v3/campus/task/all',
      CAMPUS_ACCEPT_TASK: 'v3/campus/task/accept',
      CAMPUS_SUBMIT_TASK: 'v3/campus/task/submit',
      CAMPUS_UPLOAD_IMAGE: 'v3/campus/task/upload/image',
      AMBASSADOR_INFO: 'v3/campus/ambassador_info',
      LEADERBOARD_DETAILS: 'v3/campus/leaderboard_details',
      NINJA_ENTREPRENEUR_APPLICATION:
        'v3/campus/ninja_entrepreneur_application',
    },
    BOOTCAMP: {
      HEADER_CALL: 'v3/bootcamp/header_data',
      BASIC_PROFILE: 'v3/bootcamp/basic_profile',
      EDUCATION_PROFILE: 'v3/bootcamp/education_profile',
      WORK_PROFILE: 'v3/bootcamp/work_profile',
      BOOTCAMP_PROFILE: 'v3/bootcamp/bootcamp_profile',
      SET_BATCH: 'v3/bootcamp/set_batch',
      GET_APPLICATION_DETAILS: 'v3/bootcamp/get_application_details',
      GET_APPLICATION_MILESTONE_DETAILS:
        'v3/bootcamp/get_application_milestone_details',
      ADD_STUDENT_PACKAGE_RESPONSE: 'v3/bootcamp/add_student_package_response',
      DOWNLOAD_USER_DOC: 'v3/bootcamp/download_user_doc',
      GET_USER_INFO: 'v3/get_user_info',
      STUDENT_APPLY: 'v3/student_apply',
      PROFESSIONAL_APPLY: 'v3/professional_apply',
      SAVE_EDUCATIONS: 'v3/bootcamp/education_profile',
      FORM_CONFIG: 'v3/bootcamp/form_config',
      ENTRANCE_TEST: 'v3/bootcamp/entrance_test',
    },
    MENTORS: {
      PERSONALIZED_SCHEDULE: {
        INITIAL_BATCHES: 'v3/mentors/personalized_schedule/initial_batches',
        GET_FULL_SCHEDULE: 'v3/mentors/personalized_schedule/get_full_schedule',
        REMOVE_TOPIC_FROM_SCHEDULE:
          'v3/mentors/personalized_schedule/remove_topic_from_schedule',
        ADD_TOPICS_TO_SCHEDULE:
          'v3/mentors/personalized_schedule/add_topics_to_schedule',
        EDIT_COMMENTS: 'v3/mentors/personalized_schedule/edit_comments',
        GET_COMMENT_HISTORY:
          'v3/mentors/personalized_schedule/get_comment_history',
        GET_SINGLE_USER_ALL_SCHEDULES:
          'v3/mentors/personalized_schedule/get_single_user_all_schedules',
      },
      WORKSPACE: {
        TA_WORKSPACE_MARK_ONLINE: 'v3/mentors/mark_ta_workspace_online',
        TA_WORKSPACE_MARK_OFFLINE: 'v3/mentors/mark_ta_workspace_offline',
      },
      DASHBOARD: {
        FETCH_TASHIP_DATA: 'v3/mentors/fetch_taship_data',
        METRICS: 'v3/mentors/vital_metrics',
        LATEST_TASHIP_PARAMETERS: 'v3/mentors/latest_taship_parameters',
        DOUBT_RATING_STATS: 'v3/mentors/doubt_rating_stats',
        SHARED_CODE_INSTANCES: 'v3/mentors/shared_code_instances',
        DELETE_FLAG: 'v3/mentors/delete_flag',
        LOW_RATING_REASON_STATS: 'v3/mentors/low_rating_reason_stats',
        AUTOMATED_FLAG_REJECTED_STATS:
          'v3/mentors/get_automated_flag_rejected_stats',
        HIGH_RATING_REASON_STATS: 'v3/mentors/high_rating_reason_stats',
        DOUBT_FLAGGING_STATS: 'v3/mentors/doubt_flagging_stats',
        DOUBT_FLAGGING_DATA: 'v3/mentors/flag_data',
        DOUBT_RESOLUTION_STATS: 'v3/mentors/doubt_resolution_stats',
        DOUBT_POINTS_STATS: 'v3/mentors/doubt_points_stats',
        DOUBT_METRICS: 'v3/mentors/doubt_metrics',
        SLOT_METRICS: 'v3/mentors/slot_metrics',
        ASSIGNED_STUDENTS: 'v3/mentors/assigned_students',
        GET_URGENT_PROJECTS: 'v3/mentors/get_urgent_projects',
        SEND_CUSTOM_MAIL: 'v3/mentors/send_custom_mail_to_assigned_students',
        GET_INCOMPLETE_PROBLEMS: 'v3/mentors/incomplete_problems',
        LEAVE_COUNT: 'v3/mentors/leave_count',
        OVERTIME_SLOTS: 'v3/mentors/overtime_slots',
        BOOK_OVERTIME_SLOT: 'v3/mentors/overtime_slots/book',
      },
      FLAGGING: {
        FLAGGED_DOUBTS: 'v3/mentors/get_flagged_doubts',
        FILTER_OPTIONS: 'v3/mentors/get_filter_options',
        UPDATE_FLAG_STATUS: 'v3/mentors/update_flag_status',
        GET_FLAGGED_USERS: 'v3/mentors/get_flagged_users',
      },
      FLAG_EVALUATION: {
        FILTER_OPTIONS: 'v3/mentors/get_flag_evaluation_options',
        SUBMIT: 'v3/mentors/submit_flag_evaluation',
        GET_FLAG_EVALUATION: 'v3/mentors/get_flag_evaluation',
      },
      CALENDAR: {
        EVENTS: 'v3/mentors/calendar/events',
      },
      PROJECT_EVALUATION: {
        ASSIGN_PROJECT_TO_NEW_TA: 'v3/mentors/assign_project_to_new_ta',
        ASSIGN_STUDENT_TO_NEW_TA: 'v3/mentors/assign_studet_to_new_ta',
        FETCH_TA_STATS: 'v3/mentors/fetch_ta_reports',
        FETCH_EVALUATED_STUDENTS: 'v3/mentors/fetch_project_evaluated_students',
        FETCH_COURSE_MODULE: 'v3/mentors/fetch_projects_course_modules',
        FETCH_OVERALL_STATS: 'v3/mentors/course_modules_overall_stats_admin',
        GET_ALL_URGENT_PROJECTS: 'v3/mentors/get_all_urgent_projects',
        GET_ALL_EVALUATED_PROJECTS: 'v3/mentors/get_all_evaluated_projects',
        FETCH_PROJECT_COURSE_MODULES: 'v3/mentors/ta/course_modules',
        ASSIGNED_PROJECT_EVALUATION_DATA:
          'v3/mentors/get_project_submission_listings',
        EVALUATED_PROJECT_DATA: 'v3/mentors/get_evaluation_detail',
        GET_ADMISSION_TA_TIMELINE: 'v3/mentors/get_admission_ta_timeline',
        GET_TA_STATS_IMAGE_LINK: 'v3/mentors/gen_stats_image',
        METRICS: 'v3/mentors/get_project_evaluation_stats',
        GET_URGENT_PROJECTS: 'v3/mentors/get_urgent_projects',
      },
      WHATSAPP: {
        FETCH_FILTERS: 'v3/mentors/whatsapp/get_filters',
        FETCH_FILTER_OPTIONS: 'v3/mentors/whatsapp/get_filter_options',
        FETCH_USERS: 'v3/mentors/whatsapp/get_users',
        FETCH_TEMPLATES: 'v3/mentors/whatsapp/get_template_options',
        SEND_TEMPLATE_MESSAGE: 'v3/mentors/whatsapp/send_template_message',
        FETCH_CHAT_CONTACTS: 'v3/mentors/whatsapp/get_chat_contacts',
        FETCH_CHAT_PANEL_USER: 'v3/mentors/whatsapp/get_chat_panel_user',
        FETCH_CHATS: 'v3/mentors/whatsapp/get_chats',
        FETCH_MESSAGES: 'v3/mentors/whatsapp/get_messages',
        SEND_MESSAGE: 'v3/mentors/whatsapp/send_message',
        FETCH_CONTACT: 'v3/mentors/whatsapp/get_single_contact',
        FETCH_OVERALL_DATA: 'v3/mentors/whatsapp/get_overall_data',
        FETCH_FILTERED_DATA: 'v3/mentors/whatsapp/get_filtered_data',
      },
      FETCH_COURSE_NAMES: 'v3/mentors/fetch_courses',
      DOUBT_STATS: {
        FETCH_COURSE_MODULE: 'v3/mentors/fetch_course_modules',
        FETCH_DOUBT_STATS: 'v3/mentors/fetch_doubt_stats',
        FETCH_DOUBT_ACTIVITIES: 'v3/mentors/fetch_doubt_activities',
        FETCH_STUDENTS: 'v3/mentors/search_students',
      },
      CAREER_CAMP: {
        BATCH_REPORT: 'v3/mentors/stats/get_career_camp_report',
      },
      SLOT_SCHEDULE_REQUEST: {
        FETCH_DOUBT_TEAM_MEMBERS: 'v3/mentors/fetch_doubt_team_members',
        FETCH_SLOT_REQUESTS: 'v3/mentors/fetch_slot_requests',
        LEAVE_REQUEST: 'v3/mentors/create_leave_request',
        RESCHEDULE_REQUEST: 'v3/mentors/create_leave_reschedule_request',
        FETCH_ALL_SLOTS: 'v3/mentors/get_all_slots',
        FETCH_CURRENT_DATE_SLOTS: 'v3/mentors/get_ta_current_slot',
        UPDATE_REQUEST: 'v3/mentors/update_slot_requests',
        GET_LEAVE_REQUEST: 'v3/mentors/get_leave_requests',
        CANCEL_LEAVE_REQUEST: 'v3/mentors/cancel_request',
        FETCH_COURSE_MODULE: 'v3/mentors/fetch_modules_slot_change',
        GET_SLOT_CHANGE_REQUEST: 'v3/mentors/get_reschedule_requests',
        GET_ALL_REQUEST: 'v3/mentors/slot_schedule_all_requests',
      },
      SHARED_REQUEST: {
        FETCH_TAWISE_REPORRT: 'v3/mentors/get_tawise_requests',
      },
      FETCH_TA_STATS: 'v3/mentors/get_ta_stats',
      FETCH_COURSE_TA_STATS: 'v3/mentors/get_course_ta_stats',
      FETCH_TA_DOUBT_TARGETS: 'v3/mentors/get_doubt_target_stats',
      TASHIPS: 'v3/mentors/taships',
      METRICS: 'v3/mentors/metrics',
      DOWNLOAD_TASHIP_CERTIFICATE: 'v3/mentors/download_certificate',
      SAVE_COMPENSATION_DATA: 'v3/mentors/save_compensation_data',
      VIEW_COMPENSATION_DATA: 'v3/mentors/view_compensation_data',
      FETCH_MENTOR_USER: 'v3/mentors/mentor_user',
      ASSIGN_DOUBT: 'v3/mentors/assign_doubt',
      ACTIVE_DOUBTS: 'v3/mentors/active_doubts',
      DOUBT_CALL_STATS: 'v3/mentors/fetch_call_stats',
      GET_MENTOR_SLACK_DATA: 'v3/mentors/get_mentor_slack_group_data',
      REVIEW_PENDING_DOUBTS: 'v3/mentors/review_pending_doubts',
      FETCH_DOUBT_ACTIVITY_STATUS: 'v3/mentors/get_doubt_activity_status',
      ACCEPT_DOUBT: 'v3/mentors/accept_doubt',
      REJECT_DOUBT: 'v3/mentors/reject_doubt',
      UNASSIGN_DOUBT: 'v3/mentors/unassign_doubt',
      INACTIVATE_DOUBT_SESSION: 'v3/mentors/inactivate_doubt_session',
      RESOLVED_DOUBTS: 'v3/mentors/resolved_doubts',
      PENDING_ON_USER_DOUBTS: 'v3/mentors/pending_on_user_doubts',
      AVAILABLE_DOUBTS: 'v3/mentors/available_doubts',
      ALL_TA_ACTIVE_DOUBTS: 'v3/mentors/all_ta_active_doubts',
      ASSIGN_AND_ACCEPT_DOUBT: 'v3/mentors/assign_and_accept_doubt',
      COURSE_MODULES: 'v3/mentors/course_modules',
      MODULE_DOUBT_USERS: 'v3/mentors/get_module_doubt_users',
      ALL_DOUBTS_OF_USER: 'v3/mentors/get_all_doubts_of_user',
      UPDATE_DOUBT_BASE_PRIORITY: 'v3/mentors/update_doubt_base_priority',
      DOUBT_STUDENT_FEEDBACK: 'v3/mentors/rate_student',
      USER_CREATE_FLAG: 'v3/mentors/create_flag',
      AVAILABLE_DOUBTS_COUNT: 'v3/mentors/available_doubts_count',
      COURSE_MODULE_TA: 'v3/mentors/get_course_module_tas',
      ALL_CUSTOM_ASSIGNED_DOUBTS: 'v3/mentors/get_all_custom_assigned_doubts',
      CUSTOM_ASSIGN_DOUBT_TO_MENTOR: 'v3/mentors/custom_assign_doubt_to_mentor',
      CUSTOM_ASSIGNED_DOUBTS: 'v3/mentors/get_custom_assigned_to_mentor_doubts',
      COURSE_MODULE_DOUBT_STATS: 'v3/mentors/course_module_doubt_stats',
      ACTIVE_DOUBT_DETAILS: 'v3/mentors/get_active_doubt_details',
      FETCH_DAILY_TARGET: 'v3/mentors/get_ta_today_target',
      FETCH_DOUBT_REJECT_COUNT: 'v3/mentors/get_doubt_reject_count',
      FETCH_SINGLE_DOUBT_DETAILS: 'v3/mentors/get_doubt_details',
      SLACK_INVITE_LINK_CLICKED: 'v3/slack/clicked_mentor_invite_link',
      TASK_GROUP: 'v3/mentors/get_task_group',
      SUBMIT_CALL_FEEDBACK: 'v3/mentors/submit_call_feedback',
      UPDATE_CALL_STATUS: 'v3/mentors/update_call_status',
      UPDATE_TASK_SCHEDULE: 'v3/mentors/update_task_schedule',
      TASK_GROUP_FROM_SLUG: 'v3/mentors/get_task_group_from_slug',
      SEARCH_TASK_GROUPS: 'v3/mentors/search_task_groups',
      RECENT_TASK_GROUPS: 'v3/mentors/recent_task_groups',
      CALL_TASK_LIST: 'v3/mentors/call_task_list',
      SINGLE_CALL_TASK: 'v3/mentors/single_call_task',
      UPDATE_FLAG_TASK_STATUS: 'v3/mentors/update_flag_task_status',
      GET_MENTOR_PROJECT_EVALUATION_TARGET:
        'v3/mentors/mentor_project_evaluation_target',
      MARK_DASHBOARD_ONLINE: 'v3/mentors/mark_dashboard_online',
      USER_BATCH_INFO: 'v3/mentors/user_batches_info',
      MONTHLY_SHAREABLE_CERTIFICATE:
        'v3/mentors/ta_monthly_shareable_certificate',
      MONTHLY_SHAREABLE_CERTIFICATE_CTA:
        'v3/mentors/ta_monthly_shareable_certificate_cta',
    },
    PAYPAL: {
      CREATE_ORDER: 'v3/products/create_pending_payment_order',
      EXECUTE_PAYMENT: 'v3/products/execute_paypal_order',
    },
    RAZORPAY: {
      CREATE_ORDER: 'v3/products/create_pending_payment_order',
      EXECUTE_PAYMENT: 'v3/products/execute_razorpay_order',
    },
    PUBLIC_SECTION: {
      // @todo, sort urls properly
      SURVEY: {
        CREATE_SURVEY: 'v3/public_section/survey/create_survey',
        GET_TRIGGER_STATE: 'v3/public_section/survey/fetch_nps_trigger_state',
        UPDATE_DISMISS_INFO: 'v3/public_section/survey/update_dismiss_info',
      },
      FETCH_EXPERIENCES: 'v3/public_section/fetch_experiences',
      FETCH_ALL_EXPERIENCE_FILTERS: 'v3/public_section/all_experience_filters',
      FETCH_LIST_PAGE_META_DETAILS:
        'v3/public_section/fetch_list_page_meta_details',
      FETCH_SINGLE_INTERVIEW_EXPERIENCE:
        'v3/public_section/fetch_single_interview_experience',
      FETCH_INTERVIEW_EXPERIENCE_AB: 'v3/public_section/fetch_ie_ab_group',
      FETCH_SINGLE_INTERVIEW_META_DATA:
        'v3/public_section/fetch_single_interview_meta_data',
      FETCH_TOP_INTERVIEW_EXPERIENCES:
        'v3/public_section/top_interview_experiences',
      USER_DETAILS: 'v3/public_section/user_details',
      SAVE_USER_LOGS: 'v3/public_section/save_user_logs',
      FETCH_PROBLEM_SOLUTION: 'v3/public_section/problem_solution',
      FETCH_SQL_PROBLEM_SOLUTION: 'v3/public_section/sql_problem_solution',
      FETCH_PROBLEM_APPROACH: 'v3/public_section/problem_approaches',
      GET_PROBLEM_RATING_STATUS: 'v3/public_section/problem_rating',
      SAVE_PROBLEM_RATING: 'v3/public_section/save_problem_rating',
      GET_LANGUAGE_CODE: 'v3/public_section/get_language_code',
      FETCH_PROBLEM_LIST: 'v3/public_section/all_problems',
      FETCH_RECOMMENDED_PROBLEM_LIST: 'v3/public_section/recommended_problems',
      FETCH_WEB_PROJECT_LIST: 'v3/public_section/all_project_problems',
      DISCUSSIONS: 'v3/public_section/discussions',
      ARTICLES: 'v3/public_section/articles',
      FETCH_COMMUNITY_POST_PROBLEM_LIST:
        'v3/public_section/community_post_problem_list',
      FETCH_USER_SUBMISSION_STATS: 'v3/public_section/user_submission_stats',
      FETCH_ALL_PROBLEM_FILTERS: 'v3/public_section/all_filters',
      FETCH_ALL_PROJECT_FILTERS: 'v3/public_section/all_project_filters',
      FETCH_WEB_ATTEMPTED_STATS: 'v3/public_section/fetch_web_attempted_stats',
      FETCH_WEB_SUBJECT_MASTERY_DATA: 'v3/public_section/subject_mastery_data',
      FETCH_ALL_CHALLENGES: 'v3/public_section/cs_challenge/list',
      FETCH_TOP_PROBLEM_LIST: 'v3/public_section/list_page_top_lists',
      FETCH_ALL_PROBLEM_LISTS: 'v3/public_section/problem_lists',
      LAST_VISITED_PROBLEM_LIST: 'v3/public_section/last_visited_problem_list',
      FETCH_TOP_PROBLEMS: 'v3/public_section/top_list_questions',
      FETCH_TOP_PROBLEMS_CONFIG: 'v3/public_section/top_list_config',
      FETCH_NAVIGATION_PROBLEM_LIST:
        'v3/public_section/navigation_problem_list',
      PROBLEM_OFFERING_SOLUTION_LOGS: 'v3/public_section/solution_view_logs',
      FETCH_PROBLEM_TOP_TOPICS: 'v3/public_section/list_page_top_topics',
      FETCH_HIGHLIGHT_VIDEO: 'v3/public_section/list_page_highlight_video',
      FETCH_TOP_COMPANIES: 'v3/public_section/landing/top_companies',
      FETCH_RECENT_EXPERIENCES: 'v3/public_section/landing/recent_experiences',
      FETCH_RECENT_PROBLEMS: 'v3/public_section/landing/recent_problems',
      FETCH_HIGHLIGHT_EVENT: 'v3/public_section/landing/highlight_event',
      FETCH_HIGH_PRIORITY_EVENTS:
        'v3/public_section/landing/high_priority_events',
      FETCH_POPULAR_VIDEOS: 'v3/public_section/landing/popular_videos',
      FETCH_POPULAR_BLOGS: 'v3/public_section/landing/popular_blogs',
      GET_EXPLORE_DATA: 'v3/public_section/landing/explore',
      GET_RESUME_ACTIVITY: 'v3/public_section/resume_activity',
      GET_ACHIEVEMENT_DATA: 'v3/public_section/user_achievements',
      GET_TRANSACTIONAL_ACTIVITY: 'v3/public_section/transactional_activity',
      GET_USER_PROBLEM_LIST_PROGRESS: 'v3/public_section/user_progress',
      GET_USER_CURATED_LIST_PROGRESS:
        'v3/public_section/user_curated_list_progress',
      GET_USER_CURATED_COMPANY_LIST_PROGRESS:
        'v3/public_section/user_curated_company_progress',
      GET_REFERRAL_DATA: 'v3/public_section/referral/referral_data',
      GET_REGISTERED_REFEREES: 'v3/public_section/referral/registered_referees',
      GET_CONTACT_EMAILS: 'v3/public_section/referral/user_contact_emails',
      SEND_REFERRAL_EMAIL: 'v3/public_section/referral/send_referral_email',
      SEND_BULK_REFERRAL_EMAIL:
        'v3/public_section/referral/send_bulk_referral_email',
      GET_USER_LANGUAGE_SUBMISSIONS:
        'v3/public_section/user_language_submissions',
      SAVE_CONTRIBUTED_CODE: 'v3/public_section/save_contributed_code',
      LAST_SUCCESSFUL_SUBMISSION:
        'v3/public_section/last_successful_submission',
      NEW_PROBLEM_SUGGESTION: 'v3/public_section/problem_suggestion',
      PROBLEM_LIST_REGISTRATION_STATUS:
        'v3/public_section/problem_lists/registration_status',
      PROBLEM_LIST_REGISTER: 'v3/public_section/problem_lists/register',
      CUSTOM_TEST_CASE_LOG: 'v3/public_section/custom_test_case_log',
      REPORT_ISSUE_IN_CODE: 'v3/public_section/save_solution_issue',
      CONTRIBUTE_TO_APPROACH:
        'v3/public_section/user_generated_content/contribute_approach',
      REPORT_ISSUE_ON_PROBLEM:
        'v3/public_section/user_generated_content/report_problem_issue',
      REPORT_ISSUE: 'v3/public_section/report_issue',
      GET_FULL_PROBLEM_DESCRIPTION:
        'v3/public_section/full_problem_description',
      GET_SIMILAR_PROBLEMS_SEO_PAGE:
        'v3/public_section/similar_problems_seo_page',
      GET_SIMILAR_PROBLEMS_CATEGORY_PAGE:
        'v3/public_section/similar_problems_category_page',
      ALL_HINTS_FOR_PROBLEM: 'v3/public_section/get_hints',
      PROBLEM_VIDEO_SOLUTION: 'v3/public_section/problem_video_solution',
      HINT_VIEW_COUNT: 'v3/public_section/hint_view_count',
      CODESTUDIO_PROBLEM_SLUG: 'v3/public_section/problem_slug',
      RESOURCES: 'v3/public_section/resources',
      VIDEO_RESOURCES: 'v3/public_section/video_resources',
      RESOURCE_PLAYLIST: 'v3/public_section/resources/playlist',
      GET_STREAKS_PROGRESS: 'v3/public_section/streaks/progress',
      GET_CURR_AND_LONG_STREAK:
        'v3/public_section/streaks/fetch_curr_and_long_streak',
      GET_LEVELS_PROGRESS: 'v3/public_section/levels/progress',
      GET_USER_CURRENT_STREAK: 'v3/public_section/streaks/current',
      GET_USER_STREAK_MONTH_MAP: 'v3/public_section/streaks/month_map',
      GET_CONTRIBUTIONS: 'v3/public_section/profile/contributions',
      GET_CONCEPT_OF_THE_DAY: 'v3/public_section/concept_of_the_day',
      REFERRABLE_INVITE: 'v3/public_section/send_invite',
      GUIDED_PATH: {
        CHECK_CERTIFICATE_NAME:
          'v3/public_section/guided_path/check_certificate_name',
        GET_ALL_PATHS: 'v3/public_section/guided_path/all_paths',
        GET_PATH_DETAILS_BY_SLUG: 'v3/public_section/guided_path/details',
        ENROLL_INTO_GUIDED_PATH: 'v3/public_section/guided_path/enroll',
        FETCH_COMPLETION_CERTIFICATE:
          'v3/public_section/guided_path/certificate',
        FETCH_CONTENT_DETAILS: 'v3/public_section/guided_path/content_details',
        FETCH_OFFERING_DETAILS:
          'v3/public_section/guided_path/offering_details',
        NOTE_COMPLETION_RECORD:
          'v3/public_section/guided_path/note_completed_record',
        NAUKRI_S2J: 'v3/public_section/guided_path/naukri_s2j',
      },
      SET_ACADEMIC_DATA: 'v3/onboarding/update_academic_detail',
      PROFILE: {
        USER_DETAILS: 'v3/public_section/profile/user_details',
        PROFILE_VIEWS: 'v3/public_section/profile/capture_profile_view',
        USER_EDIT_DETAILS: 'v3/public_section/profile/user_edit_details',
        UPDATE_GENDER: 'v3/public_section/profile/gender',
      },
      SUBMISSION: {
        CODE: 'v3/public_section/submission/submit_code',
        MULTIPLE: 'v3/public_section/submission/submit_mcq',
        FILL_UP: 'v3/public_section/submission/submit_fill_up',
        SQL: 'v3/public_section/submission/submit_sql',
        LIST: 'v3/public_section/submission/list',
        CODE_EXECUTION_LOG: 'v3/public_section/submission/code_execution_log',
        OTHER_USERS_TOP_SUBMISSIONS:
          'v3/public_section/submission/other_users_top_submissions',
        OTHER_USER_TOP_SUBMISSION_CODE:
          'v3/public_section/submission/top_submission_code',
      },
      PROJECT_DETAILS: 'v3/public_section/project_detail',
      PROBLEM_DETAILS: 'v3/public_section/problem_detail',
      ONLINE_COMPILER_CODE_RUN: 'v3/public_section/playground/code_run',
      CUSTOM_TESTCASE: 'v3/public_section/custom_test_case',
      SUBMITTED_CODE: 'v3/public_section/submitted_code',
      TEST_CASES: 'v3/public_section/testcases',
      RUN_PROBLEM: 'v3/public_section/run_problem',
      FETCH_TEST_SERIES_LIST: 'v3/public_section/test_series_list',
      FETCH_TEST_SERIES_DETAILS: 'v3/public_section/test_series_details',
      FETCH_TEST_SERIES_PROBLEM_LIST:
        'v3/public_section/test_series_problem_list',
      START_TEST_SERIES_TEST: 'v3/public_section/start_test_series_test',
      SUBMIT_TEST_SERIES_TEST: 'v3/public_section/submit_test_series_test',
      CONTEST: {
        FETCH_CONTEST_PROBLEM_LIST: 'v3/public_section/contest_problem_list',
        FETCH_CONTEST_PROBLEM_DETAILS:
          'v3/public_section/contest_problem_details',
        FETCH_CONTEST_LIST: 'v3/public_section/contest_list',
        FETCH_RECENT_CONTEST_RESULTS:
          'v3/public_section/recent_contests_result',
        FETCH_VIRTUAL_CONTEST_RESULT:
          'v3/public_section/virtual_contest_result',
        START_CONTEST: 'v3/public_section/start_contest',
        SUBMIT_CONTEST: 'v3/public_section/submit_contest',
        FETCH_GLOBAL_CONTEST_LEADERBOARD:
          'v3/public_section/contest_global_leaderboard',
        FETCH_CONTEST_LEADERBOARD: 'v3/public_section/contest_leaderboard',
        FETCH_CONTEST_DETAILS: 'v3/public_section/contest_details',
        FETCH_CONTEST_REGISTERED_USER_RESPONSE:
          'v3/public_section/contest_registered_user_response',
        NAUKRI_CHECK_CONTEST_REGISTRATION_STATUS:
          'v3/public_section/check_registration_status',
      },
      READING_CLUB: {
        FETCH_RESOURCE_DETAILS: 'v3/public_section/resource_details',
        FETCH_SIMILAR_ARTICLES: 'v3/public_section/similar_articles',
        FETCH_SIBLING_ARTICLE_DETAILS:
          'v3/public_section/sibling_article_details',
        FETCH_ARTICLE_FEATURED_CONTENT:
          'v3/public_section/article_featured_content',
      },
      CAMPUS: {
        FETCH_CAMPUS_HOME_DETAIL: 'v3/public_section/cs_campus_home',
      },
      INTERVIEW_BUNDLE: {
        FETCH_COMPANY_LIST: 'v3/public_section/company_list',
        FETCH_COMPANY_DETAILS: 'v3/public_section/company_details',
        FETCH_COMPANY_PROBLEMS: 'v3/public_section/company_problem_list',
        FETCH_COMPANY_IE: '/v3/public_section/fetch_company_experiences',
        FETCH_COMPANY_TEST_SERIES: 'v3/public_section/company_test_series',
        FETCH_COMPANY_GP: 'v3/public_section/guided_path/top_gp_list',
        FETCH_OTHER_COMPANIES: 'v3/public_section/random_companies_list',
      },
      GET_SNACKBAR_PROMOTIONS: 'v3/public_section/promotions/snackbar',
      COMMUNITY: {
        FETCH_POST_DETAILS: 'v3/public_section/community_post_detail',
        FETCH_COMMUNITY_POSTS: 'v3/public_section/community_post_list',
      },
      CAMPUS_MANAGEMENT: {
        FETCH_CAMPUS_INFO: 'v3/public_section/fetch_campus_details',
        FETCH_CAMPUS_MODERATORS: 'v3/public_section/fetch_campus_moderators',
        FETCH_CAMPUS_LEADERBOARD: 'v3/public_section/fetch_campus_leaderboard',
        CREATE_POST: 'v3/public_section/community_post',
        UPDATE_POST: 'v3/public_section/community_post_update',
        FETCH_CAMPUS_CATEGORY_LIST: 'v3/public_section/categories',
        FETCH_CAMPUS_TAG_LIST: 'v3/public_section/tags',
        FETCH_CAMPUS_TAG_AUTOCOMPLETE: 'v3/public_section/tags/autocomplete',
        FETCH_CAMPUS_POSTS: 'v3/public_section/community_post_list',
        UPDATE_USER_ROLE: 'v3/public_section/update_user_role',
        BLOCK_CAMPUS_USER: 'v3/public_section/campus_user_block_toggle',
        REPORT_CAMPUS_POST: 'v3/public_section/campus/report',
        FETCH_CAMPUS_MEMBERS: 'v3/public_section/fetch_user_campus_members',
        FETCH_REPORTED_COMMENTS: 'v3/public_section/fetch_reported_comments',
        UPDATE_REPORT_STATUS: 'v3/public_section/campus/report/update',
        FETCH_INTERCAMPUS_LEADERBOARD:
          'v3/public_section/fetch_inter_campus_leaderboard',
      },
      SCREEN_NAME: {
        SCREENNAME_SUGGESTIONS: 'v3/public_section/name_suggestions',
        SAVE_SCREENNAME: 'v3/public_section/set_screen_name',
        CHECK_VALID_SCREENNAME: 'v3/public_section/check_valid_screen_name',
      },
      PROBLEM_OF_THE_DAY: {
        SINGLE_PROBLEM_DETAILS: 'v3/public_section/potd/single_problem_detail',
        PROBLEM_LIST: 'v3/public_section/potd/problem_list',
        SKILLS: 'v3/public_section/potd/skill_map',
        PROBLEM_DETAILS: 'v3/public_section/potd/problem_detail',
        PROBLEM_DETAILS_MCQ: 'v3/public_section/potd/problem_detail_mcq',
        START: 'v3/public_section/challenge/start',
        END: 'v3/public_section/challenge/end',
        USER_CHALLENGE_STATUS: 'v3/public_section/challenge/user_status',
        USER_OFFERING_STATUS:
          'v3/public_section/challenge/user_offering_status',
        DIFFICULTY_LEADERBOARD:
          'v3/public_section/challenge/offering_leaderboard',
        OVERALL_LEADERBOARD: 'v3/public_section/challenge/overall_leaderboard',
        USER_STREAK: 'v3/public_section/challenge/user_streak',
        GET_SUBMITTED_CODE: 'v3/public_section/challenge/submitted_code',
        USER_EDITOR_EVENTS: 'v3/public_section/challenge/user_editor_events',
        SKILL_RESET: 'v3/public_section/potd/reset_skill',
      },
      JOURNEY_GOALS: {
        FETCH_ALL_GOAL: 'v3/public_section/fetch_journey_goals',
        SET_USER_GOAL: 'v3/public_section/add_goal_for_user',
        FETCH_GOAL_DETAILS: 'v3/public_section/fetch_goal_detail',
        SELECT_CUSTOM_ELEMENTS: 'v3/public_section/choose_phase_elements',
        FETCH_USER_GOAL_STATUS: 'v3/public_section/user_goal_status',
        SEND_DATA_TO_SHEET: 'v3/public_section/goal_dashboard_user_response',
        FETCH_ELEMENT_DETAILS: 'v3/public_section/fetch_element_details',
        SET_GUIDED_PATH_LIST_GP: 'v3/public_section/set_guided_path_list_gp',
        MARK_LAST_VIEWED_ELEMENT: 'v3/public_section/mark_last_viewed_element',
        FETCH_ACTION_CARD_DETAILS: 'v3/public_section/fetch_action_cards_data',
        SET_NAUKRI_LANDING_EVENT: 'v3/public_section/set_naukri_landing_event',
        PARTNER: {
          FIRST_NAUKRI: {
            SET_GOAL_LANGUAGE: 'v3/partners/fn/user_journey_language',
          },
        },
      },
      FETCH_PRESET_TAGS: 'v3/public_section/tags/fetch_or_create_preset_tags',
      CREATE_VIDEO_WATCH_LOG: 'v3/public_section/create_video_watch_log',
      AB_TEST: {
        FETCH_ADD_ABTEST_GROUP: 'v3/public_section/fetch_abtest_group',
      },
      UNREGISTER: 'v3/public_section/contest_unregister',
      ADD_CALENDAR_EVENT: 'v3/public_section/add_event_to_calendar',
      FETCH_PRACTICE_TOPICS_DATA: 'v3/public_section/practice_topics_data',
      FETCH_USER_CONTEST_RATING_DATA: 'v3/public_section/user_rating_data',
      SEND_OFFERING_TO_SELF: 'v3/public_section/offering_send_to_self',
      FETCH_USER_BADGES: 'v3/public_section/user_active_badges',
      MARK_BADGES_ACKNOWLEDGED: 'v3/public_section/badges_acknowledged',
      MARK_STREAK_ACKNOWLEDGED: 'v3/public_section/streak_acknowledged',
      FETCH_BADGES_CERTIFICATE: 'v3/public_section/fetch_badge_certificate',
      BADGE_RENAME_POPUP: 'v3/public_section/badge_rename_popup',
      NAUKRI_ONBOARDING_POPUP:
        'v3/public_section/naukri_onboarding_popup_status',
      FETCH_CONTEST_RATING_CERTIFICATE:
        'v3/public_section/fetch_contest_rating_certificate',
      FETCH_XP_POINT_DETAILS: 'v3/public_section/experience_points_details',
      FETCH_HISTORY: 'v3/public_section/experience_points_history',
      FETCH_BOOSTERS_LIST: 'v3/public_section/daily_boosters_aggregate',
      FETCH_DAILY_EXP_INFO: 'v3/public_section/daily_goal_info',
      APPLY_DELAYED_BOOSTER: 'v3/public_section/apply_delayed_booster',
      FETCH_ALL_POLICIES: 'v3/public_section/policies',
      FETCH_USER_WOW_DATA: 'v3/public_section/fetch_user_wow_retention_data',
      ONLINE_COMPILERS: {
        FETCH_ALL_COMPILERS: 'v3/public_section/online_compilers',
        FETCH_COMPILER_DETAILS:
          'v3/public_section/online_compilers/online_compiler_details',
      },
      CHALLENGES: {
        FETCH_DETAIL: 'v3/public_section/cs_challenge/details',
        REGISTER_USER: 'v3/public_section/cs_challenge/register',
        USER_STATS: 'v3/public_section/cs_challenge/user_stats',
        PROBLEM_LIST: 'v3/public_section/cs_challenge/problems',
        LEADERBOARD: 'v3/public_section/cs_challenge/leaderboard',
        ACHIEVEMENTS_CERTIFICATE:
          'v3/public_section/cs_challenge/achievements_certificate',
      },
      USER_ONBOARDING: {
        FETCH_ONBOARDING_PREFERENCES:
          'v3/public_section/onboarding/user_preferences',
        SET_ONBOARDING_PREFERENCE:
          'v3/public_section/onboarding/set_user_preferences',
      },
      ANALYTICS_EVENTS: {
        CREATE_ANALYTICS_EVENT: 'v3/public_section/analytics_events/create',
      },
      HOME: {
        SET_USER_OBJECTIVE: 'v3/public_section/set_user_objective',
        FETCH_USER_LAST_JOURNEY_ACTIVITY:
          'v3/public_section/user_last_journey_activity',
      },
    },
    REACTIONS: {
      TOGGLE_UPVOTE_DOWNVOTE: 'v3/reactions/toggle_upvote_downvote_reaction',
    },
    SET_USER_NAUKRI_SOURCE: 'v2/users/set_source',
    CLOSE_ALUMINI_DISCOUNT_BANNER: 'v3/close_alumini_disount_banner',
    GET_PENALTY_WIDGET_STATUS: 'v3/get_penalty_widget_status',
    DISMISS_PENALTY_WIDGET: 'v3/dismiss_penalty_widget',
    VIDEO_RECORDINGS: 'v3/interviews/interviewee/video_recordings',
    WHATS_NEW_DETAIL: 'v3/public_section/whats_new_details',
    WHATS_NEW_HIGHLIGHT: 'v3/public_section/whats_new_highlights',
    ABANDONED_CART_DETAILS: 'v3/abandoned_cart_details',
    DISMISS_ABANDONED_CART: 'v3/dismiss_abandoned_cart_widget',
    SUBMISSIONS: {
      SUBMISSION_LIST: 'v3/submission/list',
      SUBMISSION_DETAILS: 'v3/submission/detail',
      DOWNLOAD_SUBMISSION: 'v3/submission/download',
      DOWNLOAD_SOLUTION: 'v3/submission/download_solution',
      PROBLEM_SUBMISSION: 'v3/problem_submission',
      FETCH_FRONTEND_SUBMISSION: 'v3/submission/submitted_web_code',
      SUBMISSION_TOKEN: 'v3/submission/token',
    },
    AWARDS: {
      AWARDS_ACKNOWLEDGE: 'v3/classroom/badges/acknowledge',
      AWARD_ACHIEVEMENT_CARD: 'v3/classroom/badges/achievement_card',
      FETCH_BADGES_CERTIFICATE: 'v3/public_section/fetch_badge_certificate',
    },
    COURSE_OFFERINGS: 'v3/course_offerings',
    GET_HINT_VIDEO_FEEDBACK: 'v3/video_feedback/get',
    SHARE_ENTITY_URL: 'v3/communicate/entity_url',
  },
  V4: {
    LATEST_USER_FORM_RESPONSE: 'v4/latest_user_form_response',
    RATE_PROJECT: 'v4/projects/rate',
    REFUND_FORM: 'v4/refund_form',
    AWARDS: {
      USER_AWARD_PROGRESS: 'v4/classroom/badges/progress',
    },
    OPEN_AI: {
      DEBUG_CODE: 'v4/open_ai/debug_code',
    },
    DOMAIN_LEARNING: {
      DASHBOARD: 'v4/domain_learning/dashboard',
      LEARNING_PATH: 'v4/domain_learning/learning_path',
      UNRESOLVED_DOUBTS: 'v4/domain_learning/unresolved_doubts',
      RESOLVED_DOUBTS: 'v4/domain_learning/resolved_doubts',
      CERTIFICATES: 'v4/domain_learning/certificates',
    },
    USER_LEARNING_PREFERENCES: {
      DISMISS_LEARNING_PREFERENCE_POPUP: 'v4/dismiss_learning_preference_popup',
      DISMISS_LEARNING_PREFERENCE_WIDGET:
        'v4/dismiss_learning_preference_widget',
      ADD_LEARNING_PREFERENCE: 'v4/add_learning_preference',
    },
    TOPIC_CELEBRATION: {
      GET_TOPIC_CELEBRATION_DATA: 'v4/topic_celebration',
      DISMISS_TOPIC_CELEBRATION: 'v4/dismiss_topic_celebration',
    },
    PROBLEM_COMPLETION_CELEBRATION: {
      MUTE_NOTIFICATION: 'v4/classroom/mute_completion_notification',
    },
    Widgets: {
      DISMISS_GOODIES_BANNER: 'v4/users/dismiss_goodies_banner',
    },
    GET_PROBLEM_TOP_SCORERS: 'v4/problem/top_scorers',
    COUNTRY_LIST: 'v4/country_state/get_country_list',
    STATE_LIST: 'v4/country_state/get_state_list',
    CITY_LIST: 'v4/country_state/get_city_list',
    CITY_LIST_WITHOUT_STATE: 'v4/get_city_list',
    CITY_AUTOCOMPLETE_LIST: 'v4/cities/autocomplete',
    ORDER: 'v4/post_checkout/order_details',
    SET_BILLING_ADDRESS_DATA: 'v4/orders/set_billing_address',
    SET_USER_INFO: 'v4/users/update_info',
    UPDATE_SHOW_NAUKRI_UI: 'v4/users/update_show_naukri_ui',
    FETCH_BILLING_ADDRESS_DATA: 'v4/orders/fetch_billing_address',
    DOWNLOAD_PLACEMENT_DATA: 'v4/home/download_placement_report',
    MOCK_INTERVIEW_DETAILS: 'v4/service_details',
    MOCK_INTERVIEW_ENQUIRY: 'v4/mock-interview-enquiry',
    MOCK_INTERVIEW_AVAILABILITY: 'v4/mock_interview_availability',
    MOCK_INTERVIEW_PRIORITY_SCHEDULE: 'v4/mock_interview_priority_schedule',
    USER_WEEKLY_PERFORMANCE_DASHBOARD:
      'v4/dashboards/user_weekly_performance_dashboard',
    LIVE_CLASS_INSTRUCTOR: 'v4/dashboards/live_class_instructor',
    GET_ADDRESS_VERIFICATION_DATA: 'v4/get_address_verification_data',
    SET_ADDRESS_VERIFICATION_DATA: 'v4/set_address_verification_data',
    SUBMIT_FEEDBACK: 'v4/feedback/submit_feedback',
    SUBMIT_LIVE_CLASS_FEEDBACK: 'v4/feedback/live_class',
    GET_REACTION_FEEDBACK: 'v4/users/get_feedback',
    GET_FEEDBACK: 'v4/feedback/get_feedback',
    AUTHORIZE_GOOGLE_CALENDAR_OFFLINE:
      'v4/google_calendar_offline_authorization',
    GET_FESTIVE_OFFERS_BANNER: 'v4/get_landing_offer_banner',
    GET_EARLY_BIRD_DISCOUNT_OFFER: 'v4/early_bird_discount_offer',
    GET_PROFESSIONAL_COURSE_PAGE_BANNER:
      'v4/get_professional_course_page_banner',
    GET_FEATURED_CONTENTS: 'v4/featured_content',
    GET_STUDIO_FEATURED_CONTENTS: 'v4/studio_featured_content',
    MARK_PROBLEM_COMPLETE: 'v4/problem/completion_mark',
    MARK_OFFERING_COMPLETE: 'v4/offering/completion_mark',
    PROFILE: {
      GET_INFO: 'v4/profile/get_info',
      UPDATE_SKILLS: 'v4/profile/update_skills',
      UPDATE_ABOUT_ME: 'v4/profile/update_about_me',
      UPDATE_OR_ADD_EXPERIENCE: 'v4/profile/update_or_add_work_experience',
      UPDATE_OR_ADD_PROJECT: 'v4/profile/update_or_add_project',
      UPDATE_OR_ADD_EDUCATION: 'v4/profile/update_or_add_education',
      UPDATE_DP: 'v4/profile/update_dp',
      UPDATE_BASIC_INFO: 'v4/profile/update_basic_info',
      DELETE_EDUCATION: 'v4/profile/delete_education',
      DELETE_PROJECT: 'v4/profile/delete_project',
      DELETE_WORK_EXPERIENCE: 'v4/profile/delete_work_experience',
      DOWNLOAD_RESUME: 'v4/profile/download_resume',
      UPDATE_USER_INFO: 'v4/profile/update_user_info',
      UPDATE_PROFILE_IMAGE: 'v4/profile/update_profile_image',
      GET_RESUMES: 'v4/profile/get_resumes',
      DELETE_RESUME: 'v4/profile/delete_resume',
      UPLOAD_RESUME: 'v4/profile/upload_resume',
      UPDATE_USER_PROFILE_LINKS: 'v4/profile/update_profile_links',
      DELETE_PROFILE_LINK: 'v4/profile/delete_link',
      GET_CERTIFICATES: 'v4/profile/get_certificates',
      GET_SKILLS: 'v4/profile/get_user_skills',
      GOAL_AND_COLLEGE_DETAILS: 'v4/profile/user_details_and_goals',
      UPDATE_COLLEGE_AND_GRAD_YEAR: 'v4/profile/update_college_and_graduation',
      USER_IT_SKILLS: 'v4/profile/user_it_skills',
      UPDATE_USER_IT_SKILL: 'v4/profile/update_user_it_skill',
      DELETE_USER_IT_SKILL: 'v4/profile/delete_user_it_skill',
      GET_USER_NOTIFICATION_PREFERENCE:
        'v4/notifications/users/notification_preferences',
      UPDATE_NOTIFICATION_PREFERENCES_DATA:
        'v4/notifications/users/notification_preferences/update',
    },
    PREFERENCE: {
      PLACEMENT_PREFERENCES: 'v4/placements/placement_preferences',
      PREFERENCE_OPTIONS: 'v4/placements/preference_options',
    },
    PLACEMENT_INFO: {
      USER_PLACEMENT_INFO: 'v4/placements/placement_info_options',
      UPDATE_PLACEMENT_INFO: 'v4/placements/placement_info',
    },
    TECHVARSITY: {
      PAGE_DATA: 'v4/techvarsity_course_data',
      EXPERIENCE_TYPE_DATA: 'v4/techvarsity_experience_type_data',
    },
    COURSE: {
      COURSES_INFO: 'v4/course/courses_info',
      STUDENT_PROFESSIONAL_COURSES_INFO:
        'v4/course/student_professional_courses',
      COURSE_PLANS: 'v4/course/course_plans',
      COURSE_PLAN_PRODUCTS: 'v4/course/course_group_products',
      COURSE_DATA: 'v4/course/course_data',
      COURSE_APPLICABLE_DISCOUNTS: 'v4/course/course_applicable_discounts',
      COURSES_PAGE_DATA: 'v4/courses_page_data',
      COURSE_PLAN_SELECTION_FORM: 'v4/course/log_plan_selection',
      CURRICULUM_DATA: 'v4/course/curriculum_data',
      STUDENT_DATA: 'v4/course_placement_lead_form',
      SHOW_FREE_TRIAL_AB_TEST: 'v4/course/show_free_trial',
      BOOTCAMP_COURSES_INFO: 'v4/course/bootcamp_courses_info',
    },
    CLASSROOM: {
      STREAKS: {
        STREAK_STATE: 'v4/classroom/streak_state',
        UPDATE_PREFERENCE: 'v4/classroom/update_learning_preference',
        STREAKS_HISTORY: 'v4/classroom/streaks_history',
      },
      CHEAT_DAYS: {
        SCHEDULE_CHEAT_DAYS: 'v4/classroom/course/pause',
        RESUME_CHEAT_DAYS: 'v4/classroom/course/resume',
        VALIDATE_CHEAT_DAYS_DURATION: 'v4/classroom/course/validate_pause',
        CHEAT_DAYS_HISTORY: 'v4/classroom/course/pause_history',
      },
      FUTURE_BATCH_LAUNCH: {
        START_MODULE_EARLY: 'v4/classroom/start_module_early',
        START_LEARNING_MODULE_EARLY: 'v4/classroom/start_learning_module_early',
        CREATE_EARLY_DEPENDENCY_RECORD:
          'v4/classroom/create_early_dependency_record',
        EARLY_ACCESS_BATCHES: 'v4/classroom/early_access_batches',
      },
      SOFT_SKILL_TRAINING: {
        PRODUCTS: 'v4/classroom/soft_skill_training/products',
        REGISTER: 'v4/classroom/soft_skill_training/register',
      },
      CONTACT_SUPPORT: {
        FORM_SUBMITTED: 'v4/classroom/contact_support_request',
        NOTIFICATION_DISMISSED: 'v4/classroom/contact_support_dismissed',
      },
      COURSE_PAUSE_UPDATE_BANNER: {
        GET_STATUS: 'v4/classroom/course_pause_update_banner_status',
        DISMISS: 'v4/classroom/course_pause_update_banner_dismiss',
      },
      INTERVIEW_PREPARATION: {
        DASHBOARD_MODULE_DATA: 'v4/classroom/interview_preparation_modules',
        MODULE_CONTENT_DETAILS:
          'v4/classroom/interview_prep_module_content_details',
        MODULE_TOPICS_DETAILS: 'v4/classroom/interview_prep_module_topics',
      },
      GET_EM_DATA: 'v4/classroom/em_data',
      PRODUCT_DETAILS: 'v4/classroom/product_details',
      LAST_VISITED_DETAILS: 'v4/classroom/last_visited_details',
      WEEKLY_LEADERBOARD_BANNER_DATA:
        'v4/classroom/weekly_leaderboard_banner_data',
      ACHIEVER_LIST_DATA: 'v4/classroom/leaderboard/achiever_list_data',
      DISMISS_CONGRATULATE_ACHIEVER_BANNER:
        'v4/classroom/leaderboard/dismiss_congratulate_achiever_banner',
      LEADERBOARD: {
        WEEKLY_LEADERBOARD_PAGE_DATA:
          'v4/classroom/leaderboard/weekly_leaderboard_data',
        WEEKLY_LEADERBOARD_HIGHER_GROUP_CUTOFF:
          'v4/classroom/leaderboard/higher_group_cutoff',
        CLOSE_TOP_FINISHES_TOOLTIP:
          'v4/classroom/leaderboard/close_top_finishes_tooltip',
      },
      SKILL_TESTS: 'v4/classroom/skill_tests/skill_tests_details',
      MODULES: 'v4/classroom/modules',
      TOPICS: 'v4/classroom/topics',
      GENERATE_CERTIFICATE: 'v4/classroom/generate_certificate',
      PRODUCT_SKILL_SETS: 'v4/classroom/product_skill_sets',
      LEARNING_DOMAIN_SKILL_SETS: 'v4/classroom/learning_domain_skill_sets',
      COURSE_PRODUCT_SKILL_SETS: '/v4/classroom/course_product_skill_sets',
      PLACEMENT_PREP: {
        TEST_ANALYSIS: 'v4/classroom/placement_prep/test_analysis',
        SEND_TEST_LINK: 'v4/classroom/placement_prep/send_test_link',
      },
      BUDDY: {
        BUDDY_BANNER_STATUS: 'v4/classroom/buddy_sessions/banner_status',
        GET_SLOTS: 'v4/classroom/buddy_sessions/available_buddy_slots',
        BOOK_SLOT: 'v4/classroom/buddy_sessions/book_buddy_session',
        SAVE_INTEREST_DATA: 'v4/classroom/buddy_sessions/save_interest_data',
        SUBMIT_DISMISS_REASON:
          'v4/classroom/buddy_sessions/submit_dismiss_reason',
        DISMISS_AFTER_BOOKING:
          'v4/classroom/buddy_sessions/dismiss_after_booking',
      },
      MODULE_SKILL_TEST: 'v4/classroom/module_skill_test_data',
      DISMISS_UNLOCKED_SKILL_TEST_POPUP:
        'v4/classroom/dismiss_unlocked_skill_test_popup',
      BOOKMARKS: 'v4/classroom/bookmarks',
      MARK_BOOKMARK_REVISED: 'v4/classroom/mark_bookmark_revised',
      MODULE_OUTLINE: 'v4/classroom/module_outline',
      REGULAR_PAUSE_OVERLAP_CHECK: 'v4/classroom/regular_pause_overlap_check',
      PAUSE_COURSE: 'v4/classroom/pause_course',
      RESUME_COURSE: 'v4/classroom/resume_course',
      PRODUCT_HISTORY: 'v4/classroom/course_pause_history',
      UNRESOLVED_DOUBTS: 'v4/classroom/unresolved_doubts',
      RESOLVED_DOUBTS: 'v4/classroom/resolved_doubts',
      PRODUCT_ONBOARDING_DATA: '/v4/classroom/product_onboarding_data',
      SHOW_POPUPS: 'v4/classroom/show_popups',
      INTERVIEW_SESSIONS: {
        DASHBOARD: 'v4/classroom/interview_sessions/dashboard',
        TABLE_DATA: 'v4/classroom/interview_sessions/interviews_data',
        BOOKING_OPTIONS: 'v4/classroom/interview_sessions/booking_options',
        SLOT_OPTIONS: 'v4/classroom/interview_sessions/slot_options',
        BOOK_SESSION: 'v4/classroom/interview_sessions/book_session',
      },
      SLACK: {
        DRAWER: 'v4/classroom/slack/drawer',
        USER_WORKSPACE_JOINING_STATUS:
          'v4/classroom/slack/user_workspace_joining_status',
      },
      LEADERBOARD_DATA: 'v4/classroom/module_leaderboard',
      HIRIST: {
        GET_BUTTON_STATUS: 'v4/classroom/hirist_requests/status',
        SUBMIT_HIRIST_REQUEST:
          'v4/classroom/hirist_requests/hirist_request_submission',
      },
      VOUCHERS: {
        GET_USER_VOUCHERS: 'v4/classroom/user_vouchers',
        GET_VOUCHER_CODE: 'v4/classroom/generate_voucher_code',
      },
      ONBOARDING: {
        DASHBOARD_ONBOARDING_COMPLETION:
          'v4/classroom/complete_dashboard_onboarding',
        MILESTONE_ONBOARDING_COMPLETION:
          'v4/classroom/complete_milestone_onboarding',
        PARALLEL_MODULES_ONBOARDING_COMPLETION:
          'v4/classroom/complete_parallel_modules_onboarding',
        INSTALL_PWA_ONBOARDING_COMPLETION:
          'v4/classroom/mark_install_pwa_banner_shown',
      },
      PLACEMENT: {
        FETCH_PLACED_NINJAS: 'v4/classroom/placement/placed_ninjas_details',
        FETCH_PLACED_COLLEGE_MATES:
          'v4/classroom/placement/placed_college_mates',
        FETCH_PLACED_COURSE_MATES: 'v4/classroom/placement/placed_course_mates',
        FETCH_PLACED_PRODUCT_BASED_MATES:
          'v4/classroom/placement/placed_product_based_mates',
        DEMONSTRATION_DATA: 'v4/classroom/placement/demonstration_data',
      },
      USER_ACTIVITY: {
        FETCH_ALL_ACTIVITIES: '/v4/classroom/user_activities/activities',
        DISMISS_USER_ACTIVITIES:
          '/v4/classroom/user_activities/dismiss_activity',
      },
      USER_NOTIFICATION_ACTIVITY: {
        FETCH_ALL_ACTIVITIES:
          'v4/classroom/user_activities_notifications/user_activities',
        UPDATE_ACTIVITIES:
          'v4/classroom/user_activities_notifications/update_activities_notification_status',
      },
      GET_PENALTY_WIDGET_STATUS: 'v4/classroom/get_penalty_widget_status',
      DISMISS_PENALTY_WIDGET: 'v4/classroom/close_penalty_widget',

      PRODUCT_DEADLINE_MISS_SUPPORT: {
        GET_STATUS: 'v4/classroom/product_deadline_miss_support/status',
        FORM_SUBMITTED: 'v4/classroom/product_deadline_miss_support/submit',
        NOTIFICATION_DISMISSED:
          'v4/classroom/product_deadline_miss_support/dismiss',
      },
      SCHEDULED_PAUSE: {
        CHECK_PAUSE_PERIOD_VALIDITY:
          'v4/classroom/scheduled_pause_period_validity',
        TRIGGER_SCHEDULED_PAUSE: 'v4/classroom/trigger_scheduled_pause',
        CANCEL: 'v4/classroom/cancel_scheduled_pause',
      },
      STREAK_ANALYTICS_DATA: 'v4/classroom/order_streaks/streak_analytics_data',
      CAREER_CAMP: {
        USER_PERSONALIZED_SCHEDULE: 'v4/classroom/user_personalized_schedule',
        MARK_PERSONALIZED_SCHEDULE_OPENED:
          'v4/classroom/personalized_schedule_opened',
        REFERRAL_SUMMARY: 'v4/classroom/cc_referral_summary',
        REQUEST_CALLBACK: 'v4/classroom/request_callback',
        CONFIRM_WHATSAPP: 'v4/classroom/whatsapp_cc_referral_info',
      },
      ACHIEVEMENTS: {
        ACHIEVEMENT_CARD: 'v4/classroom/fetch_achievement_card',
      },
    },
    PUBLIC_SECTION: {
      FETCH_USER_CONTEST_RATING_DATA: 'v4/public_section/user_rating_data',
      BOOTCAMP: {
        COURSE_DATA: 'v4/public_section/bootcamp_course_data/',
      },
      FETCH_NOTIFICATIONS: 'v4/notifications/drawer_notifications',
      MARK_ALL_AS_READ: 'v4/notifications/mark_all_read',
      MARK_NOTIFICATION_READ: 'v4/notifications/mark_read',
      GET_UNREAD_NOTIFICATIONS_COUNT:
        'v4/notifications/unread_notifications_count',
      GET_UNREAD_EXPERIENCE_TOASTS: 'v4/notifications/unread_experience_toasts',
      FETCH_CONTEST_LIST: 'v4/public_section/contest_list',
      COMPANY_ROLES_AUTOCOMPLETE_LIST:
        'v4/public_section/company_roles/autocomplete',
      INTERESTED_USERS: {
        ADD_USER: 'v4/public_section/interested_users/add_user',
        FETCH_USERS: 'v4/public_section/interested_users/fetch_users',
      },
      SEND_OFFERING_TO_SELF: 'v4/public_section/offering_send_to_self',
      LEAGUE_LEADERBOARD: {
        CHECK_FOR_ONBOARDING:
          'v4/public_section/weekly_leaderboard/check_for_onboarding',
        MARK_ONBOARDING_COMPLETE:
          'v4/public_section/weekly_leaderboard/mark_onboarding_complete',
        ADD_USER: 'v4/public_section/weekly_leaderboard/add_user',
        REMOVE_USER: 'v4/public_section/weekly_leaderboard/remove_user',
        USER_DETAILS: 'v4/public_section/weekly_leaderboard/details',
        XP_SUMMARY: 'v4/public_section/weekly_leaderboard/xp_summary',
        FETCHED_UNSOLVED_PROBLEM:
          'v4/public_section/weekly_leaderboard/first_unsolved_problem',
      },
      FETCH_HISTORY: 'v4/public_section/experience_points_history',
      JOB_PREP_PLAN: {
        TODAY_PLAN: 'v4/public_section/job_prep_plan/today_plan',
        PREP_PLAN: 'v4/public_section/prep_plan',
      },
      JOURNEY_GOALS: {
        SET_USER_GOAL_LANGUAGE: 'v4/public_section/set_user_goal_language',
        MARK_VIDEO_COMPLETED: 'v4/public_section/mark_video_completed',
        FETCH_GOAL_DETAILS: 'v4/public_section/fetch_goal_detail',
        FETCH_USER_GOAL_STATUS: 'v4/public_section/user_goal_status',
        FETCH_USER_GOAL_PROGRESS: 'v4/public_section/user_goal_progress',
      },
      GUIDED_PATH: {
        FETCH_SIDENAV_DETAILS: 'v4/public_section/guided_path/content_details',
      },
      LOGGED_OUT_MASTERCLASS_REGISTRATION:
        'v4/public_section/logged_out_masterclass_registration',
    },
    NINJAS_IO: {
      APP_SOCKET: 'app',
      USER_SOCKET: 'users',
    },
    NINJAS_VIDEO_SESSION: {
      ROOM_URL: 'v4/room_url',
    },
    STEP_FORM: 'v4/step_forms',
    FETCH_USER_SCHOLARSHIP_REGISTRATION: 'v4/scholarship_test_reminder_banner',
    CAREER_CAMP: {
      HOMEPAGE: {
        CAREER_CAMP_PRODUCT_SERIES_LIST:
          'v4/career_camp/homepage/fetch_career_camp_product_series_list',
      },
      HANDLE_REFERRAL: 'v4/career_camp/handle_career_camp_referral',
      WEBINARS: 'v4/career_camp/webinars',
      CREATE_WEBINAR_REGISTRATION: 'v4/career_camp/register_participant',
      WEBINAR_REGISTRATION_DETAILS:
        'v4/career_camp/webinar_registration_details',
      COURSE_CATEGORIES: 'v4/career_camp/course_categories',
      SEND_LSQ_EVENT: 'v4/career_camp/send_lsq_event',
      FETCH_CAREER_CAMP_PRODUCT_SERIES: 'v4/career_camp/fetch_ccp_series_slug',
      PROFESSIONALS: {
        FETCH_MILESTONE_DATA: 'v4/career_camp/professionals/milestone_data',
        HOMEPAGE: 'v4/career_camp/professionals/homepage',
        WORK_EXP_DETAILS: 'v4/career_camp/professionals/work_exp_info/details',
        WORK_EXP_SUBMIT: 'v4/career_camp/professionals/work_exp_info/submit',
        PERSONAL_INFO_DETAILS:
          'v4/career_camp/professionals/personal_info/details',
        PERSONAL_INFO_SUBMIT:
          'v4/career_camp/professionals/personal_info/submit',
        SUBMIT_WEBINAR_FEEDBACK:
          'v4/career_camp/professionals/webinar_feedback/submit',
      },
      CC_PRO_WEBINAR_REGISTRATION: 'v4/career_camp/cc_pro_webinar_registration',
      DASHBOARD: {
        FETCH_MILESTONE_DATA: 'v4/career_camp/dashboard/milestone_data',
        FETCH_DASHBOARD_DATA: 'v4/career_camp/dashboard/',
        FETCH_TESTIMONIALS: 'v4/career_camp/dashboard/testimonials',
        WORK_EXP_DETAILS: 'v4/career_camp/dashboard/work_exp_info/details',
        WORK_EXP_SUBMIT: 'v4/career_camp/dashboard/work_exp_info/submit',
        EDUCATION_DETAILS: 'v4/career_camp/dashboard/education_info/details',
        EDUCATION_SUBMIT: 'v4/career_camp/dashboard/education_info/submit',
        PERSONAL_INFO_DETAILS: 'v4/career_camp/dashboard/personal_info/details',
        PERSONAL_INFO_SUBMIT: 'v4/career_camp/dashboard/personal_info/submit',
        SUBMIT_WEBINAR_FEEDBACK:
          'v4/career_camp/dashboard/webinar_feedback/submit',
        BOOK_ANOTHER_SESSION:
          'v4/career_camp/dashboard/create_book_another_session_mapping',
        SIGN_TERM_AND_CONDITION:
          'v4/career_camp/dashboard/sign_term_and_condition',
        FETCH_EXP_TO_CCP_SERIES_MAPPING:
          'v4/career_camp/dashboard/fetch_exp_to_ccp_series_mapping',
        CREATE_FORM_RESPONSE_RECORD:
          'v4/career_camp/dashboard/create_form_response_record',
        FETCH_SERIES_SWITCH_DATA: 'v4/career_camp/dashboard/series_switch_data',
        FETCH_BLENDED_SERIES_DATA:
          'v4/career_camp/dashboard/fetch_blended_series_data',
        PROVIDE_FREE_TRIAL_ACCESS:
          'v4/career_camp/dashboard/provide_free_trial_access',
        V2: {
          FETCH_MILESTONE_DATA: 'v4/career_camp/dashboard/v2/step_data',
        },
        FETCH_COURSE_CATEGORY:
          'v4/career_camp/dashboard/course_category_via_series_slug',
      },
    },
    DOWNLOAD_INVOICE: 'v4/orders/download_invoice',
    CALENDLY_SLOT_FOR_CC_PRO: 'v4/calendly_slot_for_cc_pro',
    FORM_RESPONSE: 'v4/form_response',
    TESTIMONIAL_SUBMISSION_STATUS:
      'v4/classroom/testimonials/submission_status',
    DISMISS_TESTIMONIALS_WIDGET_TEMPORARILY:
      'v4/classroom/testimonials/dismiss_temporarily',
    TESTIMONIAL_SHARABLE_LINKS: 'v4/classroom/testimonials/sharable_links',
    LANDING: {
      NAUKRI_COURSES_INFO: 'v4/landing/naukri_course/course_data',
      LATEST_USER_WEBINAR: 'v4/landing/latest_user_webinar',
    },
    AB_TEST: {
      AB_TESTS: 'v4/ab_tests',
    },
    USER_ACTIVITY: {
      CAPTURE_ACTIVITY: 'v4/user_activity_event/capture_activity',
    },
    NAUKRI_SMALL_COURSE_CHECKOUT: 'v4/skill_360_course/checkout',
    VALIDATE_CHECKOUT_SESSION_TOKEN:
      'v4/skill_360_course/validate_checkout_session_token',
    TERMS_AND_CONDITIONS: {
      FETCH: 'v4/terms_and_conditions',
      ACKNOWLEDGE: 'v4/terms_and_conditions/acknowledge',
    },
  },

  SANDBOX: {
    RUN_CODE_CASES: 'run_code_problem',
    CUSTOM_TEST_CASE_RUN: 'custom_test_code',
    CUSTOM_TEST_CASE_RUN_V2: 'v2/custom_test_code',
  },
  PING_URL: 'https://api-gateway.codingninjas.com/pings/ping',
  NINJASAUTH: {
    GET_ACCESS_TOKEN: 'ninjaauth/oauth/authorize',
  },
};

export const NAUKRI_URLs = {
  STAGING: {
    LOGOUT: 'https://www.naukri.com/central-login-services/v1/login',
    PAYMENT_EMAIL:
      'https://www.naukri.com/n360-services/v2/submitPayment?flowId=CN',
  },
  PRODUCTION: {
    GEN_TEMP_TOKEN:
      'https://www.naukri.com/external-oauth/v0/jobseeker/authentication-and-generate-temp-token',
    CONSENT: 'https://www.naukri.com/external-oauth/v0/jobseeker/consent',
    SET_POTD_ATTEMPT:
      'https://www.naukri.com/cloudgateway-mynaukri/naukri-content-management-services/v0/users/self/content/interact',
    REFRESH_NAUK_AT: 'https://www.naukri.com/central-login-services/v0/login',
  },
};
