import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Resource } from '@codingninjas/networking';
import { Observable } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { CommonProductDetailResponse } from '@codingninjas/shared-ui/models/common-product-detail.response';
import { CommonProductService } from '@codingninjas/shared-ui/services/common-product.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injector, PLATFORM_ID } from '@angular/core';

@Injectable()
export class CommonProductResolver implements Resolve<any> {
  req: any;

  constructor(
    private commonProductService: CommonProductService,
    private router: Router,
    @Inject(PLATFORM_ID) private _platformId: object,
    private injector: Injector
  ) {}

  public getCookie(name: string): string {
    if (isPlatformBrowser(this._platformId)) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let c of ca) {
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    if (isPlatformServer(this._platformId)) {
      const cookies = this.req.cookies;
      const cookieStore = {};
      if (!!cookies === false) {
        return null;
      }
      const cookiesArr = cookies.split('; ');
      for (const cookie of cookiesArr) {
        const index = cookie.indexOf('=');
        const cookieArr = [cookie.slice(0, index), cookie.slice(index + 1)];
        cookieStore[cookieArr[0]] = cookieArr[1];
      }
      return cookieStore[name];
    }
    return null;
  }

  parseQuery(queryString) {
    const query = {};
    const pairs = (
      queryString[0] === '?' ? queryString.substr(1) : queryString
    ).split('&');
    for (const pair_ of pairs) {
      const pair = pair_.split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Resource<CommonProductDetailResponse>> {
    if (isPlatformServer(this._platformId)) {
      this.req = this.injector.get('req');
    }

    if (!route.queryParams.redirect) {
      return null;
    }

    const productId = route.queryParams.redirect.split('?')[0].split('/').pop();
    if (
      !(
        productId.toLowerCase().startsWith('cp-') ||
        productId.toLowerCase() === 'interview-sessions'
      )
    ) {
      return null;
    }

    let quantity = '1';
    if (productId.toLowerCase() === 'interview-sessions') {
      const param_string = route.queryParams.redirect.split('?')[1];
      if (param_string !== '' && param_string !== undefined) {
        quantity = this.parseQuery(route.queryParams.redirect.split('?')[1])[
          'quantity'
        ];
      }

      if (quantity === null || quantity === undefined) {
        quantity = '1';
      }
    }

    return this.commonProductService
      .fetchCommonProduct(productId, quantity)
      .pipe(
        skip(1),
        map((resource) => {
          if (resource.isSuccessful()) {
            return resource;
          } else {
            if (resource.error && resource.error.code === 404) {
              this.router.navigate(['/fourofour'], { replaceUrl: true });
            } else if (resource.error && resource.error.code === 101) {
              return null;
            } else {
              this.router.navigate(['/500'], { replaceUrl: true });
            }
            return null;
          }
        })
      );
  }
}
