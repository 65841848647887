import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarComponent } from './toolbar.component';
import { FuseSharedModule } from '../../../../../@fuse/shared.module';
import { WeekoffLeaveApplyComponent } from './weekoff-leave-apply/weekoff-leave-apply.component';
import { MonthlyShareableModule } from '../monthly-shareable/monthly-shareable.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ToolbarComponent, WeekoffLeaveApplyComponent],
  imports: [
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MonthlyShareableModule,
    FuseSharedModule,
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
