<div class="errorpage">

  <div class="content">
    <p class="heading">UNAUTHORISED</p>
    <p class="subhead">Don't panic and watch your oxygen level</p>
    <a (click)="redirectTo()">RETURN TO SAFETY</a>

  </div>


</div>
