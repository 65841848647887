import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhatsappButtonComponent } from './whatsapp-button/whatsapp-button.component';
import { LinkedinButtonComponent } from './linkedin-button/linkedin-button.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [WhatsappButtonComponent, LinkedinButtonComponent],
  imports: [CommonModule, MatIconModule],
  exports: [WhatsappButtonComponent, LinkedinButtonComponent],
})
export class SocialButtonsModule {}
