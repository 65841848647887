import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AuthActionTypes,
  Authenticated,
  Authenticating,
  AuthenticationError,
  FBMergeState,
  Logout,
  NinjasLogin,
  SocialAuth,
  SocialRedirectLogin,
} from './auth.actions';
import { AuthState } from './auth.reducer';
import { SocialAuthService } from '../social-login/social-auth.service';
import { AuthResponse } from './';
import { SocialAuthData } from '../social-login';
import { AuthService } from '../auth.service';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Resource } from '@codingninjas/networking';
import { from } from 'rxjs';
import { fetch } from '@nrwl/angular';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {
  socialAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SocialAuth),
      fetch({
        run: (action: SocialAuth) => {
          return from(
            this.socialAuth.socialLogin(
              action.payload,
              action.origin,
              action.token
            )
          ).pipe(
            mergeMap((data: SocialAuthData) => {
              return this.authService.authenticate(data).pipe(
                map((resource: Resource<AuthResponse>) => {
                  if (resource.isSuccessful()) {
                    if (resource.data.fb_merge_state) {
                      return new FBMergeState(
                        resource.data.fb_merge_state,
                        resource.data.fb_merge_email,
                        resource.data.fb_merge_token
                      );
                    }
                    const finalAction = new Authenticated({
                      ...resource.data,
                      provider: data.provider,
                    });
                    this.authService.actionDispatched(finalAction);
                    if (
                      resource.data.eu_user &&
                      !resource.data.eu_active_user
                    ) {
                      window.location.href =
                        'https://www.codingninjas.com/coming-soon';
                    }
                    return finalAction;
                  } else if (resource.isLoading()) {
                    return new Authenticating();
                  } else if (resource.isUnsuccessful()) {
                    const finalAction = new AuthenticationError(resource.error);
                    this.authService.actionDispatched(finalAction);
                    return finalAction;
                  }
                })
              );
            })
          );
        },

        onError: (action: SocialAuth, error) => {
          return new AuthenticationError(error);
        },
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.Logout),
      withLatestFrom(this.store$),
      fetch({
        run: (action: Logout, state: AuthState) => {
          this.authService.logout(state.auth.provider);
          this.authService.actionDispatched(action);
        },
        onError: (action: Logout, error) => {},
      })
    )
  );

  localLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LocalLogout),
        fetch({
          run: (action: Logout) => {
            this.eraseCookie('ninja_auth_token');
            this.authService.deleteNaukriCookies();
          },
          onError: (action: Logout, error) => {},
        })
      ),
    { dispatch: false }
  );

  manualLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.NinjasLogin),
      fetch({
        run: (action: NinjasLogin) => {
          return this.authService.ninjasLogin(action.payload).pipe(
            map((resource: Resource<any>) => {
              if (resource.isSuccessful()) {
                const finalAction = new Authenticated({
                  ...resource.data,
                  provider: action.payload.provider,
                });
                this.authService.actionDispatched(finalAction);
                if (resource.data.eu_user && !resource.data.eu_active_user) {
                  window.location.href =
                    'https://www.codingninjas.com/coming-soon';
                }
                return finalAction;
              } else if (resource.isLoading()) {
                return new Authenticating();
              } else {
                const finalAction = new AuthenticationError(resource.error);
                this.authService.actionDispatched(finalAction);
                return finalAction;
              }
            })
          );
        },

        onError: (action: NinjasLogin, error) => {
          return new AuthenticationError(error);
        },
      })
    )
  );

  redirectLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SocialRedirectLogin),
      fetch({
        run: (action: SocialRedirectLogin) => {
          return this.authService.authenticate(action.data).pipe(
            map((resource: Resource<any>) => {
              if (resource.isSuccessful()) {
                if (resource.data.fb_merge_state) {
                  return new FBMergeState(
                    resource.data.fb_merge_state,
                    resource.data.fb_merge_email,
                    resource.data.fb_merge_token
                  );
                }
                const finalAction = new Authenticated({
                  ...resource.data,
                  provider: action.data.provider,
                });
                this.authService.actionDispatched(finalAction);
                return finalAction;
              } else if (resource.isLoading()) {
                return new Authenticating();
              } else {
                const finalAction = new AuthenticationError(resource.error);
                this.authService.actionDispatched(finalAction);
                return finalAction;
              }
            })
          );
        },

        onError: (action: SocialRedirectLogin, error) => {
          return new AuthenticationError(error);
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private socialAuth: SocialAuthService,
    private store$: Store<AuthState>
  ) {}

  private eraseCookie(name) {
    const domain = this.getDomain();
    document.cookie =
      name +
      '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
      domain +
      ';';
  }
  private getDomain() {
    let domain = window.location.hostname.split('.').slice(1).join('.');
    domain = domain === '' ? 'localhost' : '.' + domain;
    return domain;
  }
}
