import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NinjasNotificationConfig } from '@codingninjas/notification';
import {
  MyActivityNotification,
  MyActivityNotificationOptions,
} from '../my-activity-notification';
import { MyActivitiesNotificationService } from '../my-activities-notification.service';
import { MyActivityNotificationAnimation } from './my-activity-notification.animation';
import { ACTIVITY_TYPE_TO_ANALYTICS_NAME_MAP } from '../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-my-activity-notification-base',
  animations: [MyActivityNotificationAnimation],
  templateUrl: './my-activity-notification-base.component.html',
  styleUrls: ['./my-activity-notification-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyActivityNotificationBaseComponent implements OnInit, OnDestroy {
  @Input() notification: MyActivityNotification;
  @Input() index: number;
  showCloseIcon = false;
  config: NinjasNotificationConfig = {};
  noOfTimesCallbackExecute = 1;

  protected _options: MyActivityNotificationOptions;
  private _autoErase: boolean;
  private _eraseTimer: number = null;
  private _eraseTimingStart: number;
  private _eraseTTL: number;
  onClickCallbackPresent = false;

  constructor(
    private myActivityNotificationService: MyActivitiesNotificationService
  ) {}

  ngOnInit(): void {
    this.configureNotification();
    this.markNotificationDelivered([this.notification.notification.id]);
    this.triggerShowAnalytics();
  }

  triggerShowAnalytics() {
    const eventName = 'Classroom - Notification Shown';
    const additionalData = {
      notificationType:
        ACTIVITY_TYPE_TO_ANALYTICS_NAME_MAP[
          this.notification.notification.activity_type
        ],
    };
    this.myActivityNotificationService.sendAnalyticsEvent({
      eventName,
      additionalData,
      baseData: {},
    });
  }

  markNotificationDelivered(notificationIds) {
    this.myActivityNotificationService
      .markAsDelivered(notificationIds)
      .subscribe((_value) => {});
  }

  configureNotification() {
    this._options = this.notification.options;

    if (this._options.animate) {
      this.notification.state = 'enter';
    }

    this._autoErase = this._options.duration > 0;

    if (this._autoErase) {
      this._initErase();
      this._startEraseTimeout();
    }

    if (this._options.audio) {
      this.playAudio();
    }
  }

  playAudio() {
    const audio = new Audio(this._options.audio);
    audio.volume = 0.3;
    audio.autoplay = true;
    audio.play().catch();
    audio.remove();
  }

  onEnter(): void {
    this.showCloseIcon = true;

    if (this._autoErase && this._options.pauseOnHover) {
      this._clearEraseTimeout();
      this._updateTTL();
    }
  }

  onLeave(): void {
    this.showCloseIcon = false;

    if (this._autoErase && this._options.pauseOnHover) {
      this._startEraseTimeout();
    }
  }

  // Remove self
  protected _destroy(): void {
    if (this._options.animate) {
      this.notification.state = 'leave';
      setTimeout(
        () =>
          this.myActivityNotificationService.removeNotification(
            this.notification.notificationId
          ),
        200
      );
    } else {
      this.myActivityNotificationService.removeNotification(
        this.notification.notificationId
      );
    }
  }

  triggerDismissAnalytics(type) {
    const eventName = 'Classroom - Notification Dismissed';
    const additionalData = {
      notificationType:
        ACTIVITY_TYPE_TO_ANALYTICS_NAME_MAP[
          this.notification.notification.activity_type
        ],
      type,
    };
    this.myActivityNotificationService.sendAnalyticsEvent({
      eventName,
      additionalData,
      baseData: {},
    });
  }

  private _initErase(): void {
    this._eraseTTL = this._options.duration;
    this._eraseTimingStart = Date.now();
  }

  private _updateTTL(): void {
    if (this._autoErase) {
      this._eraseTTL -= Date.now() - this._eraseTimingStart;
    }
  }

  private _startEraseTimeout(): void {
    if (this._eraseTTL > 0) {
      this._clearEraseTimeout();
      this._eraseTimer = window.setTimeout(() => {
        this._destroy();
        this.triggerDismissAnalytics('Auto Dismiss');
      }, this._eraseTTL);
      this._eraseTimingStart = Date.now();
    } else {
      this._destroy();
    }
  }

  private _clearEraseTimeout(): void {
    if (this._eraseTimer !== null) {
      window.clearTimeout(this._eraseTimer);
      this._eraseTimer = null;
    }
  }

  close(executeAfterCloseCallback): void {
    this._destroy();
    this.triggerDismissAnalytics('Manual Dismiss');
    if (executeAfterCloseCallback) {
      const callbackEvent = this.notification.afterClosedCallback;
      if (callbackEvent) {
        callbackEvent.call(this);
      }
    }
  }

  get state(): string {
    if (this.notification.state === 'enter') {
      if (
        this.config.placement === 'topLeft' ||
        this.config.placement === 'bottomLeft'
      ) {
        return 'enterLeft';
      } else {
        return 'enterRight';
      }
    } else {
      return this.notification.state;
    }
  }

  ngOnDestroy(): void {
    if (this._autoErase) {
      this._clearEraseTimeout();
    }
  }
}
