import { createReducer, on } from '@ngrx/store';
import { setChangeUserNameNudge } from './user-actions.actions';

export const userActionFeatureKey = 'userActions';

export interface UserActionsState {
  change_username_nudge: boolean;
}

export const initialState: UserActionsState = {
  change_username_nudge: false,
};

export const userActionReducer = createReducer(
  initialState,
  on(setChangeUserNameNudge, (state) => ({
    ...state,
    change_username_nudge: true,
  }))
);
