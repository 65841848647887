import { Inject, Injectable, Optional, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ToastNinjasNotification,
  ToastNotificationOptions,
  ToastNotificationType,
} from './toast-ninjas-notification';
import {
  TOAST_NOTIFICATION_CONFIG,
  TOAST_NOTIFICATION_DEFAULT_CONFIG,
  ToastNotificationConfig,
} from './toast-ninjas-notification.config';

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  private _actionsSourceToast: BehaviorSubject<ToastNotificationActions> = new BehaviorSubject<ToastNotificationActions>(
    null
  );
  $toastNotificationActions: Observable<ToastNotificationActions> = this._actionsSourceToast.asObservable();

  config: ToastNotificationConfig = {};

  constructor(
    @Optional()
    @Inject(TOAST_NOTIFICATION_DEFAULT_CONFIG)
    defaultConfig: ToastNotificationConfig,
    @Optional()
    @Inject(TOAST_NOTIFICATION_CONFIG)
    config: ToastNotificationConfig
  ) {
    this.setConfig({ ...defaultConfig, ...config });
  }

  private setConfig(config: ToastNotificationConfig): void {
    this.config = { ...this.config, ...config };
  }

  getConfig(): ToastNotificationConfig {
    return this.config;
  }

  success(
    title: string,
    options?: ToastNinjasNotification
  ): ToastNinjasNotification {
    return this.create('success', title, options);
  }

  create(
    type: ToastNotificationType,
    title: string,
    options?: ToastNotificationOptions
  ): ToastNinjasNotification {
    const noti = new ToastNinjasNotification(title, type).setOptions(options);
    this._actionsSourceToast.next(new Add(noti));
    return noti;
  }
  template(template: TemplateRef<{}>): ToastNinjasNotification {
    const noti = new ToastNinjasNotification('template').setTemplate(template);
    this._actionsSourceToast.next(new Add(noti));
    return noti;
  }
  removeNotification(notificationId: string) {
    this._actionsSourceToast.next(new Remove(notificationId));
  }
}

export const ADD_TOAST_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_TOAST_NOTIFICATION = 'REMOVE_NOTIFICATION';

export class Add {
  type = ADD_TOAST_NOTIFICATION;
  constructor(public payload: ToastNinjasNotification) {}
}

export class Remove {
  type = REMOVE_TOAST_NOTIFICATION;
  constructor(public payload: string) {}
}

export type ToastNotificationActions = Add | Remove;
