import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { OvertimeSlotResponse } from '../../../../dashboard/models/responses/overtime-slot.response';
import { DatePipe } from '@angular/common';
import { DashboardService } from '../../../../dashboard/dashboard.service';
import { NinjasNotificationService } from '@codingninjas/notification';
import { OvertimeSlotsConfirmationComponent } from './overtime-slots-confirmation/overtime-slots-confirmation.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'mentor-overtime-slots-modal',
  templateUrl: './overtime-slots-modal.component.html',
  styleUrls: ['./overtime-slots-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeSlotsModalComponent implements OnInit {
  displayedColumns: string[] = [
    'slot_date',
    'start_time',
    'end_time',
    'action',
  ];
  overtimeSlotData: OvertimeSlotResponse[] = [];
  selectedOvertimeSlotData: OvertimeSlotResponse[] = [];
  DAYS_LIMIT = 4;
  availableDates = [];
  slotBookLoading = false;
  bookingSlotId: number;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<OvertimeSlotsModalComponent>,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private notificationService: NinjasNotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.setAvailableDates();
    this.setOvertimeSlots();
  }

  setOvertimeSlots() {
    this.loading = true;
    this.cdr.markForCheck();
    const fromDate = new Date().toISOString().split('T')[0];
    const toDate = new Date();
    toDate.setDate(new Date().getDate() + this.DAYS_LIMIT);
    const formattedToDate = toDate.toISOString().split('T')[0];
    this.dashboardService
      .fetchOvertimeSlots(fromDate, formattedToDate)
      .subscribe((resource) => {
        if (resource.isSuccessful()) {
          this.loading = false;
          this.overtimeSlotData = resource.data.overtime_slots;
          this.selectedOvertimeSlotData = this.overtimeSlotData;
          this.cdr.markForCheck();
        } else if (resource.isUnsuccessful()) {
          this.notificationService.error(
            'Something went wrong',
            resource.message
          );
        }
      });
  }

  setAvailableDates() {
    const today = new Date();
    this.availableDates = [];

    for (let i = 0; i < this.DAYS_LIMIT; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      let label = '';
      if (i === 0) {
        label = 'Today, ';
      }
      label += new DatePipe('en-US').transform(nextDate, 'd MMMM, yyyy') || '';
      // Format the value as 'YYYY-MM-DD'
      const year = nextDate.getFullYear();
      const month = String(nextDate.getMonth() + 1).padStart(2, '0');
      const day = String(nextDate.getDate()).padStart(2, '0');
      const value = `${year}-${month}-${day}`;
      this.availableDates.push({ value, label });
    }
  }

  dateSelected(event) {
    const selectedDate = event.value;
    this.selectedOvertimeSlotData = this.overtimeSlotData.filter((slot) => {
      return slot.date === selectedDate;
    });
    this.cdr.markForCheck();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openConfirmationDialog(slotId: number) {
    const config = new MatDialogConfig();
    config.panelClass = 'overtime-slot-modal';
    this.dialog
      .open(OvertimeSlotsConfirmationComponent, config)
      .afterClosed()
      .subscribe((result) => {
        if (result.bookSlot) {
          this.bookOvertimeSlot(slotId);
        }
      });
  }

  bookOvertimeSlot(slotId: number) {
    this.bookingSlotId = slotId;
    this.slotBookLoading = true;
    this.cdr.markForCheck();
    this.dashboardService.bookOvertimeSlot(slotId).subscribe((resource) => {
      if (resource.isSuccessful()) {
        this.slotBookLoading = false;
        this.cdr.markForCheck();
      } else if (resource.isUnsuccessful()) {
        this.notificationService.error(
          'Something went wrong',
          resource.message
        );
      }
    });
  }
}
