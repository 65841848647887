import { Observable, of } from 'rxjs';
import { NetworkingError } from './networking.error';
import { catchError, map, startWith } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiClient } from './api-client';
import { Call } from './call';
import { Resource } from './resource';
import { Injectable } from '@angular/core';

export interface IResourceFactory {
  createResource<T>(apiClient: ApiClient, call: Call): Observable<Resource<T>>;
}

@Injectable({
  providedIn: 'root',
})
export class ResourceFactory implements IResourceFactory {
  createResource<T>(apiClient: ApiClient, call: Call): Observable<Resource<T>> {
    const request: Observable<T> = apiClient.request<T>(call);
    if (request != null) {
      return request.pipe(
        startWith(null),
        map((response: T, index) => {
          if (index === 0) {
            return Resource.loading<T>();
          } else if (response != null) {
            return Resource.success<T>(response);
          } else {
            return Resource.error<T>(NetworkingError.INTERNAL_SERVER_ERROR);
          }
        }),
        catchError((err) => {
          const defaultError = NetworkingError.INTERNAL_SERVER_ERROR;
          if (err != null) {
            if (err instanceof NetworkingError) {
              return of(Resource.error<T>(err));
            } else if (err instanceof HttpErrorResponse) {
              if (err.error instanceof ErrorEvent) {
                // A client-side or network error occurred. Handle it accordingly.
                return of(Resource.error<T>(NetworkingError.NETWORK_ERROR));
              } else {
                // The backend returned an unsuccessful response code.
                // The response body may contain clues as to what went wrong,
                const backendError = new NetworkingError(
                  err.status,
                  err.message
                );
                return of(Resource.error<T>(backendError));
              }
            }
          }
          return of(Resource.error<T>(defaultError));
        })
      );
    }
    return null;
  }
}
