<div
  *ngIf="showActionables"
  [@expandReportNotification]="expandNotificationState"
  class="ninjas-notification-report-confirmation"
>
  <div class="report-confirmation-heading zen-body-2 py-12 px-24">
    Do you want to block these notifications forever?
  </div>

  <div
    class="report-confirmation-actions flex-row align-items-center justify-content-space-between py-12 px-24"
  >
    <button
      zen-secondary-button
      class="report-action-button"
      [size]="'small'"
      (click)="collapseActionable()"
    >
      No
    </button>

    <button
      zen-primary-button
      class="report-action-button"
      [size]="'small'"
      (click)="notificationActionConfirmed()"
    >
      Yes
    </button>
  </div>
</div>
