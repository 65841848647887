<div class="login-dialog-container">
  <div id="login-header">
    <div class="heading" style="text-align: center; width: 100%">
      Login / Sign Up
    </div>
    <div class="close" (click)="close()"></div>
  </div>

  <auth-login-signup
    [loginForm]="data && data.loginFormUserStatus"
    [inputEmail]="data && data.userEmail"
    [inputName]="data && data.userName"
    [loginType]="data && data.loginType"
  >
  </auth-login-signup>
</div>
