import { Component, Input, OnInit } from '@angular/core';
import { TaLeaveFormComponent } from '../ta-leave-form/ta-leave-form.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mentor-ta-leave-response',
  templateUrl: './ta-leave-response.component.html',
  styleUrls: ['./ta-leave-response.component.css'],
})
export class TaLeaveResponseComponent implements OnInit {
  @Input() requestLeaveResult: any;

  constructor(public dialogRef: MatDialogRef<TaLeaveFormComponent>) {}

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }
}
