import { MentorUser } from '../models/mentor-user';
import { MentorActions, MentorActionTypes } from '../actions/mentor.actions';

export interface MentorState {
  readonly mentor: MentorData;
}

export type AvailabilityStatusType = 'offline' | 'online' | 'away';

export interface MentorAvailabilityStatus {
  status: AvailabilityStatusType;
  auto: boolean;
}

export interface MentorData {
  mentorUser: MentorUser;
  availabilityStatus: MentorAvailabilityStatus;
  doubtActivityStatus: boolean;
}

export const statusInitialState: MentorAvailabilityStatus = {
  status: 'offline',
  auto: false,
};

export const mentorInitialState: MentorData = {
  mentorUser: null,
  availabilityStatus: statusInitialState,
  doubtActivityStatus: false,
};

export function mentorReducer(
  state = mentorInitialState,
  action: MentorActions
): MentorData {
  switch (action.type) {
    case MentorActionTypes.MENTOR_USER_FETCHED:
      return {
        doubtActivityStatus: false,
        availabilityStatus: { status: action.mentorUser.status, auto: false },
        mentorUser: action.mentorUser,
      };

    case MentorActionTypes.UPDATE_ONLINE_STATUS:
      return {
        ...state,
        availabilityStatus: { status: action.status, auto: false },
      };

    default:
      return state;
  }
}
