import {
  Input,
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CustomNinjasNotification } from '../custom-ninja-notification';
import {
  NinjasNotificationConfig,
  NinjasNotificationOptions,
} from '@codingninjas/notification';
import { CustomNinjasNotificationService } from '../custom-ninjas-notification.service';
import {
  CustomNotificationAnimation,
  FadeInFlag,
} from './custom-notification.animation';

@Component({
  selector: 'codingninjas-custom-notification',
  animations: [CustomNotificationAnimation, FadeInFlag],
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomNotificationComponent implements OnInit, OnDestroy {
  @Input() notification: CustomNinjasNotification;
  @Input() index: number;
  showReportFlag = false;
  showNotificationActions = false;
  config: NinjasNotificationConfig = {};
  noOfTimesCallbackExecute = 1;

  protected _options: NinjasNotificationOptions; // Shortcut reference to nzMessage.options
  // For auto erasing(destroy) self
  private _autoErase: boolean; // Whether record timeout to auto destroy self
  private _eraseTimer: number = null;
  private _eraseTimingStart: number;
  private _eraseTTL: number; // Time to live
  onClickCallbackPresent = false;

  constructor(
    private customNinjasNotificationService: CustomNinjasNotificationService
  ) {}

  ngOnInit(): void {
    this.configureNotification();
  }

  configureNotification() {
    this._options = this.notification.options;

    if (this._options.animate) {
      this.notification.state = 'enter';
    }

    this._autoErase = this._options.duration > 0;

    if (this._autoErase) {
      this._initErase();
      this._startEraseTimeout();
    }

    if (this._options.audio) {
      this.playAudio();
    }

    if (this._options.onClickCallback) {
      this.onClickCallbackPresent = true;
    }
  }

  playAudio() {
    const audio = new Audio(this._options.audio);
    audio.volume = 0.3;
    audio.play().catch();
    audio.remove();
  }

  onEnter(): void {
    this.showReportFlag = true;

    if (this._autoErase && this._options.pauseOnHover) {
      this._clearEraseTimeout();
      this._updateTTL();
    }
  }

  onLeave(): void {
    this.showReportFlag = false;

    if (this._autoErase && this._options.pauseOnHover) {
      this._startEraseTimeout();
    }
  }

  showActionable() {
    if (
      this.showNotificationActions === false &&
      this.noOfTimesCallbackExecute === 1
    ) {
      const callbackEvent = this.notification.onActionableIntentCallback;
      if (callbackEvent) {
        callbackEvent?.call(this);
        this.noOfTimesCallbackExecute += 1;
      }
    }
    this.showNotificationActions = !this.showNotificationActions;
  }

  // Remove self
  protected _destroy(): void {
    if (this._options.animate) {
      this.notification.state = 'leave';
      setTimeout(
        () =>
          this.customNinjasNotificationService.removeNotification(
            this.notification.notificationId
          ),
        200
      );
    } else {
      this.customNinjasNotificationService.removeNotification(
        this.notification.notificationId
      );
    }
  }

  private _initErase(): void {
    this._eraseTTL = this._options.duration;
    this._eraseTimingStart = Date.now();
  }

  private _updateTTL(): void {
    if (this._autoErase) {
      this._eraseTTL -= Date.now() - this._eraseTimingStart;
    }
  }

  private _startEraseTimeout(): void {
    if (this._eraseTTL > 0) {
      this._clearEraseTimeout(); // To prevent calling _startEraseTimeout() more times to create more timer
      this._eraseTimer = window.setTimeout(
        () => this._destroy(),
        this._eraseTTL
      );
      this._eraseTimingStart = Date.now();
    } else {
      this._destroy();
    }
  }

  private _clearEraseTimeout(): void {
    if (this._eraseTimer !== null) {
      window.clearTimeout(this._eraseTimer);
      this._eraseTimer = null;
    }
  }

  close(executeAfterCloseCallback): void {
    this._destroy();
    if (executeAfterCloseCallback) {
      const callbackEvent = this.notification.afterClosedCallback;
      if (callbackEvent) {
        callbackEvent.call(this);
      }
    }
  }

  get state(): string {
    if (this.notification.state === 'enter') {
      if (
        this.config.placement === 'topLeft' ||
        this.config.placement === 'bottomLeft'
      ) {
        return 'enterLeft';
      } else {
        return 'enterRight';
      }
    } else {
      return this.notification.state;
    }
  }

  get fadeInFlag(): string {
    if (this.showReportFlag) {
      return 'fadeIn';
    }
    return 'fadeOut';
  }

  ngOnDestroy(): void {
    if (this._autoErase) {
      this._clearEraseTimeout();
    }
  }
}
