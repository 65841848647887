import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  NotificationTheme,
  WebinarActivityNotification,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-webinar-registration-activity',
  templateUrl: './webinar-registration-activity.component.html',
  styleUrls: ['./webinar-registration-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebinarRegistrationActivityComponent implements OnInit {
  @Input() notification: WebinarActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;

  _actionPerformed = false;
  title: string;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.title = 'Upcoming webinar';
    if (this.theme === 'dark') {
      this.title = this.notification.content.title;
      this.notification.content.icon_context += '-fill';
    }
  }

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }
}
