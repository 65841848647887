import { Injectable } from '@angular/core';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { Observable } from 'rxjs';
import { CommonProductDetailResponse } from '../models/common-product-detail.response';
@Injectable({
  providedIn: 'root',
})
export class CommonProductService {
  constructor(private apiClient: ApiClient) {}

  fetchCommonProduct(
    id: string,
    quantity: string = null
  ): Observable<Resource<CommonProductDetailResponse>> {
    const call: Call = new CallBuilder(CallMethod.Get, URLS.V3.PRODUCT_COMMON)
      .setParam('orderable_key', id)
      .setParam('quantity', quantity)
      .build();
    return this.apiClient.enqueue<CommonProductDetailResponse>(call);
  }
}
