<ng-container *ngIf="theme === 'dark'; else lightThemeView">
  <div class="activity-container">
    <div [class]="theme" class="flex-row gap-8">
      <div class="left">
        <codingninjas-my-activity-icon
          [iconType]="notification.content.icon_type"
          [iconContext]="notification.content.icon_context"
        ></codingninjas-my-activity-icon>
      </div>
      <div class="right flex-column gap-12">
        <div class="flex-column gap-4">
          <div
            class="notification-header ellipsis flex-row justify-content-space-between align-items-center"
          >
            <span class="zen-typo-subtitle-small">{{
              notification.content.title
            }}</span>
            <mat-icon
              style="width: 12px; height: 12px; font-size: 12px"
              class="separator-icon"
              fontSet="zen-icon"
              fontIcon="icon-circle-seprator"
            ></mat-icon>
            <span class="zen-typo-body-small">
              {{ toSentenceCase(notification.content.interview_topic) }}
            </span>
          </div>

          <div class="webinar-info zen-typo-caption-medium">
            {{ notification.content.end_time_key_name }}
            {{
              notification.content.session_start_time
                | date : 'shortTime'
                | lowercase
            }}
          </div>
        </div>

        <div>
          <codingninjas-attend-interview-session-action
            [theme]="theme"
            [notification]="notification"
            (ctaClicked)="actionPerformed()"
            [source]="source"
          ></codingninjas-attend-interview-session-action>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #lightThemeView>
  <div class="activity-container">
    <div [class]="theme" class="flex-row gap-8">
      <div class="left">
        <codingninjas-my-activity-icon
          [iconType]="notification.content.icon_type"
          [iconContext]="notification.content.icon_context"
        ></codingninjas-my-activity-icon>
      </div>
      <div class="right flex-column gap-12">
        <div class="flex-column gap-4">
          <div
            class="notification-header flex-row justify-content-space-between align-items-center"
          >
            <span class="zen-typo-subtitle-small"
              >Upcoming {{ notification.content.title }}</span
            >

            <codingninjas-my-activity-header-info
              *ngIf="theme === 'light'"
              [notification]="notification"
              [actionPerformed]="_actionPerformed"
            ></codingninjas-my-activity-header-info>
          </div>

          <div class="interview-topic ellipsis pt-4 zen-typo-caption-medium">
            {{ notification.content.description }}
            <span class="zen-typo-caption-bold">
              {{ this.notification.content.interview_topic | lowercase }}
              at
              {{
                this.notification.content.session_start_time
                  | date : 'shortTime'
                  | lowercase
              }}
            </span>
          </div>
        </div>

        <div>
          <codingninjas-attend-interview-session-action
            [theme]="theme"
            [notification]="notification"
            (ctaClicked)="actionPerformed()"
            [source]="source"
          ></codingninjas-attend-interview-session-action>
        </div>
      </div>
    </div>
  </div>
</ng-template>
