<div class="activity-container">
  <div [class]="theme" class="flex-row gap-8">
    <div class="left">
      <codingninjas-my-activity-icon
        [iconType]="notification.content.icon_type"
        [iconContext]="notification.content.icon_context"
      ></codingninjas-my-activity-icon>
    </div>
    <div class="right flex-column gap-8 flex-1">
      <div class="flex-column gap-4">
        <div class="flex-row justify-content-space-between">
          <div
            class="notification-header flex-row justify-content-space-between align-items-center"
          >
            <span class="zen-typo-subtitle-small" style="margin-right: 4px">
              {{ title }}</span
            >
            <ng-container *ngIf="theme === 'dark'">
              <mat-icon
                style="width: 8px; height: 8px; font-size: 8px"
                class="separator-icon"
                fontSet="zen-icon"
                fontIcon="icon-circle-seprator"
              ></mat-icon>
              <span class="ellipsis zen-typo-body-small">
                {{ notification.content.event_name }}
              </span>
            </ng-container>
          </div>

          <div>
            <codingninjas-my-activity-header-info
              *ngIf="theme === 'light'"
              [notification]="notification"
              [actionPerformed]="_actionPerformed"
            ></codingninjas-my-activity-header-info>
          </div>
        </div>

        <div
          *ngIf="theme === 'light'"
          class="webinar-info zen-typo-caption-medium"
        >
          "{{ notification.content.event_name }}" on
          <span class="zen-typo-caption-bold">
            {{ notification.content.event_start_time | date : 'd MMM y' }} at
            {{
              notification.content.event_start_time
                | date : 'shortTime'
                | lowercase
            }}
          </span>
        </div>

        <div
          *ngIf="theme === 'dark'"
          class="webinar-info zen-typo-caption-medium"
        >
          <span class="zen-typo-caption-bold">
            {{ notification.content.event_start_time | date : 'd MMMM' }} at
            {{
              notification.content.event_start_time
                | date : 'shortTime'
                | lowercase
            }}
          </span>
        </div>
      </div>

      <div>
        <codingninjas-webinar-registration-actions
          [theme]="theme"
          [notification]="notification"
          (ctaClicked)="actionPerformed()"
          [source]="source"
        ></codingninjas-webinar-registration-actions>
      </div>
    </div>
  </div>
</div>
