export const UNREAD_NOTIFICATION = {
  v: '5.5.8',
  fr: 30,
  ip: 0,
  op: 60,
  w: 30,
  h: 30,
  nm: 'dot notification 2',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'palette 01',
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [15, 15, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [11.25, 11.25],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 11.25,
                ix: 4,
              },
              nm: 'Trazado de rectángulo 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.8980392156862745, 0.2784313725490196, 0.3137254901960784, 1,
                ],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transformar',
            },
          ],
          nm: 'palette 01',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'palette 02',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 0,
              s: [5],
            },
            {
              i: {
                x: [0.667],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0.167],
              },
              t: 17.5,
              s: [35],
            },
            {
              t: 35,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [15, 15, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0, 0, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.667, 0.667, 0.667],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0.167, 0.167, 0],
              },
              t: 0,
              s: [60, 60, 100],
            },
            {
              t: 35,
              s: [150, 150, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {
                a: 0,
                k: [18, 18],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 18,
                ix: 4,
              },
              nm: 'Trazado de rectángulo 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.8980392156862745, 0.2784313725490196, 0.3137254901960784, 1,
                ],
                ix: 4,
              },
              o: {
                a: 0,
                k: 100,
                ix: 5,
              },
              r: 1,
              bm: 0,
              nm: 'Relleno 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {
                a: 0,
                k: [0, 0],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: 'Transformar',
            },
          ],
          nm: 'palette 02',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 60,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
