import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { PeriodPipe, SafePipe } from './pipes';
import { InfiniteScrollDirective } from './directives';
import { CacheInterceptor, NetworkingModule } from '@codingninjas/networking';
import { AuthInterceptor, AuthModule } from '@codingninjas/auth';
import { LoggerModule } from 'logger';

export const PIPES = [PeriodPipe, SafePipe];
export const DIRECTIVES = [InfiniteScrollDirective];

@NgModule({
  imports: [
    CommonModule,
    LoggerModule,
    NetworkingModule,
    AuthModule,
    RouterModule,
  ],
  declarations: [...PIPES, ...DIRECTIVES],
  exports: [...PIPES, ...DIRECTIVES],
})
export class NinjasUtilsModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<NinjasUtilsModule> {
    return {
      ngModule: NinjasUtilsModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CacheInterceptor,
          multi: true,
        },
      ],
    };
  }
}
