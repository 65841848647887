import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { FuseConfigService } from '../@fuse/services/config.service';
import { FuseSidebarService } from '../@fuse/components/sidebar/sidebar.service';
import { FuseNavigationService } from '../@fuse/components/navigation/navigation.service';
import { FuseSplashScreenService } from '../@fuse/services/splash-screen.service';
import { navigation } from './core/navigation';
import {
  AuthAnalyticsService,
  UtmTrackingService,
} from '@codingninjas/ninjas-utils';
import { Store } from '@ngrx/store';
import { AuthData, AuthState, AuthService, Logout } from '@codingninjas/auth';
import { environment } from '../environments/environment';
import { isNullOrUndefined } from '@codingninjas/ninjas-utils';
import { CoreService } from './core/core.service';
import { Router } from '@angular/router';
import { DetectPhoneNumberService } from '@codingninjas/ninjas-utils/services/detect-phone-number.service';

@Component({
  selector: 'mentor-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  static LOGOUT_NODE_EVENT = 'LogoutUser';
  fuseConfig: any;
  navigation: any;

  _isLoggedIn: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _platform: Platform,
    private authAnalyticsService: AuthAnalyticsService,
    private authService: AuthService,
    private store: Store<AuthState>,
    private utmTrackingService: UtmTrackingService,
    private coreService: CoreService,
    private router: Router,
    private detectPhoneNumberService: DetectPhoneNumberService
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.store.select('auth').subscribe((authData: AuthData) => {
      this._isLoggedIn = authData.isAuthenticated;
      if (authData && authData.user) {
        this.coreService.setUserSocket(authData.user?.node_access_token);
      }

      if (this._isLoggedIn) {
        const socket = this.coreService.getUserSocket();

        if (socket) {
          socket.listen(AppComponent.LOGOUT_NODE_EVENT).subscribe((data) => {
            if (authData.user_access_token === data.query.user_access_token) {
              let redirect = this.router.routerState.snapshot.url;
              if (isNullOrUndefined(redirect)) {
                redirect = 'dashboard';
              }
              this.store.dispatch(new Logout());
              this.router.navigate(['auth'], {
                queryParams: { redirect, logged_out: true },
              });
            }
          });
        }
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;

        // Boxed
        if (this.fuseConfig.layout.width === 'boxed') {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        for (const className of this.document.body.classList) {
          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });

    this.utmTrackingService.captureUtmParameters(environment.domainRoot);
    this.detectPhoneNumberService.detectPhoneNumberOnRouteChange();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
