import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mentor-overtime-slots-confirmation',
  templateUrl: './overtime-slots-confirmation.component.html',
  styleUrls: ['./overtime-slots-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeSlotsConfirmationComponent {
  constructor(public dialogRef: MatDialogRef<any>) {}

  closeDialog(bookSlot: boolean): void {
    this.dialogRef.close({ bookSlot });
  }
}
