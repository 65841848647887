import { fromEvent, merge, of, Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-connection',
  templateUrl: './no-connection.component.html',
  styleUrls: ['./no-connection.component.css'],
})
export class NoConnectionComponent implements OnInit {
  isConnected = true;

  sub: Subscription;

  constructor() {}

  ngOnInit() {
    this.sub = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
      fromEvent(window, 'offline').pipe(map(() => false))
    ).subscribe((status) => {
      this.isConnected = status;
    });
  }

  reload() {
    location.reload();
  }
}
