import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '../../client/api-client';

@Component({
  selector: 'networking-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  showProgress$: Observable<boolean>;

  constructor(private apiClient: ApiClient) { }

  ngOnInit() {
    this.showProgress$ = this.apiClient.callExecutionInProgress$;
  }

}
