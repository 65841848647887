import { Action } from '@ngrx/store';
import { MentorUser } from '../models/mentor-user';
import { AvailabilityStatusType } from '../reducers/mentor.reducer';

export enum MentorActionTypes {
  FETCH_MENTOR_USER = '[Mentor] FetchMentorUser',
  MENTOR_USER_FETCHED = '[Mentor] MentorUserFetched',
  UPDATE_ONLINE_STATUS = '[Mentor] UpdateOnlineStatus',
  ONLINE_STATUS_UPDATED = '[Mentor] OnlineStatusUpdated',
  UPDATE_DOUBT_ACTIVITY_STATUS = '[Mentor] UpdateDoubtActivityStatus',
}

export class FetchMentorUser implements Action {
  readonly type = MentorActionTypes.FETCH_MENTOR_USER;
  constructor() {}
}
export class MentorUserFetched implements Action {
  readonly type = MentorActionTypes.MENTOR_USER_FETCHED;
  constructor(public mentorUser: MentorUser) {}
}

export class UpdateOnlineStatus implements Action {
  readonly type = MentorActionTypes.UPDATE_ONLINE_STATUS;
  constructor(public status: AvailabilityStatusType) {}
}

export class UpdateDoubtActivityStatus implements Action {
  readonly type = MentorActionTypes.UPDATE_DOUBT_ACTIVITY_STATUS;
  constructor(public status: boolean) {}
}

export class OnlineStatusUpdated implements Action {
  readonly type = MentorActionTypes.ONLINE_STATUS_UPDATED;
  constructor(public status: AvailabilityStatusType) {}
}

export type MentorActions =
  | FetchMentorUser
  | MentorUserFetched
  | UpdateOnlineStatus
  | OnlineStatusUpdated
  | UpdateDoubtActivityStatus;
