<div
  class="ninjas-notification-notice ninjas-notification-notice-closable toast-container"
  [ngStyle]="notification.options.style"
  [ngClass]="notification.options.class"
  [@enterLeave]="state"
>
  <div
    *ngIf="!notification.template"
    class="ninjas-notification-notice-content"
    [ngClass]="{ 'clickable-container': onClickCallbackPresent }"
  >
    <div
      class="ninjas-notification-notice-content"
      [ngClass]="{
        'ninjas-notification-notice-with-icon mt-8':
          notification.type !== 'blank'
      }"
    >
      <div
        [class.ninjas-notification-notice-with-icon]="
          notification.type !== 'blank'
        "
      >
        <ng-container [ngSwitch]="notification.type">
          <span *ngSwitchCase="'success'"
            ><mat-icon svgIcon="success"></mat-icon
          ></span>
        </ng-container>
        <div
          class="ninjas-notification-notice-message toast-notification-message align-title"
          [innerHTML]="notification.title"
        ></div>
      </div>
    </div>
  </div>
  <ng-template
    [ngIf]="notification.template"
    [ngTemplateOutlet]="notification.template"
    [ngTemplateOutletContext]="{ $implicit: this }"
  ></ng-template>
</div>
