<div
  class="popover-box {{ _popoverClass }} {{ _animationClass }} popover-{{
    _position
  }}"
  [ngClass]="{
    'caret-popover': _caret && !_rounded_caret,
    'caret-popover-rounded': _caret && _rounded_caret
  }"
  [style.color]="_color"
>
  <ng-template cdkPortalOutlet></ng-template>
</div>
