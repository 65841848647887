import { ResourceStatus } from './resource-status';
import { NetworkingError } from './networking.error';

export class Resource<T> {
  private constructor(
    private status: ResourceStatus,
    public data?: T,
    public error?: NetworkingError
  ) {}

  public static success<T>(data: T): Resource<T> {
    return new Resource<T>(ResourceStatus.SUCCESS, data, null);
  }

  public static loading<T>(): Resource<T> {
    return new Resource<T>(ResourceStatus.LOADING, null, null);
  }

  public static error<T>(error: NetworkingError): Resource<T> {
    return new Resource<T>(ResourceStatus.ERROR, null, error);
  }

  public isSuccessful(): boolean {
    return this.status === ResourceStatus.SUCCESS;
  }

  public isLoading(): boolean {
    return this.status === ResourceStatus.LOADING;
  }

  public isUnsuccessful(): boolean {
    return this.status === ResourceStatus.ERROR;
  }
}
