import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiClientOptions } from './client/api-client-options';
import { CacheInterceptor } from './client/cache.interceptor';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
const SHARED_COMPONENTS = [
  ProgressBarComponent
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: SHARED_COMPONENTS,
  declarations: SHARED_COMPONENTS
})
export class NetworkingModule {

  public static forRoot(options: ApiClientOptions): ModuleWithProviders<NetworkingModule> {
    return {
      ngModule: NetworkingModule,
      providers: [
        {
          provide: 'options', // you can also use InjectionToken
          useValue: options
        }
      ]
    };
  }
}
