<div
  class="login-states"
  [ngClass]="{ 'auth-before-checkout-mod': showOptInCheckBox !== null }"
>
  <div class="message" *ngIf="loginData.loginType === 'email'">
    One Time Password has been sent to your email, <b>{{ loginData.email }}</b
    >, please enter the same here to login. Valid for 10 minutes.
  </div>
  <div class="message" *ngIf="loginData.loginType === 'phone'">
    One Time Password has been sent to your phone number,
    <b>{{ loginData.phoneNumber }}</b
    >, please enter the same here to login. Valid for 10 minutes.
  </div>
  <div class="form-container">
    <form [formGroup]="userLoginForm">
      <div class="field-row">
        <div class="field" *ngIf="isNewUser" style="margin-bottom: 8px">
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="name"
              placeholder="Name"
            />
            <mat-error *ngIf="userLoginForm.get('name').hasError('required')"
              >Name is required</mat-error
            >
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field>
            <input
              type="password"
              matInput
              formControlName="passcode"
              [placeholder]="
                userHasPassword ? 'Enter OTP or your password' : 'Enter OTP'
              "
            />
            <div
              matSuffix
              class="resend-otp-btn"
              *ngIf="(isAuthenticating$ | async) === false"
              (click)="resendOtp()"
            >
              Resend OTP
            </div>
            <mat-error
              *ngIf="userLoginForm.get('passcode').hasError('required')"
              >{{
                userHasPassword
                  ? 'OTP or Password is required.'
                  : 'OTP is required'
              }}</mat-error
            >
            <mat-error
              *ngIf="
                userLoginForm.get('passcode').hasError('minlength') &&
                !userLoginForm.get('passcode').hasError('required')
              "
              >{{
                userHasPassword ? 'Invalid OTP or Password' : 'Invalid OTP'
              }}</mat-error
            >
            <mat-error
              *ngIf="userLoginForm.get('passcode').hasError('incorrect')"
              >{{ errorMessage }}</mat-error
            >
            <mat-error
              *ngIf="userLoginForm.get('passcode').hasError('blocked')"
              >{{ errorMessage }}</mat-error
            >
          </mat-form-field>
          <span
            *ngIf="resendOTPSuccess && loginData.loginType === 'email'"
            style="font-size: 12px"
            >OTP Sent Successfully, please check your email for the OTP</span
          >
          <span
            *ngIf="resendOTPSuccess && loginData.loginType === 'phone'"
            style="font-size: 12px"
            >OTP Sent Successfully, please check your phone number for the
            OTP</span
          >
        </div>

        <div
          class="field blog-preference-div"
          *ngIf="isNewUser"
          style="margin-bottom: 8px"
        >
          <mat-checkbox formControlName="blogPreference"></mat-checkbox>

          <span class="subscribe-to-blog"
            >Subscribe to our blog to get expert coding tips.</span
          >
        </div>
      </div>

      <div class="buttons-row">
        <div class="submit-buttons">
          <button
            class="submit-button loading-button expand"
            [class.disabled]="userLoginForm.invalid"
            [class.sending]="isAuthenticating$ | async"
            (click)="ninjasLogin()"
          >
            Login
          </button>
          <div
            class="back-button"
            (click)="backPress.emit()"
            *ngIf="showGoBackButton"
          >
            <img
              alt="back"
              src="https://files.codingninjas.in/0000000000004060.svg"
              style=""
            />
            Go Back
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
