export class NetworkingError extends Error {
  constructor(public code: number, public message: string) {
    super(message);
  }

  public static readonly INTERNAL_SERVER_ERROR: NetworkingError = new NetworkingError(
    501,
    'Internal Server Error.'
  );
  //todo change code
  public static readonly NETWORK_ERROR: NetworkingError = new NetworkingError(
    503,
    'Network error'
  );
}
