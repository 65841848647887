import { Injectable } from '@angular/core';
import {
  ApiClient,
  CallBuilder,
  CallMethod,
  Resource,
  URLS,
} from '@codingninjas/networking';
import { Observable } from 'rxjs';
import {
  DoubtTeamMembersResponse,
  SlotScheduleRequestResponse,
  SlotsResponse,
  TaAllRequestResponse,
  TaLeaveRequestRespone,
  TaSlotChangeRequestResponse,
} from '../models/team-member.response';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root',
})
export class SlotScheduleRequestService {
  constructor(private apiClient: ApiClient) {}

  fetchTeamMembers(): Observable<Resource<DoubtTeamMembersResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.FETCH_DOUBT_TEAM_MEMBERS
    ).build();
    return this.apiClient.enqueue<DoubtTeamMembersResponse>(call);
  }

  fetchslotScheduleRequests(
    fromDate,
    toDate,
    courseModuleId,
    selectedTeamMemberId,
    selectedslotScheduleActivity,
    selectedSlotScheduleStatus
  ): Observable<Resource<SlotScheduleRequestResponse>> {
    fromDate = fromDate.toLocaleDateString('en-GB');
    toDate = toDate.toLocaleDateString('en-GB');

    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.FETCH_SLOT_REQUESTS
    )
      .setParam('from_date', fromDate)
      .setParam('to_date', toDate)
      .setParam('course_module_id', courseModuleId)
      .setParam('team_member_id', selectedTeamMemberId)
      .setParam('activity', selectedslotScheduleActivity)
      .setParam('slot_status', selectedSlotScheduleStatus)
      .build();
    return this.apiClient.enqueue<SlotScheduleRequestResponse>(call);
  }

  requestLeaveApplication(data: any): Observable<any> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.LEAVE_REQUEST
    )
      .setParam('from_date', data.fromDate)
      .setParam('to_date', data.toDate)
      .setParam('reason', data.reason)
      .setParam('ta_id', data.ta_id)
      .setParam('request_type', '0')
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  requestSlotReschedule(data: any): Observable<any> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.RESCHEDULE_REQUEST
    )
      .setParam('current_slot_assignment_id', data.current_slot_assignment_id)
      .setParam('current_slot_id', data.current_slot_id)
      .setParam('new_slot_id', data.new_slot_id)
      .setParam('ta_id', data.ta_id)
      .setParam('reason', data.reason)
      .setParam('date', data.date)
      .setParam('request_type', '1')
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  fetchAllSlots(taId): Observable<Resource<SlotsResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.FETCH_ALL_SLOTS
    )
      .setParam('ta_id', taId)
      .build();
    return this.apiClient.enqueue<SlotsResponse>(call);
  }

  fetchTaAllRequest(
    courseModuleId
  ): Observable<Resource<TaAllRequestResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.GET_ALL_REQUEST
    )
      .setParam('course_module_id', courseModuleId)
      .build();
    return this.apiClient.enqueue<TaAllRequestResponse>(call);
  }

  fetchCurrentDateSlot(taId, statDate): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.FETCH_CURRENT_DATE_SLOTS
    )
      .setParam('date', statDate)
      .setParam('ta_id', taId)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  updateSlotRequest(
    selectedslotScheduleRequestListings,
    requestType,
    currentUserId
  ): Observable<any> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.UPDATE_REQUEST
    )
      .setParam('request_type', requestType)
      .setParam('slot_schedule_listings', selectedslotScheduleRequestListings)
      .setParam('current_user_id', currentUserId)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  fetchLeaveRequests(): Observable<Resource<TaLeaveRequestRespone>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.GET_LEAVE_REQUEST
    ).build();
    return this.apiClient.enqueue<TaLeaveRequestRespone>(call);
  }

  cancelLeaveRequest(id): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.CANCEL_LEAVE_REQUEST
    )
      .setParam('id', id)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  fetchSlotChangeRequests(): Observable<Resource<TaSlotChangeRequestResponse>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.GET_SLOT_CHANGE_REQUEST
    ).build();
    return this.apiClient.enqueue<TaSlotChangeRequestResponse>(call);
  }

  fetchCourseModules(): Observable<Resource<any>> {
    const call = new CallBuilder(
      CallMethod.Get,
      URLS.V3.MENTORS.SLOT_SCHEDULE_REQUEST.FETCH_COURSE_MODULE
    ).build();
    return this.apiClient.enqueue<any>(call);
  }

  slotAvailable(slot: any) {
    const startTime = slot.split('-')[0].trim();
    const isPM = startTime.endsWith('PM');
    let [hours, minutes] = startTime.split(/:| /).map(Number);
    if (isPM) {
      hours = hours + (hours === 12 ? 0 : 12);
    } else {
      hours = hours === 12 ? 0 : hours;
    }
    (hours += 0), (minutes += 0);
    const totalMinutes = hours * 60 + minutes;
    const currentMinutes = new Date().getHours() * 60 + new Date().getMinutes();
    return currentMinutes <= totalMinutes;
  }
}
