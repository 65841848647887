export const CLASSROOM_REVERSE_AUTOLOGIN_CONFIG = {
  redirectURL: 'https://www.naukri.com/code360/reverse-auto-login-mediator',
  utm_source: 'code_studio',
  utm_campaign: 'naukri_jobs_on_code_studio',
  desktopMedium: 'desktop',
  mobileMedium: 'mobile_site',
  state: 'source=cn',
  desktopLanding: 'www.naukri.com/mnjuser/redirect',
  mobileLanding: 'www.naukri.com/mnj/redirect',
};

export const CLASSROOM_STUDIO_CTA_REVERSE_AUTOLOGIN_CONFIG = {
  redirectURL: 'https://www.naukri.com/code360/home',
  utm_source: 'code_studio',
  utm_campaign: 'naukri_jobs_on_code_studio',
  desktopMedium: 'desktop',
  mobileMedium: 'mobile_site',
  state: 'source=cn',
  desktopLanding: 'www.naukri.com/mnjuser/redirect',
  mobileLanding: 'www.naukri.com/mnj/redirect',
};

export const CLASSROOM_PROFILE_REVERSE_AUTOLOGIN_CONFIG = {
  redirectURL: 'https://www.naukri.com/code360/profile',
  utm_source: 'code_studio',
  utm_campaign: 'naukri_jobs_on_code_studio',
  desktopMedium: 'desktop',
  mobileMedium: 'mobile_site',
  state: 'source=cn',
  desktopLanding: 'www.naukri.com/mnjuser/redirect',
  mobileLanding: 'www.naukri.com/mnj/redirect',
};
