import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  CloseScrollStrategy,
  FlexibleConnectedPositionStrategy,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayRef,
  RepositionScrollStrategy,
  RepositionScrollStrategyConfig,
  ScrollDispatcher,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import {
  fallbackOriginConnectPosition,
  fallbackOverlayConnectionPositon,
  invertPopoverPosition,
  originConnectPosition,
  overlayConnectionPosition,
  PopoverPosition,
} from './../popover-position';
import { NinjasPopoverContainerComponent } from './ninjas-popover-container.component';

declare interface CloseScrollStrategyConfig {
  /** Amount of pixels the user has to scroll before the overlay is closed. */
  threshold?: number;
}

const CARET_BASE = 20;
const CARET_HEIGHT = 14;
const CARET_OFFSET = 12;
const SCROLL_THRESHOLD = 1;

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ninjasPopover]',
})
export class NinjasPopoverDirective implements AfterViewInit, OnDestroy {
  _overlayRef: OverlayRef | null;
  private readonly _closeScrollStrategy: (
    config?: CloseScrollStrategyConfig
  ) => CloseScrollStrategy;
  private readonly _repositionScrollStrategy: (
    config?: RepositionScrollStrategyConfig | undefined
  ) => RepositionScrollStrategy;
  private _viewInitialized = false;
  private _popoverContentTemplateRef: TemplateRef<unknown>;
  private scrollableAncestors;
  private _eleWidth = 0;
  private _eleHeight = 0;
  private _show: boolean;
  private _shown = false;
  private _popoverContainer: NinjasPopoverContainerComponent;
  /** Emits when the component is destroyed. */
  private readonly _destroyed = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private renderer: Renderer2,
    private _elementRef: ElementRef<HTMLElement>,
    private _scrollDispatcher: ScrollDispatcher,
    private _viewContainerRef: ViewContainerRef,
    private _ngZone: NgZone,
    private _sso: ScrollStrategyOptions,
    @Inject(PLATFORM_ID) private _platformId: object
  ) {
    this._closeScrollStrategy = _sso.close;
    this._repositionScrollStrategy = _sso.reposition;
  }

  private _position: PopoverPosition = 'top';
  @Input('position')
  get position() {
    return this._position;
  }

  set position(value: PopoverPosition) {
    if (value && value !== this._position) {
      this._position = value;
      if (this._overlayRef) {
        this._updatePosition();
        this._overlayRef.updatePosition();
      }
      if (this._popoverContainer) {
        this._popoverContainer.setPosition(this._position);
      }
    }
  }

  private _caret = true;
  @Input('caret')
  set caret(val: boolean) {
    this._caret = val;
    if (this._viewInitialized) {
      this._render();
    }
    if (this._popoverContainer) {
      this._popoverContainer.setCaret(val);
    }
  }

  private _roundedCaret = false;
  @Input('roundedCaret')
  set roundedCaret(val: boolean) {
    if (this._roundedCaret !== val) {
      this._roundedCaret = val;
      if (this._viewInitialized) {
        this._render();
      }
      if (this._popoverContainer) {
        this._popoverContainer.setRoundedCaret(val);
      }
    }
  }

  private _animationClass: string;
  @Input('animationClass')
  set animationClass(val: string) {
    if (this._animationClass !== val) {
      this._animationClass = val;
      if (this._viewInitialized) {
        this._render();
      }
      if (this._popoverContainer) {
        this._popoverContainer.setAnimationClass(val);
      }
    }
  }

  private _animationCeaseDuration: number;
  @Input('animationCeaseDuration')
  set animationCeaseDuration(val: number) {
    if (this._animationCeaseDuration !== val) {
      this._animationCeaseDuration = val;
      if (this._viewInitialized) {
        this._render();
      }
      if (this._popoverContainer) {
        this._popoverContainer.setAnimationCeaseDuration(val);
      }
    }
  }

  private _color = '#212121';
  @Input('color')
  set color(val: string) {
    this._color = val;
    if (this._viewInitialized) {
      this._render();
    }
    if (this._popoverContainer) {
      this._popoverContainer.setColor(val);
    }
  }

  private _allowRepositioningOnScroll = false;
  @Input('allowRepositioningOnScroll')
  set allowRepositioningOnScroll(val: boolean) {
    this._allowRepositioningOnScroll = val;
  }

  private _renderTimeout: ReturnType<typeof setTimeout> | undefined;
  private _clickableDelay = 0;

  @Input('isClickable')
  set isClickable(value: boolean) {
    this._clickableDelay = value ? 100 : 0;
  }

  @Input('open')
  get open() {
    return this._show;
  }

  set open(value: boolean) {
    this._show = value;

    if (!value) {
      if (this._viewInitialized) {
        if (this._clickableDelay > 0 && isPlatformBrowser(this._platformId)) {
          this._renderTimeout = setTimeout(() => {
            this._render();
          }, this._clickableDelay);
        } else {
          this._render();
        }
      }
    } else {
      if (this._renderTimeout) {
        clearTimeout(this._renderTimeout);
      }
      if (this._viewInitialized && !this._shown) {
        this._shown = true;
      }
      this._render();
    }
  }

  @Input('ninjasPopover')
  set templateRef(value: TemplateRef<unknown>) {
    if (value) {
      this._popoverContentTemplateRef = value;
      if (this._viewInitialized) {
        this._render();
      }
    }
  }

  @Input('hasBackdrop')
  set hasBackdrop(value: boolean) {
    if (this._hasBackdrop !== value) {
      this._hasBackdrop = value;
      if (this._viewInitialized) {
        if (this._overlayRef) {
          this._overlayRef.dispose();
          this._overlayRef = null;
        }
        this._render();
      }
    }
  }

  _hasBackdrop = false;

  @Input('backdropClass')
  set backdropClass(value: string) {
    if (this._backdropClass !== value) {
      this._backdropClass = value;
      if (this._viewInitialized) {
        if (this._overlayRef) {
          this._overlayRef.dispose();
          this._overlayRef = null;
        }
        this._render();
      }
    }
  }

  _backdropClass: string;

  @Input('popoverClass')
  set popoverClass(value: string) {
    if (this._popoverClass !== value) {
      this._popoverClass = value;
      if (this._viewInitialized) {
        if (this._overlayRef) {
          this._overlayRef.dispose();
          this._overlayRef = null;
        }
        this._render();
      }
    }
  }

  _popoverClass: string;

  @Output() popoverDismiss = new EventEmitter();

  @Output() outsideClick = new EventEmitter();

  ngAfterViewInit() {
    // This needs to happen after view init so the initial values for all inputs have been set.
    this._viewInitialized = true;
    // Set timeout to create a view dynamically in directive lifecycle. Issue https://github.com/angular/angular/issues/15634
    setTimeout(() => {
      this._render();
    });
  }

  /**
   * Dispose the popover when destroyed.
   */
  ngOnDestroy() {
    this._detach(true);

    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._popoverContentTemplateRef = null;
    }

    if (this._popoverContainer) {
      this._popoverContainer.dispose();
    }

    this._destroyed.next();
    this._destroyed.complete();
  }

  /**
   * Attaches the bottom sheet container component to the overlay.
   */
  private _attachContainer(
    overlayRef: OverlayRef
  ): ComponentRef<NinjasPopoverContainerComponent> {
    const containerPortal = new ComponentPortal(
      NinjasPopoverContainerComponent
    );
    const containerRef: ComponentRef<NinjasPopoverContainerComponent> =
      overlayRef.attach(containerPortal);
    return containerRef;
  }

  show(delay: number = 0): void {
    this._eleHeight = this._elementRef.nativeElement.offsetHeight;
    this._eleWidth = this._elementRef.nativeElement.offsetWidth;

    this._detach(false);

    const overlayRef = this._createOverlay();
    const containerRef = this._attachContainer(overlayRef);
    this._popoverContainer = containerRef.instance;
    this._popoverContainer.attachTemplatePortal(
      new TemplatePortal(
        this._popoverContentTemplateRef,
        this._viewContainerRef
      )
    );

    containerRef.onDestroy(() => {
      if (containerRef) {
        this._detach(true);
      }
    });

    this._overlayRef.updatePosition();
    this._popoverContainer.setCaret(this._caret);
    this._popoverContainer.setColor(this._color);
    this._popoverContainer.setPopoverClass(this._popoverClass);
    this._popoverContainer.setPosition(this._position);
    this._popoverContainer.setRoundedCaret(this._roundedCaret);
    this._popoverContainer.setAnimationClass(this._animationClass);
    this._popoverContainer.setAnimationCeaseDuration(
      this._animationCeaseDuration
    );
    // if (this.scrollableAncestors) {
    //   for (const anc of this.scrollableAncestors) {
    //     const ancElement = anc.elementRef;
    //     if (ancElement) {
    //       const nativeAnc = ancElement.nativeElement;
    //       this.renderer.addClass(nativeAnc, 'popover-no-scroll');
    //     }
    //   }
    // }
  }

  hide(delay: number = 0): void {
    this._detach(true);
    if (this._shown) {
      this.popoverDismiss.emit();
    }
  }

  /** Shows/hides the popover */
  toggle(): void {
    this._PopoverVisible() ? this.hide() : this.show();
  }

  /** Returns true if the popover is currently visible to the user */
  _PopoverVisible(): boolean {
    return this._show;
  }

  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  _getOrigin(): {
    main: OriginConnectionPosition;
    fallback: OriginConnectionPosition;
  } {
    const position = this._position;
    const originConnectionPosition = originConnectPosition(position);
    return {
      main: originConnectionPosition,
      fallback: fallbackOriginConnectPosition(originConnectionPosition),
    };
  }

  /** Returns the overlay position and a fallback position based on the user's preference */
  _getOverlayPosition(): {
    main: OverlayConnectionPosition;
    fallback: OverlayConnectionPosition;
    offsetX: number;
    offsetY: number;
  } {
    const position = this._position;
    const overlayPosition: OverlayConnectionPosition =
      overlayConnectionPosition(position);

    const defaultOffset = CARET_OFFSET + CARET_BASE / 2;
    const gap = CARET_HEIGHT + 4;

    let offsetX = 0;
    let offsetY = 0;

    // const lessHeightYOffset =
    //   this._eleHeight <= 32 ? Math.floor(this._eleHeight / 2) : 0;
    // const lessWidthXOffset =
    //   this._eleWidth <= 32 ? Math.floor(this._eleWidth / 2) : 0;

    switch (position) {
      case 'top':
        offsetY = -gap;
        break;
      case 'top-left':
        offsetX = defaultOffset;
        offsetY = -gap;
        break;
      case 'top-right':
        offsetX = -defaultOffset;
        offsetY = -gap;
        break;
      case 'bottom':
        offsetY = gap;
        break;
      case 'bottom-left':
        offsetX = defaultOffset;
        offsetY = gap;
        break;
      case 'bottom-right':
        offsetX = -defaultOffset;
        offsetY = gap;
        break;
      case 'left':
        offsetX = -gap;
        break;
      case 'left-top':
        offsetX = -gap;
        offsetY = defaultOffset;
        break;
      case 'left-bottom':
        offsetX = -gap;
        offsetY = -defaultOffset;
        break;
      case 'right':
        offsetX = gap;
        break;
      case 'right-top':
        offsetX = gap;
        offsetY = defaultOffset;
        break;
      case 'right-bottom':
        offsetX = gap;
        offsetY = -defaultOffset;
        break;
    }

    return {
      main: overlayPosition,
      fallback: fallbackOverlayConnectionPositon(overlayPosition),
      offsetX,
      offsetY,
    };
  }

  /** Create the overlay config and position strategy */
  private _createOverlay(): OverlayRef {
    if (this._overlayRef) {
      return this._overlayRef;
    }

    this.scrollableAncestors =
      this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._elementRef)
      .withGrowAfterOpen(false)
      .withLockedPosition(true)
      .withPush(false)
      .withTransformOriginOn('.popover-box')
      .withFlexibleDimensions(true)
      .withViewportMargin(0)
      .withScrollableContainers(this.scrollableAncestors);

    strategy.positionChanges
      .pipe(
        takeUntil(this._destroyed),
        map((change) => change.connectionPair.panelClass),
        distinctUntilChanged()
      )
      .subscribe((panelClass) => {
        if (this._popoverContainer) {
          const fallback: boolean = panelClass === 'fallback';
          let position = this._position;
          if (fallback) {
            position = invertPopoverPosition(position);
          }
          this._popoverContainer.setPosition(position);
        }
      });

    this._overlayRef = this._overlay.create({
      positionStrategy: strategy,
      panelClass: 'popover-panel',
      hasBackdrop: this._hasBackdrop,
      backdropClass: this._backdropClass,
      scrollStrategy: this.getScrollStrategy(),
    });

    this._overlayRef
      .outsidePointerEvents()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.outsideClick.emit();
      });

    this._updatePosition();

    this._ngZone.onStable
      .asObservable()
      .pipe(takeUntil(this._destroyed), debounceTime(100))
      .subscribe(() => {
        this._overlayRef.updatePosition();
      });

    this._overlayRef
      .detachments()
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => {
        this.hide();
      });

    return this._overlayRef;
  }

  getScrollStrategy() {
    return this._allowRepositioningOnScroll
      ? this._repositionScrollStrategy({
          autoClose: false,
          scrollThrottle: 50,
        })
      : this._closeScrollStrategy({ threshold: SCROLL_THRESHOLD });
  }

  /** Detaches the currently-attached popover. */
  private _detach(cleanAncestors = true) {
    if (this._popoverContainer && this._popoverContainer.hasAttached()) {
      this._popoverContainer.detach();
    }
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }

    // if (this.scrollableAncestors && cleanAncestors) {
    //   for (const anc of this.scrollableAncestors) {
    //     const ancElement = anc.elementRef;
    //     if (ancElement) {
    //       const nativeAnc = ancElement.nativeElement;
    //       this.renderer.removeClass(nativeAnc, 'popover-no-scroll');
    //     }
    //   }
    // }
  }

  /** Updates the position of the current popover. */
  private _updatePosition() {
    const position = this._overlayRef.getConfig()
      .positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this._getOrigin();
    const overlay = this._getOverlayPosition();

    position.withPositions([
      {
        ...origin.main,
        ...overlay.main,
        panelClass: 'actual',
        offsetX: overlay.offsetX,
        offsetY: overlay.offsetY,
      },
      {
        ...origin.fallback,
        ...overlay.fallback,
        panelClass: 'fallback',
        offsetX: -overlay.offsetX,
        offsetY: -overlay.offsetY,
      },
    ]);
  }

  private _render() {
    if (this._popoverContentTemplateRef) {
      if (this._show) {
        this.show(0);
      } else {
        this.hide(0);
      }
    }
  }
}
