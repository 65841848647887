<ng-container *ngIf="theme === 'light'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-underlined-text-cta
    size="small"
    (click)="
      openUrl(
        notification.content.primary_cta_url,
        notification.content.primary_cta_text
      )
    "
  >
    <span>{{ notification.content.primary_cta_text | sentenceCase }}</span>
    <mat-icon
      class="arrow-icon"
      fontSet="zen-icon"
      fontIcon="icon-arrow-top-right"
    ></mat-icon>
  </button>
</ng-container>
<ng-container *ngIf="theme === 'dark'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-underlined-text-cta
    size="small"
    (click)="
      openUrl(
        notification.content.primary_cta_url,
        notification.content.primary_cta_text
      )
    "
  >
    <span>{{ notification.content.primary_cta_text | sentenceCase }}</span>
    <mat-icon
      class="arrow-icon"
      fontSet="zen-icon"
      fontIcon="icon-arrow-top-right"
    ></mat-icon>
  </button>
</ng-container>
