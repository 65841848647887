import { TemplateRef } from '@angular/core';

export class NinjasNotification {

  private static counter = 0;

  private _icon: string;
  private _message: string;
  private _redirectLink: string;
  private _onClickCallback: EventCallback;
  private _onCloseCallback: EventCallback;
  private _onErrorCallback: EventCallback;
  private _onShowCallback: EventCallback;
  private readonly _id: string;
  private _options: NinjasNotificationOptions = {};
  private _template: TemplateRef<{}>;
  public state?: 'enter' | 'leave';


  public constructor(private _title: string,private _type: NinjasNotificationType = 'blank') {
    this._id = "notification-" + NinjasNotification.counter ++;
  }

  public setIcon(icon: string): NinjasNotification {
    this._icon = icon;
    return this;
  }

  public setMessage(message: string): NinjasNotification {
    this._message = message;
    return this;
  }

  public setType(type: NinjasNotificationType): NinjasNotification{
    this._type = type;
    return this;
  }

  public setOptions(options: NinjasNotificationOptions): NinjasNotification{
    this._options = options;
    return this;
  }

  public setRedirectLink(redirectLink: string): NinjasNotification {
    this._redirectLink = redirectLink;
    return this;
  }

  public setTemplate(template: TemplateRef<{}>): NinjasNotification {
    this._template = template;
    return this;
  }

  get template(): TemplateRef<{}>{
    return this._template;
  }

  get icon(): string {
    return this._icon;
  }

  get message(): string {
    return this._message;
  }

  get redirectLink(): string {
    return this._redirectLink;
  }

  get notificationId(): string{
    return this._id;
  }

  get type(): NinjasNotificationType{
    return this._type;
  }

  get options(): NinjasNotificationOptions{
    return this._options;
  }

  get title(): string {
    return this._title;
  }

  public setOnClickCallback(callback: EventCallback): NinjasNotification {
    this._onClickCallback = callback;
    return this;
  }

  public setOnCloseCallback(callback: EventCallback): NinjasNotification {
    this._onCloseCallback = callback;
    return this;
  }

  public setOnErrorCallback(callback: EventCallback): NinjasNotification {
    this._onErrorCallback = callback;
    return this;
  }

  public setOnShowCallback(callback: EventCallback): NinjasNotification {
    this._onShowCallback = callback;
    return this;
  }

  get onClickCallback(): EventCallback {
    return this._onClickCallback;
  }

  get onCloseCallback(): EventCallback {
    return this._onCloseCallback;
  }

  get onErrorCallback(): EventCallback {
    return this._onErrorCallback;
  }

  get onShowCallback(): EventCallback {
    return this._onShowCallback;
  }

}

type EventCallback = (event: Event) => void;
export type NinjasNotificationType = 'success' | 'info' | 'warning' | 'error' | 'blank'

export interface NinjasNotificationOptions {

  showClose?: boolean;
  animate?: boolean;
  duration?: number;
  pauseOnHover?: boolean;
  /* tslint:disable-next-line:no-any */
  style?: any;
  /* tslint:disable-next-line:no-any */
  class?: any;
  onClickCallback?:any;
  audio?:string;

}
