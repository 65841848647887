import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface ExtendedIconSet {
  name: string;
  url: string;
}

@NgModule({
  imports: [HttpClientModule],
})
export class ExtendedMatIconRegistryModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    const extraIcons: ExtendedIconSet[] = [
      {
        name: 'linkedin',
        url: '../assets/icons/linkedin.svg',
      },
      {
        name: 'whatsapp',
        url: '../assets/icons/whatsapp.svg',
      },
    ];

    extraIcons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}
