import { Injectable } from '@angular/core';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  URLS,
} from '@codingninjas/networking';
import { SubToCNUpdatesModel } from '@codingninjas/shared-ui/models/sub-to-cn-updates.model';

@Injectable({ providedIn: 'root' })
export class SubToCNUpdatesService {
  private professionalProductId: string;

  constructor(private apiClient: ApiClient) {}

  submitSubToCNUpdatesInfo(subToCNUpdatesInfo: SubToCNUpdatesModel) {
    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V2.SET_USER_WE_PARAMS
    )
      .body({
        ...subToCNUpdatesInfo,
        we_email: subToCNUpdatesInfo.should_sub_to_cn_updates,
      })
      .build();

    return this.apiClient.enqueue(call);
  }
}
