<ng-container *ngIf="theme === 'light'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-secondary-cta
    size="small"
    (click)="
      openUrl(
        notification.content.primary_cta_url,
        notification.content.primary_cta_text
      )
    "
  >
    {{ notification.content.primary_cta_text | sentenceCase }}
  </button>
</ng-container>
<ng-container *ngIf="theme === 'dark'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-primary-cta
    size="small"
    (click)="
      openUrl(
        notification.content.primary_cta_url,
        notification.content.primary_cta_text
      )
    "
  >
    {{ notification.content.primary_cta_text | sentenceCase }}
  </button>
</ng-container>
