import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  NotificationTheme,
  TaJobActivityNotification,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-monthly-recap-activity',
  templateUrl: './monthly-recap-activity.component.html',
  styleUrls: ['./monthly-recap-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyRecapActivityComponent {
  @Input() notification: TaJobActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;
  _actionPerformed = false;

  constructor(private cdr: ChangeDetectorRef) {}

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }
}
