import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { QuickPanelComponent } from './quick-panel.component';
import { FuseSharedModule } from '../../../../../@fuse/shared.module';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [QuickPanelComponent],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,

    FuseSharedModule,
  ],
  exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
