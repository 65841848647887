import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  BasePortalOutlet,
  CdkPortalOutlet,
  ComponentPortal,
  TemplatePortal,
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { PopoverPosition } from '../popover-position';

@Component({
  templateUrl: './ninjas-popover-container.component.html',
  styleUrls: [
    './ninjas-popover-container.component.scss',
    './ninjas-popover-container-rounded.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NinjasPopoverContainerComponent
  extends BasePortalOutlet
  implements OnInit
{
  /** The portal outlet inside of this container into which the content will be loaded. */
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet: CdkPortalOutlet;

  /** Server-side rendering-compatible reference to the global document object. */
  private _document: Document;

  _position: PopoverPosition = 'top';
  _caret = true;
  _color = '#212121';
  _popoverClass = '';
  _animationClass = '';
  _animationCeaseDuration = 0;
  _rounded_caret = false;

  constructor(
    private _elementRef: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) document: any
  ) {
    super();
    this._document = document;
  }

  ngOnInit(): void {}

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    return undefined;
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    return this._portalOutlet.attachTemplatePortal(portal);
  }

  setPosition(val: PopoverPosition) {
    this._position = val;
    this._changeDetectorRef.markForCheck();
  }

  setCaret(val: boolean) {
    this._caret = val;
    this._changeDetectorRef.markForCheck();
  }

  setColor(color: string) {
    this._color = color;
    this._changeDetectorRef.markForCheck();
  }

  setPopoverClass(value: string) {
    this._popoverClass = value;
    this._changeDetectorRef.markForCheck();
  }

  setRoundedCaret(value: boolean) {
    this._rounded_caret = value;
    this._changeDetectorRef.markForCheck();
  }

  setAnimationClass(value: string) {
    this._animationClass = value;
    this._changeDetectorRef.markForCheck();
  }

  // TODO- Check this shouldn't be needing it now
  setAnimationCeaseDuration(value: number) {
    this._animationCeaseDuration = value;
    setTimeout(() => {
      this._animationClass = '';
      this._changeDetectorRef.markForCheck();
    }, this._animationCeaseDuration);
  }
}
