import { AnimationOptions } from 'ngx-lottie';

export class CustomNinjasNotification {
  private static counter = 0;
  private _icon: string;
  private _animatedIcon: AnimationOptions;
  private _onActionableClickCallback: EventCallback;
  private _onAfterCloseCallback: EventCallback;
  private _onActionableIntentCallback: EventCallback;
  private readonly _id: string;
  private _options: CustomNinjasNotificationOptions = {};
  public state?: 'enter' | 'leave';
  _title: string;
  _description: string;
  backgroundClass: string;

  public constructor(_title: string, description: string) {
    this._title = _title;
    this._description = description;
    this._id = 'notification-' + CustomNinjasNotification.counter++;
  }

  // use this method if you want to use any normal icon ( send path of url )
  public setIcon(icon: string): CustomNinjasNotification {
    this._icon = icon;
    return this;
  }

  // use this method if you want to use any lottie animation as your icon ( send animationData )
  public setLottieAnimationData(
    icon: AnimationOptions
  ): CustomNinjasNotification {
    this._animatedIcon = icon;
    return this;
  }

  public setBackgroundClass(className: string): CustomNinjasNotification {
    this.backgroundClass = className;
    return this;
  }

  public setOptions(
    options: CustomNinjasNotificationOptions
  ): CustomNinjasNotification {
    this._options = options;
    return this;
  }

  get icon(): string {
    return this._icon;
  }

  get animatedIcon(): AnimationOptions {
    return this._animatedIcon;
  }

  get notificationId(): string {
    return this._id;
  }

  get options(): CustomNinjasNotificationOptions {
    return this._options;
  }

  get title(): string {
    return this._title;
  }

  get description(): string {
    return this._description;
  }

  // On Actionable Confirmed Callback

  public afterActionableClick(
    callback: EventCallback
  ): CustomNinjasNotification {
    this._onActionableClickCallback = callback;
    return this;
  }

  get actionableClickCallback(): EventCallback {
    return this._onActionableClickCallback;
  }

  // On Dismiss Callback

  public afterClosed(callback: EventCallback): CustomNinjasNotification {
    this._onAfterCloseCallback = callback;
    return this;
  }

  get afterClosedCallback(): EventCallback {
    return this._onAfterCloseCallback;
  }

  // On Actionable Intent Callback

  public onActionableIntent(callback: EventCallback): CustomNinjasNotification {
    this._onActionableIntentCallback = callback;
    return this;
  }

  get onActionableIntentCallback(): EventCallback {
    return this._onActionableIntentCallback;
  }
}

type EventCallback = (event: Event) => void;

export interface CustomNinjasNotificationOptions {
  showClose?: boolean;
  animate?: boolean;
  duration?: number;
  pauseOnHover?: boolean;
  audio?: string;
  style?: { [k: string]: string } | null;
}
