import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ResetAuthenticationError, AuthState, SocialAuth } from '../../+state';
import { IProviders, SocialProvider } from '../../social-login';
import { AuthService } from '../../auth.service';
import { NetworkingError, Resource } from '@codingninjas/networking';
import { EmailLoginResponse } from '../../+state/models/email-login.response';
import { MinifiedNotificationService } from '@codingninjas/minified-notification/minified-notification.service';
import { CONSTANTS } from '@codingninjas/shared-ui';
import { LoginData } from '../login-signup/login-signup.component';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AuthQuery } from './../../+state';
import { ActivatedRoute } from '@angular/router';
import { SubToCNUpdatesService } from '@codingninjas/shared-ui/services/sub-to-cn-updates.service';
import { isPlatformBrowser } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AnalyticsService } from '../../service/analytics.service';
import { SocialAuthService } from '../../social-login/social-auth.service';

declare let window: any;

@Component({
  selector: 'auth-initial-login-form',
  templateUrl: './initial-login-form.component.html',
  styleUrls: ['./initial-login-form.component.scss'],
})
export class InitialLoginFormComponent implements OnInit, OnDestroy {
  buttonText = 'Continue';
  isContinuePressed = false;
  errorMessage: string;
  _loginData: LoginData;
  isEmail = true;
  OAuthError: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() showOptInCheckBox: boolean = null;
  @Input() loginData: any;

  set data(value: LoginData) {
    this._loginData = value;
  }

  @Output() loginDataChange = new EventEmitter<LoginData>();
  @Output() formChange = new EventEmitter<number>();

  emailPhoneInputForm = new UntypedFormGroup({
    emailPhoneControl: new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]),
    shouldSubToCnUpdatesControl: new UntypedFormControl(
      true,
      Validators.required
    ),
  });

  isAuthenticating$: Observable<boolean>;
  authProvider$: Observable<string>;

  googleProvider = SocialProvider.GOOGLE;
  naukriProvider = SocialProvider.NAUKRI;
  oneTapVisible = false;
  unverified = false;
  continueWithNaukri = false;

  constructor(
    private authStore: Store<AuthState>,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationService: MinifiedNotificationService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private subToCNUpdatesService: SubToCNUpdatesService,
    private breakPointObserver: BreakpointObserver,
    private analyticsService: AnalyticsService,
    private socialAuthService: SocialAuthService,
    @Inject(PLATFORM_ID) private _platformId: object,
    @Inject('socialLoginConfig') private socialLoginConfig: IProviders
  ) {
    this.authStore
      .pipe(select(AuthQuery.getAuthError), takeUntil(this.destroy$))
      .subscribe((error: NetworkingError) => {
        if (error && error.code) {
          if (error.code === 943 || error.code === 925) {
            this.OAuthError = error.message;
          }
        } else {
          this.OAuthError = null;
        }
      });

    this.isAuthenticating$ = this.authStore.pipe(
      select(AuthQuery.getIfAuthenticating)
    );

    this.authProvider$ = this.authStore.pipe(select(AuthQuery.getProvider));
  }

  handleCredentialResponse(response) {
    this.authStore.dispatch(
      new SocialAuth(
        SocialProvider.GOOGLE_SINGLE_SIGN_IN,
        origin,
        response.credential
      )
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (!!params['continue_with_naukri']) {
        this.continueWithNaukri = true;
      }
    });

    if (isPlatformBrowser(this._platformId)) {
      this.breakPointObserver
        .observe('(max-width: 600px)')
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          const isMobile =
            this.breakPointObserver.isMatched('(max-width: 600px)');
          if (!isMobile && !this.continueWithNaukri) {
            this.loadGoogleOneTap();
          }
        });
    }

    this.unverified = this.route.snapshot.queryParamMap.has('unverified_email');

    if (this._loginData) {
      this.emailPhoneInputForm.controls.emailPhoneControl.setValue(
        this._loginData.email
      );
    }
  }

  loadGoogleOneTap() {
    const current = this;
    this.socialAuthService.loadOneTapScript().then(() => {
      if (!!window.google) {
        window.google.accounts.id.cancel();
        window.google.accounts.id.initialize({
          client_id: this.socialLoginConfig.google.clientId,
          callback: current.handleCredentialResponse.bind(current),
          prompt_parent_id: 'google-one-tap-container',
          cancel_on_tap_outside: false,
        });
        window.google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            this.oneTapVisible = false;
          } else {
            this.oneTapVisible = true;
          }
        });
      } else {
        this.oneTapVisible = false;
      }
    });
  }

  googleLogin() {
    const origin = !window.location.href.includes('auth?redirect')
      ? CONSTANTS.DEFAULTS.LOGIN_FROM_HEADER
      : '';
    if (this.isFacebookApp()) {
      const currentUrl =
        this.route.snapshot.queryParams['redirect'] ||
        (location.pathname + location.search).substr(1);
      const date = new Date();
      this.authService.setCookie(
        'ninjas-redirect-url',
        currentUrl,
        new Date(date.getTime() + 24 * 60 * 60 * 1000)
      );
    }
    this.authStore.dispatch(new SocialAuth(SocialProvider.GOOGLE, origin));
  }

  naukriLogin() {
    this.authStore.dispatch(new SocialAuth(SocialProvider.NAUKRI, origin));
  }

  phoneEmailChange(event) {
    const inputValue = event.target.value;
    this.isEmail = !/^\d+$/.test(inputValue);
    this.phoneEmailChangeUpdate();
  }

  phoneEmailChangeUpdate() {
    this.changeDetectorRef.detectChanges();
    this.emitLoginData();
  }

  emitLoginData() {
    this._loginData = {
      email: this.emailPhoneInputForm.controls.emailPhoneControl.value,
      loginType: 'email',
      phoneNumber: null,
      phoneCountryCode: null,
    };
    this.loginDataChange.emit(this._loginData);
  }

  continuePressed() {
    this.performEmailLogin();
  }

  performEmailLogin() {
    // TODO emit form change event based on api response;
    if (
      this.isContinuePressed ||
      !this.isEmail ||
      this.emailPhoneInputForm.invalid
    ) {
      return;
    }

    this.isContinuePressed = true;
    this.buttonText = 'Loading...';
    const email = this.emailPhoneInputForm.get('emailPhoneControl').value;
    this.authService
      .emailLogin(email)
      .subscribe((res: Resource<EmailLoginResponse>) => {
        if (res.isSuccessful()) {
          this.isContinuePressed = false;
          this.buttonText = 'Success';
          const userStatus: number = res.data.status;
          this.formChange.emit(userStatus);
        } else if (res.isUnsuccessful()) {
          this.isContinuePressed = false;
          this.buttonText = 'Continue';
          if (res.error.code === 422) {
            this.emailPhoneInputForm
              .get('emailPhoneControl')
              .setErrors({ email: true });
          } else if (res.error.code === 931 || res.error.code === 429) {
            this.errorMessage = res.error.message;
            this.emailPhoneInputForm
              .get('emailPhoneControl')
              .setErrors({ blocked: true });
          } else {
            this.notificationService.error(
              'Something Went Wrong',
              res.error.message
            );
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  isFacebookApp() {
    const ua = navigator.userAgent || navigator.vendor;
    return (
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.authStore.dispatch(new ResetAuthenticationError());
  }
}
