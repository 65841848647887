import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinifiedNotificationContainerComponent } from './minified-notification-container/minified-notification-container.component';
import { MinifiedNotificationComponent } from './notification/notification.component';
import { NINJAS_NOTIFICATION_DEFAULT_CONFIG_PROVIDER } from './minified-notification.config';
import { AnimationLoader } from 'ngx-lottie';

@NgModule({
  declarations: [
    MinifiedNotificationContainerComponent,
    MinifiedNotificationComponent,
  ],
  imports: [CommonModule],
  exports: [
    MinifiedNotificationContainerComponent,
    MinifiedNotificationComponent,
  ],
  providers: [NINJAS_NOTIFICATION_DEFAULT_CONFIG_PROVIDER, AnimationLoader],
})
export class MinifiedNotificationModule {}
