export type PriorityLevel = 'high' | 'others';
export type NotificationTheme = 'light' | 'dark';
export interface MyActivityNotificationModel {
  end_time: number;
  start_time: number;
  created_at: number;
  creation_at: string;
  id: number;
  activity_type: string;
  priority_level: PriorityLevel;
  read_at: number;
  delivered_at: number;
  auto_dismissible_time: number;
  has_primary_cta: boolean;
  has_secondary_cta: boolean;
  has_tertiary_cta: boolean;
  content: any;
}

export interface JobActivityNotification extends MyActivityNotificationModel {
  content: JobNotificationContent;
}

export interface TaJobActivityNotification extends MyActivityNotificationModel {
  content: TaNotificationContent;
}

export interface WebinarActivityNotification
  extends MyActivityNotificationModel {
  content: WebinarNotificationContent;
}

export interface SkillTestUnlockedActivityNotification
  extends MyActivityNotificationModel {
  content: SkillTestUnlockedNotificationContent;
}

export interface BookInterviewActivityNotification
  extends MyActivityNotificationModel {
  content: BookInterviewNotificationContent;
}

export interface AttendInterviewActivityNotification
  extends MyActivityNotificationModel {
  content: AttendInterviewNotificationContent;
}

export interface JobNotificationContent {
  title: string;
  icon_type: string;
  icon_context: string;
  company: string;
  location: string;
  ctc: number;
  position: string;
  end_time_key_name: string;
  primary_cta_text?: string;
  primary_cta_url?: string;
  secondary_cta_text?: string;
  secondary_cta_url?: string;
}

export interface TaNotificationContent {
  title: string;
  icon_type: string;
  icon_context: string;
  module_name: string;
  primary_cta_text?: string;
  primary_cta_url?: string;
}

export interface WebinarNotificationContent {
  title: string;
  event_name: string;
  event_start_time: number;
  icon_type: string;
  icon_context: string;
  end_time_key_name: string;
  primary_cta_text?: string;
  primary_cta_url?: string;
}

export interface SkillTestUnlockedNotificationContent {
  title: string;
  description: string;
  icon_type: string;
  icon_context: string;
  primary_cta_text?: string;
  primary_cta_url?: string;
}

export interface BookInterviewNotificationContent {
  title: string;
  description: string;
  icon_type: string;
  icon_context: string;
  primary_cta_text?: string;
  primary_cta_url?: string;
}

export interface AttendInterviewNotificationContent {
  title: string;
  description: string;
  interview_topic: string;
  session_start_time: string;
  icon_type: string;
  icon_context: string;
  end_time_key_name: string;
  primary_cta_text?: string;
  primary_cta_url?: string;
}

export const CAREER_OPPORTUNITY_ACTIVITY = 'CareerOpportunityActivity';
export const LAST_DAY_OPPORTUNITY_ACTIVITY = 'LastDayOpportunityActivity';
export const CAREER_OPPORTUNITY_TA_ACTIVITY = 'CareerOpportunityTaActivity';
export const MONTHLY_RECAP_ACTIVITY = 'MonthlyRecapActivity';
export const SHARE_TESTIMONIAL_ACTIVITY = 'ShareTestimonialActivity';
export const MAX_NOTIFICATION_SHOW_LIMIT = 2;

export const WEBINAR_REGISTER_USER_ACTIVITY = 'WebinarRegisterUserActivity';
export const WEBINAR_JOIN_USER_ACTIVITY = 'WebinarJoinUserActivity';
export const SKILL_TEST_UNLOCKED_ACTIVITY = 'SkillTestsUnlockedActivity';
export const BOOK_INTERVIEW_SESSION_ACTIVITY = 'BookInterviewSessionActivity';
export const ATTEND_INTERVIEW_SESSION_ACTIVITY =
  'AttendInterviewSessionActivity';
export const REMIND_REFERRED_USER_ACTIVITY = 'RemindReferredUserActivity';

export const ALL_ACTIVITIES_MAP = {
  CAREER_OPPORTUNITY_ACTIVITY,
  LAST_DAY_OPPORTUNITY_ACTIVITY,
  CAREER_OPPORTUNITY_TA_ACTIVITY,
  MONTHLY_RECAP_ACTIVITY,
  SHARE_TESTIMONIAL_ACTIVITY,
  WEBINAR_REGISTER_USER_ACTIVITY,
  WEBINAR_JOIN_USER_ACTIVITY,
  SKILL_TEST_UNLOCKED_ACTIVITY,
  BOOK_INTERVIEW_SESSION_ACTIVITY,
  ATTEND_INTERVIEW_SESSION_ACTIVITY,
  REMIND_REFERRED_USER_ACTIVITY,
};

export const ACTIVITY_TYPE_TO_ANALYTICS_NAME_MAP = {
  CareerOpportunityActivity: 'Job Created',
  LastDayOpportunityActivity: 'Job Deadline',
  CareerOpportunityTaActivity: 'TA Job',
  MonthlyRecapActivity: 'Share Monthly Recap',
  ShareTestimonialActivity: 'Share Testimonial',
  WebinarRegisterUserActivity: 'Webinar Created',
  WebinarJoinUserActivity: 'Webinar Deadline',
  SkillTestsUnlockedActivity: 'Attempt Skill Test',
  BookInterviewSessionActivity: 'Book 1:1 Session',
  AttendInterviewSessionActivity: 'Attend 1:1 Session',
  RemindReferredUserActivity: 'Remind Referred Users',
};
