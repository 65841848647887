import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthlyShareablePopupComponent } from './components/monthly-shareable-popup.component';
import { ExtendedMatIconRegistryModule } from '../../../../extended-mat-icon-registry.module';
import { SocialButtonsModule } from '@codingninjas/shared-ui/social-buttons';
import { NinjasPopoverModule } from '@codingninjas/shared-ui/popovers/ninjas-popover/ninjas-popover.module';

@NgModule({
  declarations: [MonthlyShareablePopupComponent],
  imports: [
    CommonModule,
    ExtendedMatIconRegistryModule,
    SocialButtonsModule,
    NinjasPopoverModule,
  ],
  exports: [MonthlyShareablePopupComponent],
})
export class MonthlyShareableModule {}
