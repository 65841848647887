<div class="spinner-wrapper">
  <div class="spinner">
    <div class="inner">
      <div class="gap"></div>
      <div class="left">
        <div class="half-circle"></div>
      </div>
      <div class="right">
        <div class="half-circle"></div>
      </div>
    </div>
  </div>
</div>
