import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mentor-weekoff-leave-apply',
  templateUrl: './weekoff-leave-apply.component.html',
  styleUrls: ['./weekoff-leave-apply.component.scss'],
})
export class WeekoffLeaveApplyComponent implements OnInit, OnDestroy {
  @Input() mentorUser;
  @Input() isTa;
  sub: Subscription;
  // slackData: any;
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
