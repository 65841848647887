export const isEqual = (value, other, visitedObjects = new WeakMap()) => {
  // Check if the values are identical or if they are both NaN
  if (value === other || (isNaN(value) && isNaN(other))) {
    return true;
  }

  // Check if the values are objects
  if (
    typeof value !== 'object' ||
    typeof other !== 'object' ||
    value === null ||
    other === null
  ) {
    return false;
  }

  // Check if the objects have already been visited
  if (visitedObjects.has(value) || visitedObjects.has(other)) {
    return visitedObjects.get(value) === visitedObjects.get(other);
  }

  visitedObjects.set(value, other);

  const keysA = Object.keys(value);
  const keysB = Object.keys(other);

  // Check if the objects have the same number of properties
  if (keysA.length !== keysB.length) {
    return false;
  }

  // Check if all properties are equal
  for (const key of keysA) {
    if (!isEqual(value[key], other[key], visitedObjects)) {
      return false;
    }
  }

  return true;
};

export const cloneDeep = (obj, visitedObjects = new WeakMap()) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (visitedObjects.has(obj)) {
    return visitedObjects.get(obj); // Return the cloned object if it has already been visited
  }

  let clone;
  if (Array.isArray(obj)) {
    clone = [];
  } else {
    clone = {};
  }

  visitedObjects.set(obj, clone); // Register the cloned object

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clone[key] = cloneDeep(obj[key], visitedObjects);
    }
  }

  return clone;
};

export const merge = (...objects) => {
  const isObject = (value) => typeof value === 'object' && value !== null;

  const mergeRecursive = (target, source, visitedObjects) => {
    if (visitedObjects.has(source)) {
      return source; // Break circular reference by returning the source object
    }

    visitedObjects.add(source);

    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach((key) => {
        if (isObject(source[key])) {
          if (!target.hasOwnProperty(key) || !isObject(target[key])) {
            target[key] = {};
          }
          target[key] = mergeRecursive(
            target[key],
            source[key],
            visitedObjects
          );
        } else {
          target[key] = source[key];
        }
      });
    }

    return target;
  };

  return objects.reduce((merged, obj) => {
    return mergeRecursive(merged, obj, new Set());
  }, {});
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};
