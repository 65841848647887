import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundV2Component } from '@codingninjas/shared-ui/not-found-v2/not-found-v2.component';
import { InternalErrorV2Component } from '@codingninjas/shared-ui/internal-error-v2/internal-error-v2.component';
import { INinjasAuthConfig } from './ninjas-auth-config.interface';
import { InternalErrorV3Component } from './internal-error-v3/internal-error-v3.component';
import { FourOFourErrorComponent } from './four-o-four-error/four-o-four-error.component';
import { NotFoundComponent } from '@codingninjas/shared-ui/not-found/not-found.component';
import { InternalErrorComponent } from '@codingninjas/shared-ui/internal-error/internal-error.component';
import { NinjaLoaderComponent } from '@codingninjas/shared-ui/ninja-loader/ninja-loader.component';

const SHARED_COMPONENTS = [
  NotFoundV2Component,
  LoaderComponent,
  InternalErrorV2Component,
  UnauthorisedComponent,
  ComingSoonComponent,
  InternalErrorV3Component,
  FourOFourErrorComponent,
  NotFoundComponent,
  InternalErrorComponent,
  NinjaLoaderComponent,
];
@NgModule({
  imports: [CommonModule, RouterModule],
  exports: SHARED_COMPONENTS,
  declarations: SHARED_COMPONENTS,
})
export class SharedUiModule {
  public static forNinjasConfig(
    ninjasConfig: INinjasAuthConfig
  ): ModuleWithProviders<SharedUiModule> {
    return {
      ngModule: SharedUiModule,
      providers: [
        {
          provide: 'socialLoginConfig', // you can also use InjectionToken
          useValue: ninjasConfig.socialProviders,
        },
      ],
    };
  }
}
