import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthState } from '@codingninjas/auth';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { IAuthConfig } from './social-login/ninjas-auth-config.interface';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private store: Store<AuthState>,
    @Inject('ninjasAuthConfig') private ninjasAuthConfig: IAuthConfig,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const url: string = state.url;
    return this.checkLogin(url, route.queryParamMap);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(childRoute, state);
  }

  checkLogin(url: string, paramsMap): boolean {
    let loggedIn = false;
    this.store
      .select('auth')
      .pipe(
        map((authData) => authData.isAuthenticated),
        take(1)
      )
      .subscribe((isLoggedIn) => {
        loggedIn = isLoggedIn;
      });
    if (loggedIn) {
      return true;
    }
    let authUrl;
    if (this.router.url.includes('https://www.codingninjas.com/v2')) {
      authUrl = this.ninjasAuthConfig.authUrl || '/v2/auth';
    } else {
      authUrl = this.ninjasAuthConfig.authUrl || '/auth';
    }

    if (url.includes('/apply')) {
      const email_present = paramsMap.get('email');
      const phone_present = paramsMap.get('phone_number');
      if (email_present || phone_present) {
        const base_url = url.split('?')[0];
        const keysToRemove = ['email', 'phone_number'];
        const queryParams = {};
        const keysToKeep = paramsMap.keys.filter(
          (k) => !keysToRemove.includes(k)
        );
        keysToKeep.forEach((k) => (queryParams[k] = paramsMap.get(k)));
        this.router.navigate([base_url], { queryParams });
        return false;
      }
    }

    this.router.navigate([authUrl], {
      queryParams: { redirect: url },
    });
    return false;
  }
}
