<div class="overtime-slot-container">
  <div class="slot-header mb-16 px-16 pt-12">
    <span class="title zen-typo-heading-5">Book overtime slot</span>
    <mat-icon class="icon-close" (click)="closeDialog()">close</mat-icon>
  </div>
  <div
    class="overtime-slot-date mb-cl-md-0 custom-selector-dropdown-wrapper flex-1 px-16"
  >
    <p class="label zen-typo-caption-medium mb-8">Select date</p>
    <mat-form-field
      class="custom-mat-selector-field classroom-form-field date-dropdown"
      appearance="outline"
      [ngClass]="{
        'form-not-filled': !dateSelector.panelOpen,
        'form-selector-opened': dateSelector.panelOpen
      }"
    >
      <mat-select
        [disableOptionCentering]="true"
        placeholder="Select date"
        (selectionChange)="dateSelected($event)"
        [panelClass]="[
          'custom-mat-selector-panel',
          'cheat-days-custom-selector-panel'
        ]"
        #dateSelector
      >
        <mat-option *ngFor="let date of availableDates" [value]="date.value">
          {{ date.label }}
        </mat-option>
      </mat-select>
      <mat-icon class="icon-calendar">calendar_today</mat-icon>
    </mat-form-field>
  </div>
  <ng-container
    *ngIf="
      selectedOvertimeSlotData.length > 0 && !loading;
      else loadingContainer
    "
  >
    <div class="overtime-slots">
      <table
        mat-table
        [dataSource]="selectedOvertimeSlotData"
        class="data-table"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="slot_date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let row" class="data-column">
            {{ row.date | date : 'd MMMM, y' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="start_time">
          <th mat-header-cell *matHeaderCellDef>Start time</th>
          <td mat-cell *matCellDef="let row" class="data-column">
            {{ row.start_time }}
          </td>
        </ng-container>

        <ng-container matColumnDef="end_time">
          <th mat-header-cell *matHeaderCellDef>End time</th>
          <td mat-cell *matCellDef="let row" class="data-column">
            {{ row.end_time }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let row" class="data-column">
            <ng-container
              *ngIf="
                slotBookLoading && bookingSlotId === row.id;
                else slotBooked
              "
            >
              <button
                zen-primary-cta
                size="small"
                class="book-slot-btn"
                [disabled]="slotBookLoading"
                (click)="openConfirmationDialog(row.id)"
              >
                Booking slot
                <zen-loader-dots
                  class="flex-row align-items-center"
                ></zen-loader-dots>
              </button>
            </ng-container>

            <ng-template #bookSlot>
              <button
                zen-secondary-cta
                size="small"
                class="book-slot-btn"
                [disabled]="slotBookLoading"
                (click)="openConfirmationDialog(row.id)"
              >
                Book slot
              </button>
            </ng-template>

            <ng-template #slotBooked>
              <ng-container
                *ngIf="
                  !slotBookLoading && bookingSlotId === row.id;
                  else bookSlot
                "
              >
                <button
                  zen-primary-cta
                  size="small"
                  class="book-slot-btn activate-success-button"
                  [disabled]="slotBookLoading"
                  (click)="openConfirmationDialog(row.id)"
                >
                  Slot booked
                  <mat-icon class="booked-icon">done</mat-icon>
                </button>
              </ng-container>
            </ng-template>
          </td>
        </ng-container>

        <tr
          class="data-row"
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          class="data-row"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </ng-container>
</div>

<ng-template #loadingContainer>
  <ng-container *ngIf="loading; else emptyState">
    <div class="loading-container zen-shimmer m-12"></div>
  </ng-container>
</ng-template>

<ng-template #emptyState>
  <div class="empty-state">
    <div class="zen-blank-state-illustration">
      <img src="https://files.codingninjas.in/desert-cactus-27673.svg" />
    </div>
    <div class="state-message zen-typo-body-small">
      No slots available at the moment.
    </div>
  </div>
</ng-template>
