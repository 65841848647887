import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { CoreService } from '../../../core.service';
import { MentorState } from '../reducers/mentor.reducer';
import { map, skip } from 'rxjs/operators';
import { Resource } from '@codingninjas/networking';
import {
  MentorActionTypes,
  OnlineStatusUpdated,
  UpdateOnlineStatus,
} from '../actions/mentor.actions';

@Injectable()
export class MentorEffects {
  constructor(private actions$: Actions, private apiService: CoreService) {}

  // @Effect()
  // changeOnlineStatus$ = this.dataPersistence.fetch(MentorActionTypes.UPDATE_ONLINE_STATUS, {
  //   run: (action: UpdateOnlineStatus, state: MentorState) => {
  //     return this.apiService.updateOnlineStatus(action.status).pipe(
  //       skip(1),
  //       map((resource: Resource<any>) => {
  //         if (resource.isSuccessful()) {
  //           return new OnlineStatusUpdated(action.status);
  //         }
  //         else {
  //           return new OnlineStatusUpdated();
  //         }
  //       })
  //     );
  //   },
  //
  // });
}
