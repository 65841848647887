import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CoreService } from '../core.service';
import { UserRoles } from '../+state/mentor/models/mentor-user';

@Injectable()
export class HomeRedirectGuard implements CanActivateChild {
  constructor(private router: Router, private coreService: CoreService) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (state.url === '/') {
      if (
        this.coreService.userHasRole(UserRoles.TA) ||
        this.coreService.userHasRole(UserRoles.ADMIN) ||
        this.coreService.userHasRole(UserRoles.DOUBTS_TEAM) ||
        this.coreService.userHasRole(UserRoles.DOUBTS_INTERN) ||
        this.coreService.userHasRole(UserRoles.DOUBTS_MANAGER) ||
        this.coreService.userHasRole(
          UserRoles.CAREER_CAMP_STUD_EXP_TASK_TEAM
        ) ||
        this.coreService.userHasRole(UserRoles.STUD_EXP_TASK_TEAM) ||
        this.coreService.userHasRole(UserRoles.STUDENT_EXPERIENCE_TEAM) ||
        this.coreService.userHasRole(UserRoles.STUDENT_EXP_MANAGER)
      ) {
        this.router.navigate(['/doubts']);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
    return true;
  }
}
