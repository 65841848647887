import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  NotificationTheme,
  SkillTestUnlockedActivityNotification,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-new-skills-unlocked-activity',
  templateUrl: './new-skills-unlocked-activity.component.html',
  styleUrls: ['./new-skills-unlocked-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewSkillsUnlockedActivityComponent implements OnInit {
  @Input() notification: SkillTestUnlockedActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;

  _actionPerformed = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.theme === 'dark') {
      this.notification.content.icon_context += '-fill';
    }
  }

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }
}
