import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import {
  ApiClient,
  CallBuilder,
  CallMethod,
  URLS,
} from '@codingninjas/networking';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, skip } from 'rxjs';
import { IAuthConfig } from './social-login/ninjas-auth-config.interface';
import { Store } from '@ngrx/store';
import {
  AuthQuery,
  AuthResponse,
  AuthState,
  PreAuth,
  SetAuthAndUser,
  SetCollegeValidationDetails,
  SetEmailVerificationAndBasicDetails,
  SetUser,
  TriggerReverseAutoLogin,
} from './+state';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { NaukriReverseAutoLoginService } from '../../../../libs/ninjas-utils/src/lib/services/naukri-reverse-autologin.service';

import { getQueryParams } from '../../../../libs/ninjas-utils/src/lib/utils/get-query-params.util';
import { CLASSROOM_REVERSE_AUTOLOGIN_CONFIG } from '../../../../libs/models/classroom-reverse-autologin.model';

@Injectable({
  providedIn: 'root',
})
export class AuthSetup {
  public country_id: BehaviorSubject<number>;
  public authApiDispatcher: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  req: any;
  loginCalled = new BehaviorSubject<string | null>(null);

  get authHeaders() {
    const token_data = this.getCookie('ninja_auth_token');
    const call_token = 'Token ' + token_data;
    return new HttpHeaders({ Authorization: call_token });
  }

  get authParams() {
    return new HttpParams();
  }

  get authUrl() {
    return this.ninjasAuthConfig.baseUrl + URLS.V2.AUTH_DETAILS;
  }

  get loginStatusUrl() {
    return this.ninjasAuthConfig.baseUrl + URLS.V2.LOGIN_STATUS;
  }

  constructor(
    @Inject('ninjasAuthConfig') private ninjasAuthConfig: IAuthConfig,
    private apiClient: ApiClient,
    private store: Store<AuthState>,
    @Inject(PLATFORM_ID) private _platformId: object,
    private injector: Injector,
    private naukriReverseAutoLoginService: NaukriReverseAutoLoginService
  ) {
    this.country_id = new BehaviorSubject<number>(0);
    if (isPlatformServer(_platformId)) {
      this.req = this.injector.get('req');
    }
    this.naukriReverseAutoLoginService.setConfig(
      CLASSROOM_REVERSE_AUTOLOGIN_CONFIG
    );
  }

  loadLoginStatus(): Promise<boolean> {
    const redirectedUrl = this.getRedirectedUrl(window.location.href);
    const queryParams = this.getAllQueryParams(redirectedUrl);
    const shorthandUtm =
      (queryParams['source'] !== null || queryParams['source'] !== undefined) &&
      (queryParams['utm_source'] === null ||
        queryParams['utm_source'] === undefined);
    const utmDetails = {
      utmSource: queryParams['source'] || queryParams['utm_source'],
      utmMedium: queryParams['medium'] || queryParams['utm_medium'],
      utmCampaign: queryParams['campaign'] || queryParams['utm_campaign'],
      shorthandUtm,
    };
    let sourceUrl = null;
    if (isPlatformBrowser(this._platformId)) {
      sourceUrl =
        queryParams['source_url'] ||
        window.location.host + window.location.pathname;
    }
    const sessionStartUrl = this.getFromSession('session_start_source');
    let params = new HttpParams();
    params = params.set('utm_details', JSON.stringify(utmDetails));
    params = params.set('source_url', sourceUrl);
    params = params.set('session_start_url', sessionStartUrl);
    return new Promise<boolean>((resolve, reject) => {
      this._getLoginStatus(this.loginStatusUrl, this.authHeaders, params)
        .pipe(skip(1))
        .subscribe((resource) => {
          if (resource.isSuccessful()) {
            const status = resource.data['status'];
            const country_id = resource.data['country_id'];
            const eu_user = resource.data['eu_user'];
            const eu_active_user = resource.data['eu_active_user'];
            const naukriUnverified =
              resource.data['naukri_unverified'] || false;
            const naukriFlowUser = resource.data['naukri_flow_user'] || 0;
            const registrationUtmSource =
              resource.data['registration_utm_source'] || null;
            const registrationUtmMedium =
              resource.data['registration_utm_medium'] || null;
            const registrationUtmCampaign =
              resource.data['registration_utm_campaign'] || null;
            const sourcePageUrl = resource.data['source_page_url'] || null;
            const sessionStartPageUrl =
              resource.data['session_start_page'] || null;
            const basicDetails = resource.data?.['user_basic_detail'] || null;
            const classroomPaid = resource.data['classroom_paid'];
            const initiate_rev_auto_login = resource.data['fetch_vendor_token'];
            const authorization_code = resource.data['authorization_code'];
            const naukriVendorTokenJourneyId =
              resource.data['naukri_vendor_token_journey_id'];
            const pageParams = getQueryParams();
            if (status === false) {
              this.eraseCookie('ninja_auth_token');
            }
            if (country_id) {
              this.country_id.next(country_id);
              localStorage.setItem('user_country', country_id);
            }

            this.store
              .select(AuthQuery.getReverseAutoLoginState)
              .subscribe((state) => {
                if (
                  !state &&
                  status &&
                  initiate_rev_auto_login &&
                  !pageParams['skip_fetch_vendor_token']
                ) {
                  this.naukriReverseAutoLoginService.initiateRevAutoLogin(
                    'classroom',
                    window.location.href,
                    naukriVendorTokenJourneyId,
                    authorization_code
                  );
                  this.store.dispatch(
                    new TriggerReverseAutoLogin({
                      reverse_auto_login_triggered: true,
                    })
                  );
                }
              });
            this.store.dispatch(
              new SetEmailVerificationAndBasicDetails({
                naukriUnverified,
                basicDetails,
                classroomPaid,
                naukriFlowUser,
                registrationUtmSource,
                registrationUtmMedium,
                registrationUtmCampaign,
                sourcePageUrl,
                sessionStartPageUrl,
              })
            );
            if (
              status &&
              eu_user &&
              !eu_active_user &&
              window.location.pathname !== '/coming-soon'
            ) {
              window.location.href = 'https://www.codingninjas.com/coming-soon';
            }
            if (!status) {
              this.eraseCookie('ninjas_auth_token');
            }
            this.loginCalled.next('code360');
            this.loginCalled.next('landing');
            resolve(status);
          } else {
            this.eraseCookie('ninjas_auth_token');
            this.loginCalled.next('code360');
            this.loginCalled.next('landing');
            resolve(false);
          }
        });
    });
  }

  loadAuthDetails(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._getAuthDetails(this.authUrl, this.authHeaders, this.authParams)
        .pipe(skip(1))
        .subscribe((authResource) => {
          if (authResource.isSuccessful() && authResource.data) {
            const updatedUserData = authResource.data;
            const user_access_token = this.getCookie('ninja_auth_token');
            this.store.dispatch(
              new SetAuthAndUser({
                user_access_token,
                authUser: updatedUserData,
              })
            );
            this.updateBasicDetails(updatedUserData);
          } else {
            this.fetchTokenAndUser();
          }
          resolve(true);
        });
    });
  }

  load(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        setTimeout(() => {
          this.loginCalled.next('code360');
          resolve(true);
        }, 3500); // resolving after 3.5 seconds if there is an api crash or speed deficiency

        const user_access_token = this.getCookie('ninja_auth_token');
        if (!!user_access_token) {
          this.loginCalled.next('code360');
          forkJoin([
            this.loadLoginStatus(),
            this._getAuthDetails(
              this.authUrl,
              this.authHeaders,
              this.authParams
            ),
          ]).subscribe(([resource, authResource]) => {
            if (resource) {
              if (authResource.isSuccessful() && authResource.data) {
                const updatedUserData = authResource.data;
                this.store.dispatch(new SetUser(updatedUserData));
                this.updateBasicDetails(updatedUserData);
              }
            }
            resolve(true);
          });
        } else {
          this.authApiDispatcher.subscribe((dispatch) => {
            if (dispatch) {
              this.loadAuthDetails().then((result) => resolve(true));
            }
          });
          this.loadLoginStatus().then((resource) => {
            if (resource) {
              this.authApiDispatcher.next(true);
            } else {
              resolve(true);
            }
          });
        }
      } catch (e) {
        reject();
      }
    });
  }

  private fetchTokenAndUser() {
    const token = this.getCookie('ninja_auth_token');
    const authData: AuthResponse = {
      user: null,
      user_access_token: token,
      provider: null,
    };
    if (token) {
      this.store.dispatch(new PreAuth(authData));
    }
  }

  private _getLoginStatus(url: string, headers, params): Observable<any> {
    const call = new CallBuilder(CallMethod.Get, url)
      .headers(headers)
      .params(params)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  private updateBasicDetails(data) {
    this.store.dispatch(
      new SetCollegeValidationDetails({
        naukri_gender_present: data.naukri_gender_present,
        naukri_mobile_present: data.naukri_mobile_present,
        naukri_college_present: data.naukri_college_present,
      })
    );
  }

  private _getAuthDetails(url: string, headers, params): Observable<any> {
    const call = new CallBuilder(CallMethod.Get, url)
      .headers(headers)
      .params(params)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  public getCookie(name: string): string {
    if (isPlatformBrowser(this._platformId)) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let c of ca) {
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    } else {
      const cookies = this.req.cookies;
      const cookieStore = {};
      if (!!cookies === false) {
        return null;
      }
      const cookiesArr = cookies.split('; ');
      for (const cookie of cookiesArr) {
        const index = cookie.indexOf('=');
        const cookieArr = [cookie.slice(0, index), cookie.slice(index + 1)];
        cookieStore[cookieArr[0]] = cookieArr[1];
      }
      return cookieStore[name];
    }
    return null;
  }

  public eraseCookie(name) {
    if (isPlatformBrowser(this._platformId)) {
      const domain = this.getDomain();
      document.cookie =
        name +
        '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
        domain +
        ';';
    }
  }

  private getDomain() {
    let domain = window.location.hostname.split('.').slice(1).join('.');
    domain = domain === '' ? 'localhost' : '.' + domain;
    return domain;
  }

  private getRedirectedUrl(baseUrl: string): string | null {
    const urlObj = new URL(baseUrl);
    const origin = urlObj.origin;
    const redirectParamValue = new URL(baseUrl).searchParams.get('redirect');
    if (redirectParamValue) {
      return origin + decodeURIComponent(redirectParamValue);
    }
    return baseUrl;
  }

  getAllQueryParams(url: string) {
    const params = {};
    const urlObj = new URL(url);
    const urlSearchParams = urlObj.searchParams;

    urlSearchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  }

  getFromSession(key) {
    if (isPlatformBrowser(this._platformId)) {
      return sessionStorage.getItem(key);
    }
    return null;
  }
}
