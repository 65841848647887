import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { NotificationComponent } from './notification/notification.component';
import { NINJAS_NOTIFICATION_DEFAULT_CONFIG_PROVIDER } from './ninjas-notification.config';
import { CustomNotificationComponent } from './custom-notification/custom-notification.component';
import { MatIconModule } from '@angular/material/icon';
import { AnimationLoader, LottieModule } from 'ngx-lottie';
import { ZenButtonModule } from '@codingninjashq/zen-ui';
import { CustomNotificationActionableComponent } from './custom-notification/custom-notification-actionable/custom-notification-actionable.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { TOAST_NOTIFICATION_DEFAULT_CONFIG_PROVIDER } from './toast-ninjas-notification.config';
import { MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG_PROVIDER } from './my-activity-notification.config';
import { MyActivityNotificationsBaseModule } from './my-activity-notification-base/my-activity-notifications-base.module';

function playerFactory() {
  // @ts-ignore
  return import('lottie-web/build/player/lottie_light');
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    LottieModule.forRoot({ player: playerFactory }),
    ZenButtonModule,
    MyActivityNotificationsBaseModule,
  ],
  declarations: [
    NotificationContainerComponent,
    NotificationComponent,
    ToastNotificationComponent,
    CustomNotificationComponent,
    CustomNotificationActionableComponent,
  ],
  exports: [NotificationContainerComponent],
  providers: [
    NINJAS_NOTIFICATION_DEFAULT_CONFIG_PROVIDER,
    TOAST_NOTIFICATION_DEFAULT_CONFIG_PROVIDER,
    MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG_PROVIDER,
    AnimationLoader,
  ],
})
export class NotificationModule {}
