<ng-container *ngIf="iconType === 'zen-icon'">
  <div>
    <mat-icon
      class="icon"
      fontSet="zen-icon"
      [fontIcon]="iconContext"
    ></mat-icon>
  </div>
</ng-container>
<ng-container *ngIf="iconType === 'img' || iconType === 'image'">
  <div>
    <img alt="i" class="icon" [src]="iconContext" (error)="onImageNotFound()" />
  </div>
</ng-container>
