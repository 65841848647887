import { Component, OnInit } from '@angular/core';
import { SlotScheduleRequestService } from '../../../../slot-schedule-request/services/slot-schedule-request.service';
import { Slot } from '../../../models/slot';

@Component({
  selector: 'mentor-reschedule-slot-modal',
  templateUrl: './reschedule-slot-modal.component.html',
  styleUrls: ['./reschedule-slot-modal.component.scss'],
})
export class RescheduleSlotModalComponent implements OnInit {
  taId: number;
  slots: Slot[];
  currentSlots: Slot[];
  date: any = new Date(new Date().getTime());
  request_number = 0;

  constructor(private apiService: SlotScheduleRequestService) {}

  ngOnInit() {
    this.fetchCurrentSlot();
  }

  fetchCurrentSlot() {
    this.apiService
      .fetchCurrentDateSlot(this.taId, this.date.toLocaleDateString('en-GB'))
      .subscribe((resource) => {
        if (resource.isSuccessful()) {
          this.currentSlots = resource.data.slots_listings;
        }
      });
  }
}
