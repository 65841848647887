import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  NotificationTheme,
  SkillTestUnlockedActivityNotification,
} from '../../../../../constants/my-activities-notification.constants';
import { MyActivitiesNotificationService } from '@codingninjas/notification';

@Component({
  selector: 'codingninjas-new-skills-unlocked-actions',
  templateUrl: './new-skills-unlocked-actions.component.html',
  styleUrls: ['./new-skills-unlocked-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewSkillsUnlockedActionsComponent {
  @Input() notification: SkillTestUnlockedActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;
  @Output() ctaClicked = new EventEmitter();

  constructor(
    private myActivityNotificationService: MyActivitiesNotificationService
  ) {}

  openUrl(url, ctaText) {
    window.open(url, '_self');
    this.ctaClicked.emit();
    this.myActivityNotificationService.triggerCTAClickAnalytics(
      ctaText,
      this.notification,
      this.source
    );
  }
}
