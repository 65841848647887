import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AuthService } from '../../auth.service';
import { AuthState } from '../../+state/auth.reducer';
import { ResetAuthenticationError } from '../../+state';
import { NinjasLogin } from '../../+state/auth.actions';
import { MinifiedNotificationService } from '@codingninjas/minified-notification/minified-notification.service';
import { AuthQuery } from '../../+state/auth.selector';
import { takeUntil } from 'rxjs/operators';
import { NetworkingError, Resource } from '@codingninjas/networking';
import { EmailLoginResponse } from '../../+state/models/email-login.response';
import { CONSTANTS } from '../../../../../shared-ui/src/lib/constants';
import { LoginData } from '../login-signup/login-signup.component';

@Component({
  selector: 'auth-user-login-form',
  templateUrl: './user-login-form.component.html',
  styleUrls: ['./user-login-form.component.scss'],
})
export class UserLoginFormComponent implements OnInit, OnDestroy {
  userLoginForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    passcode: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(6),
      this.validateOtpPassword.bind(this),
    ]),
    provider: new UntypedFormControl('', []),
    blogPreference: new UntypedFormControl(true, []),
  });

  isAuthenticating$: Observable<boolean>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorMessage = '';
  resendOTPSuccess = false;

  loginData: LoginData;
  @Input() set setLoginData(loginData: LoginData) {
    this.loginData = loginData;
    this.userLoginForm.get('name').patchValue(this.loginData.name || '');
  }
  @Input() userHasPassword = false;
  @Input() isNewUser = false;
  @Input() showGoBackButton = true;
  @Input() showOptInCheckBox: boolean = null;

  @Output() backPress = new EventEmitter<void>();

  constructor(
    private authStore: Store<AuthState>,
    private authService: AuthService,
    private notificationService: MinifiedNotificationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.isAuthenticating$ = this.authStore.pipe(
      select(AuthQuery.getIfAuthenticating)
    );

    this.authStore
      .pipe(select(AuthQuery.getAuthError), takeUntil(this.destroy$))
      .subscribe((error: NetworkingError) => {
        if (error && error.code) {
          if (
            error.code === 927 ||
            error.code === 928 ||
            error.code === 929 ||
            error.code === 930 ||
            error.code === 931
          ) {
            this.errorMessage = error.message;
            this.userLoginForm.get('passcode').setErrors({ incorrect: true });
          } else if (error.code !== 943) {
            this.notificationService.error(
              'Something went wrong',
              error.message
            );
          }
        }
      });
  }

  ngOnInit() {
    if (this.loginData.loginType === 'email') {
      this.userLoginForm.get('provider').setValue('coding_ninjas');
    } else {
      this.userLoginForm.get('provider').setValue('phone_coding_ninjas');
    }
    if (!this.isNewUser) {
      this.userLoginForm.removeControl('name');
      this.userLoginForm.removeControl('blogPreference');
      this.userLoginForm.updateValueAndValidity();
    }
  }

  ninjasLogin() {
    const origin = !window.location.href.includes('auth?redirect')
      ? CONSTANTS.DEFAULTS.LOGIN_FROM_HEADER
      : '';
    if (this.userLoginForm.valid) {
      this.authStore.dispatch(
        new NinjasLogin({
          ...this.userLoginForm.getRawValue(),
          email: this.loginData.email,
          phoneNumber: this.loginData.phoneNumber,
          phoneCountryCode: this.loginData.phoneCountryCode,
          origin,
        })
      );
    }
  }

  validateOtpPassword(fieldControl: UntypedFormControl) {
    const OTP_REGEX = /\d{6}/;
    if (this.userLoginForm) {
      const isValidOtp =
        fieldControl.value.length === 6 &&
        fieldControl.value.match(OTP_REGEX) &&
        fieldControl.value.match(OTP_REGEX).length === 1;

      if (
        this.userHasPassword &&
        fieldControl.value.length < 8 &&
        !isValidOtp
      ) {
        return { invalid: true };
      } else if (!this.userHasPassword && !isValidOtp) {
        return { pattern: true };
      }
    }

    return null;
  }

  resendOtp() {
    if (this.loginData.loginType === 'email') {
      this.resendOtpEmail();
    } else {
      this.resendOtpPhone();
    }
  }

  resendOtpEmail() {
    this.authService
      .emailLogin(this.loginData.email)
      .subscribe((res: Resource<EmailLoginResponse>) => {
        if (res.isSuccessful()) {
          this.resendOTPSuccess = true;
          this.errorMessage = '';
          setTimeout(() => {
            this.resendOTPSuccess = false;
            this.changeDetectorRef.detectChanges();
          }, 5000);
        } else if (res.isUnsuccessful()) {
          const error = res.error;
          if (error && error.code === 429) {
            this.errorMessage = error.message;
            this.userLoginForm.get('passcode').setErrors({ blocked: true });
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  resendOtpPhone() {
    this.authService
      .phoneLogin(this.loginData.phoneNumber, this.loginData.phoneCountryCode)
      .subscribe((res: Resource<EmailLoginResponse>) => {
        if (res.isSuccessful()) {
          this.resendOTPSuccess = true;
          this.errorMessage = '';
          setTimeout(() => {
            this.resendOTPSuccess = false;
            this.changeDetectorRef.detectChanges();
          }, 5000);
        } else if (res.isUnsuccessful()) {
          const error = res.error;
          if (res.error.code === 429) {
            this.errorMessage = error.message;
            this.userLoginForm.get('passcode').setErrors({ blocked: true });
          } else {
            this.notificationService.error(
              'Something Went Wrong',
              res.error.message
            );
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.authStore.dispatch(new ResetAuthenticationError());
  }
}
