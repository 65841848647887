import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { isNullOrUndefined } from '../../../../ninjas-utils/src/lib/utils/blank-check.util';
import { ToastNotificationService } from '../toast-ninjas-notification.service';
import { ToastNotificationConfig } from '../toast-ninjas-notification.config';
import {
  ToastNinjasNotification,
  ToastNotificationOptions,
} from '../toast-ninjas-notification';
import { ToastNotificationAnimation } from './toast-notification.animation';

@Component({
  selector: 'codingninjas-toast-notification',
  animations: [ToastNotificationAnimation],
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
  @Input() notification: ToastNinjasNotification;
  @Input() index: number;

  config: ToastNotificationConfig = {};

  protected _options: ToastNotificationOptions;

  private _autoErase: boolean;
  private _eraseTimer: number = null;
  private _eraseTimingStart: number;
  private _eraseTTL: number;
  onClickCallbackPresent = false;
  constructor(private notificationService: ToastNotificationService) {
    this.config = notificationService.getConfig();
  }

  ngOnInit(): void {
    this.setNotificationState();
    this.autoEraseNotification();
    this.checkClickCallBackStatus();
  }

  setNotificationState() {
    this._options = this.notification.options;

    if (this._options.animate) {
      this.notification.state = 'enter';
    }
    if (!isNullOrUndefined(this._options.audio)) {
      this.playAudio();
    }
  }
  autoEraseNotification() {
    this._autoErase = this._options.duration > 0;

    if (this._autoErase) {
      this._initErase();
      this._startEraseTimeout();
    }
  }
  checkClickCallBackStatus() {
    if (!isNullOrUndefined(this._options.onClickCallback)) {
      this.onClickCallbackPresent = true;
    }
  }
  playAudio() {
    const audio = new Audio(this._options.audio);
    audio.play().then((r) => {});
  }

  ngOnDestroy(): void {
    if (this._autoErase) {
      this._clearEraseTimeout();
    }
  }

  onClick(): void {
    if (this.onClickCallbackPresent) {
      this._options.onClickCallback();
    }
  }

  protected _destroy(): void {
    if (this._options.animate) {
      this.notification.state = 'leave';
      setTimeout(
        () =>
          this.notificationService.removeNotification(
            this.notification.notificationId
          ),
        200
      );
    } else {
      this.notificationService.removeNotification(
        this.notification.notificationId
      );
    }
  }

  private _initErase(): void {
    this._eraseTTL = this._options.duration;
    this._eraseTimingStart = Date.now();
  }

  private _updateTTL(): void {
    if (this._autoErase) {
      this._eraseTTL -= Date.now() - this._eraseTimingStart;
    }
  }

  private _startEraseTimeout(): void {
    if (this._eraseTTL > 0) {
      this._clearEraseTimeout();
      this._eraseTimer = window.setTimeout(
        () => this._destroy(),
        this._eraseTTL
      );
      this._eraseTimingStart = Date.now();
    } else {
      this._destroy();
    }
  }

  private _clearEraseTimeout(): void {
    if (this._eraseTimer !== null) {
      window.clearTimeout(this._eraseTimer);
      this._eraseTimer = null;
    }
  }

  close(): void {
    this._destroy();
  }

  get state(): string {
    if (this.notification.state === 'enter') {
      if (this.config.placement === 'bottomMiddle') {
        return 'enterBottom';
      }
    } else {
      return this.notification.state;
    }
  }
}
