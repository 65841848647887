<div class="activity-container">
  <div [class]="theme" class="flex-row gap-8">
    <div class="left">
      <codingninjas-my-activity-icon
        [iconType]="notification.content.icon_type"
        [iconContext]="notification.content.icon_context"
      ></codingninjas-my-activity-icon>
    </div>
    <div class="right flex-column gap-12 flex-1">
      <div>
        <div class="notification-header flex-row justify-content-space-between">
          <div
            style="max-width: 220px"
            class="job-title zen-typo-subtitle-small"
          >
            {{ notification.content.title }}
          </div>
          <codingninjas-my-activity-header-info
            *ngIf="theme === 'light'"
            [notification]="notification"
            [actionPerformed]="_actionPerformed"
          ></codingninjas-my-activity-header-info>
        </div>

        <div class="provoker-section flex-row align-items-center gap-8 pt-8">
          Nudge your friend to receive your referral bonus!
        </div>
      </div>
      <div>
        <codingninjas-remind-referred-user-actions
          [theme]="theme"
          [notification]="notification"
          (ctaClicked)="actionPerformed()"
          [source]="source"
        ></codingninjas-remind-referred-user-actions>
      </div>
    </div>
  </div>
</div>
