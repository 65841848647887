import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Call } from './call';
import { Resource } from './resource';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { CallMethod } from './call-method';
import { finalize, retry } from 'rxjs/operators';
import { ResourceFactory } from './resource-factory';
import { ApiClientOptions } from './api-client-options';
import { CallHelper } from './call-helper';

@Injectable({
  providedIn: 'root',
})
export class ApiClient {
  private callExecutionInProgressSource = new BehaviorSubject<boolean>(false);
  public callExecutionInProgress$ =
    this.callExecutionInProgressSource.asObservable();

  private callInProgCount = 0;

  constructor(
    private http: HttpClient,
    private resourceFactory: ResourceFactory,
    @Inject('options') private options: ApiClientOptions,
    @Inject('env') private environment,
    private callHelper: CallHelper
  ) {}

  private onPreExecute(call: Call) {
    if (call.wouldLoad) {
      this.callInProgCount++;
      if (this.callInProgCount > 0) {
        this.callExecutionInProgressSource.next(true);
      }
    }
  }

  private onPostExecute(call: Call) {
    if (call.wouldLoad) {
      this.callInProgCount--;
      if (this.callInProgCount <= 0) {
        this.callExecutionInProgressSource.next(false);
      }
    }
  }

  private rawRequest<T>(url: string, call: Call): Observable<T> {
    const method: CallMethod = call.method;
    switch (method) {
      case CallMethod.Get:
        return this.http.get<T>(url, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: true,
        });
      case CallMethod.Post:
        return this.http.post<T>(url, call.body, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: true,
        });
      case CallMethod.Delete:
        return this.http.delete<T>(url, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: true,
        });
      case CallMethod.Put:
        return this.http.put<T>(url, call.body, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: true,
        });
      case CallMethod.Patch:
        return this.http.patch<T>(url, call.body, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: true,
        });
      case CallMethod.Head:
        return this.http.head<T>(url, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: true,
        });
      case CallMethod.Options:
        return this.http.options<T>(url, {
          observe: 'body',
          responseType: 'json',
          params: call.params,
          headers: call.headers,
          withCredentials: false,
        });
      default:
        return null;
    }
  }

  public request<T>(call: Call): Observable<T> {
    let url = call.url;
    try {
      const parsedURL: URL = new URL(url);
    } catch (e) {
      if (this.options.app === 'publicsection' && this.callHelper.isSSRBot()) {
        url = this.options.legacyBaseUrl + url;
      } else if (this.options && this.options.baseUrl) {
        url = this.options.baseUrl + url;
      }
    }
    const request: Observable<T> = this.rawRequest<T>(url, call);
    if (request != null) {
      return defer(() => {
        this.onPreExecute(call);
        return request.pipe(
          retry(call.retryCount),
          finalize(() => {
            this.onPostExecute(call);
          })
        );
      });
    }
  }

  public enqueue<T>(call: Call): Observable<Resource<T>> {
    return this.resourceFactory.createResource<T>(this, call);
  }
}
