import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  JobActivityNotification,
  NotificationTheme,
} from '../../../../constants/my-activities-notification.constants';

@Component({
  selector: 'codingninjas-deadline-job-activity',
  templateUrl: './deadline-job-activity.component.html',
  styleUrls: ['./deadline-job-activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeadlineJobActivityComponent {
  @Input() notification: JobActivityNotification;
  @Input() theme: NotificationTheme = 'light';
  @Input() source: string;
  _actionPerformed = false;

  constructor(private cdr: ChangeDetectorRef) {}

  actionPerformed() {
    this._actionPerformed = true;
    this.cdr.markForCheck();
  }
}
