import { InjectionToken } from '@angular/core';

export interface MyActivityNotificationConfig {
  top?: string;
  bottom?: string;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  duration?: number;
  maxStack?: number;
  pauseOnHover?: boolean;
  animate?: boolean;
  showClose?: boolean;
  audio?: string;
}

export const MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG =
  new InjectionToken<MyActivityNotificationConfig>(
    'MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG'
  );

export const MY_ACTIVITY_NOTIFICATION_CONFIG =
  new InjectionToken<MyActivityNotificationConfig>(
    'MY_ACTIVITY_NOTIFICATION_CONFIG'
  );

export const MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG_PROVIDER = {
  provide: MY_ACTIVITY_NOTIFICATION_DEFAULT_CONFIG,
  useValue: {
    top: '24px',
    bottom: '24px',
    placement: 'topRight',
    duration: 4500,
    maxStack: 3,
    pauseOnHover: true,
    animate: true,
    showClose: true,
    audio: 'https://files.codingninjas.in/simple_notification-29301.mp3',
  },
};
