import { DoubtActions, DoubtActionTypes } from '../actions/doubt.actions';
import { isNullOrUndefined } from '@codingninjas/ninjas-utils';
import { Doubt } from '../models/doubt';
import { CourseModule } from '../models/course-module';
import { DoubtRejectCount } from '../models/doubt-target-module-response';
import { Action } from '@ngrx/store';

export interface DoubtState {
  readonly doubt: DoubtData;
}

export interface ActionStatus {
  loading: boolean;
  remark: string;
}

export interface DoubtTarget {
  resolveTargetCount: number;
  reviewPendingTargetCount: number;
  totalTargetAchivied: number;
  doubtWarningColor: string;
}

export interface DoubtData {
  doubts: Map<number, Doubt>;
  onlineMode: boolean;
  mentorCourseModules: CourseModule[];
  actionStatus: ActionStatus;
  targetReviewCount: DoubtTarget;
  doubtRejectCount: DoubtRejectCount;
  assignment: DoubtAssignmentData;
  timerExpireCount: number;
  activeDoubtIds: number[];
  resolvedDoubtIds: number[];
  availableDoubtIds: number[];
  assignNextStatus: boolean;
  pendingOnUserDoubtIds: number[];
  doubtActivityStatus: boolean;
  totalPendingDoubts: number;
  totalAvailableDoubts: number;
  totalResolvedDoubts: number;
  reviewPendingDoubtIds: number[];
  totalReviewPendingDoubts: number;
  mentorsAvailableDoubts: number;
  slotActive: boolean;
}

export interface DoubtAssignmentData {
  doubtId: number;
  loading: boolean;
  error: string;
  timerValue: number;
  timerCompleteAction: string;
  doubtPoints: number;
}

export const assignmentInitialState: DoubtAssignmentData = {
  loading: false,
  doubtId: -1,
  error: null,
  timerValue: 0,
  timerCompleteAction: '',
  doubtPoints: 0,
};

export const actionStatusInitialState: ActionStatus = {
  loading: false,
  remark: null,
};

export const resolveDoubtInitialState: DoubtTarget = {
  resolveTargetCount: 0,
  reviewPendingTargetCount: 0,
  totalTargetAchivied: 0,
  doubtWarningColor: '',
};

export const DoubtRejectCountInitialState: DoubtRejectCount = {
  total_reject_count: 0,
};

export const doubtInitialState: DoubtData = {
  doubts: new Map<number, Doubt>(),
  mentorCourseModules: [],
  onlineMode: true,
  assignment: assignmentInitialState,
  actionStatus: actionStatusInitialState,
  targetReviewCount: resolveDoubtInitialState,
  doubtRejectCount: DoubtRejectCountInitialState,
  timerExpireCount: 0,
  doubtActivityStatus: false,
  assignNextStatus: false,
  activeDoubtIds: [],
  availableDoubtIds: [],
  resolvedDoubtIds: [],
  pendingOnUserDoubtIds: [],
  reviewPendingDoubtIds: [],
  totalReviewPendingDoubts: 0,
  totalAvailableDoubts: 0,
  totalPendingDoubts: 0,
  totalResolvedDoubts: 0,
  mentorsAvailableDoubts: 0,
  slotActive: false,
};

export function doubtReducer(
  state: DoubtData = doubtInitialState,
  action: DoubtActions
): DoubtData {
  const doubts = new Map<number, Doubt>(state.doubts);
  let activeDoubtIds = [...state.activeDoubtIds];
  let resolvedDoubtIds = [...state.resolvedDoubtIds];
  let pendingOnUserDoubtIds = [...state.pendingOnUserDoubtIds];
  let availableDoubtIds = [...state.availableDoubtIds];
  let reviewPendingDoubtIds = [...state.reviewPendingDoubtIds];

  let totalAvailableDoubts = state.totalAvailableDoubts;
  let totalReviewPendingDoubts = state.totalReviewPendingDoubts;
  let totalPendingDoubts = state.totalPendingDoubts;
  let totalResolvedDoubts = state.totalResolvedDoubts;

  switch (action.type) {
    case DoubtActionTypes.UPDATE_DOUBT_ACTIVITY_STATUS:
      return {
        ...state,
        timerExpireCount: 0,
        doubtActivityStatus: action.status,
      };

    case DoubtActionTypes.MENTOR_COURSE_MODULES_FETCHED:
      return { ...state, mentorCourseModules: action.courseModules };

    case DoubtActionTypes.ASSIGNING_DOUBT:
      return {
        ...state,
        assignNextStatus: false,
        assignment: assignmentReducer(state.assignment, action),
      };
    case DoubtActionTypes.DOUBT_ASSIGN_ERROR:
      return {
        ...state,
        assignNextStatus: true,
        assignment: assignmentReducer(state.assignment, action),
      };

    case DoubtActionTypes.DOUBT_ASSIGNED:
      let mentorsAvailableDoubts = state.mentorsAvailableDoubts;
      if (!isNullOrUndefined(action.mentorsAvailableDoubts)) {
        mentorsAvailableDoubts = action.mentorsAvailableDoubts;
      }
      if (state.doubtActivityStatus === true) {
        if (isNullOrUndefined(action.doubt)) {
          return {
            ...state,
            mentorsAvailableDoubts,
            assignNextStatus: true,
            actionStatus: actionStatusInitialState,
            assignment: assignmentReducer(state.assignment, action),
          };
        }
        doubts.set(action.doubt.id, action.doubt);
        return {
          ...state,
          mentorsAvailableDoubts,
          assignNextStatus: false,
          actionStatus: actionStatusInitialState,
          assignment: assignmentReducer(state.assignment, action),
          doubts,
        };
      } else {
        return {
          ...state,
          mentorsAvailableDoubts,
          assignNextStatus: false,
          actionStatus: actionStatusInitialState,
          assignment: {
            ...assignmentInitialState,
            error: 'Please start doubt activity',
          },
        };
      }

    case DoubtActionTypes.ACTIVE_DOUBTS_FETCHED:
      for (const doubt of action.doubts) {
        doubts.set(doubt.id, doubt);
        if (!activeDoubtIds.includes(doubt.id)) {
          activeDoubtIds.push(doubt.id);
        }
      }
      return { ...state, activeDoubtIds, doubts };

    case DoubtActionTypes.REVIEW_PENDING_DOUBTS_FETCHED:
      for (const doubt of action.doubts) {
        doubts.set(doubt.id, doubt);
        if (!reviewPendingDoubtIds.includes(doubt.id)) {
          reviewPendingDoubtIds.push(doubt.id);
        }
      }
      return {
        ...state,
        reviewPendingDoubtIds,
        doubts,
        totalReviewPendingDoubts: action.totalCount,
      };

    case DoubtActionTypes.ACCEPTED_DOUBT:
      doubts.set(action.doubt.id, action.doubt);
      if (!activeDoubtIds.includes(action.doubt.id)) {
        activeDoubtIds.push(action.doubt.id);
      }
      return {
        ...state,
        assignNextStatus: true,
        doubts,
        activeDoubtIds,
        actionStatus: actionStatusInitialState,
        assignment: {
          ...assignmentInitialState,
          error: 'Click the button below to get another doubt',
        },
      };

    case DoubtActionTypes.CHANGE_ACTION_STATUS:
      if (action.error) {
        return {
          ...state,
          assignNextStatus: true,
          assignment: { ...assignmentInitialState, error: action.error },
          actionStatus: { loading: action.loading, remark: action.remark },
        };
      } else {
        return {
          ...state,
          actionStatus: { loading: action.loading, remark: action.remark },
        };
      }

    case DoubtActionTypes.UPDATE_RESOLVE_COUNTER:
      return {
        ...state,
        targetReviewCount: {
          resolveTargetCount: action.resolveTargetCount,
          reviewPendingTargetCount: action.reviewPendingTargetCount,
          totalTargetAchivied: action.totalTargetAchivied,
          doubtWarningColor: action.doubtWarningColor,
        },
      };

    case DoubtActionTypes.UPDATE_DOUBT_REJECT_COUNTER:
      // tslint:disable-next-line:max-line-length
      return {
        ...state,
        doubtRejectCount: {
          total_reject_count: action.totalRejectCount,
        },
      };
    case DoubtActionTypes.RESOLVED_DOUBTS_FETCHED:
      if (action.filterApplied === true) {
        resolvedDoubtIds = [];
      }
      for (const doubt of action.doubts) {
        doubts.set(doubt.id, doubt);
        if (!resolvedDoubtIds.includes(doubt.id)) {
          resolvedDoubtIds.push(doubt.id);
        }
      }
      return {
        ...state,
        resolvedDoubtIds: sortDoubts(doubts, resolvedDoubtIds),
        doubts,
        totalResolvedDoubts: action.totalCount,
      };

    case DoubtActionTypes.AVAILABLE_DOUBTS_FETCHED:
      for (const doubt of action.doubts) {
        doubts.set(doubt.id, doubt);
        if (!availableDoubtIds.includes(doubt.id)) {
          availableDoubtIds.push(doubt.id);
        }
      }
      return {
        ...state,
        availableDoubtIds: sortDoubts(doubts, availableDoubtIds),
        doubts,
        totalAvailableDoubts: action.totalCount,
      };

    case DoubtActionTypes.PENDING_ON_USER_DOUBTS_FETCHED:
      for (const doubt of action.doubts) {
        doubts.set(doubt.id, doubt);
        if (!pendingOnUserDoubtIds.includes(doubt.id)) {
          pendingOnUserDoubtIds.push(doubt.id);
        }
      }
      return {
        ...state,
        pendingOnUserDoubtIds: sortDoubts(doubts, pendingOnUserDoubtIds),
        doubts,
        totalPendingDoubts: action.totalCount,
      };

    case DoubtActionTypes.UPDATE_ASSIGN_NEXT_STATUS:
      return { ...state, assignNextStatus: action.status };

    case DoubtActionTypes.ADD_TO_ACTIVE_DOUBTS:
      if (!activeDoubtIds.includes(action.doubtId)) {
        activeDoubtIds.push(action.doubtId);
      }
      return { ...state, activeDoubtIds };

    case DoubtActionTypes.REMOVE_FROM_ACTIVE_DOUBTS:
      activeDoubtIds = activeDoubtIds.filter(
        (doubtId) => doubtId !== action.doubtId
      );
      return { ...state, activeDoubtIds };

    case DoubtActionTypes.ADD_TO_RESOLVED_DOUBTS:
      if (!resolvedDoubtIds.includes(action.doubtId)) {
        resolvedDoubtIds.push(action.doubtId);
      }
      if (resolvedDoubtIds.length > state.resolvedDoubtIds.length) {
        totalResolvedDoubts = totalResolvedDoubts + 1;
      }
      return {
        ...state,
        resolvedDoubtIds: sortDoubts(state.doubts, resolvedDoubtIds),
        totalResolvedDoubts,
      };

    case DoubtActionTypes.REMOVE_FROM_RESOLVED_DOUBTS:
      resolvedDoubtIds = resolvedDoubtIds.filter(
        (doubtId) => doubtId !== action.doubtId
      );
      if (resolvedDoubtIds.length < state.resolvedDoubtIds.length) {
        totalResolvedDoubts = totalResolvedDoubts - 1;
      }
      return {
        ...state,
        resolvedDoubtIds: sortDoubts(state.doubts, resolvedDoubtIds),
        totalResolvedDoubts,
      };

    case DoubtActionTypes.ADD_TO_AVAILABLE_DOUBTS:
      if (!availableDoubtIds.includes(action.doubtId)) {
        availableDoubtIds.push(action.doubtId);
      }
      if (availableDoubtIds.length > state.availableDoubtIds.length) {
        totalAvailableDoubts = totalAvailableDoubts + 1;
      }
      return {
        ...state,
        availableDoubtIds: sortDoubts(state.doubts, availableDoubtIds),
        totalAvailableDoubts,
      };

    case DoubtActionTypes.REMOVE_FROM_AVAILABLE_DOUBTS:
      availableDoubtIds = availableDoubtIds.filter(
        (doubtId) => doubtId !== action.doubtId
      );
      if (availableDoubtIds.length < state.availableDoubtIds.length) {
        totalAvailableDoubts = totalAvailableDoubts - 1;
      }
      return {
        ...state,
        availableDoubtIds: sortDoubts(state.doubts, availableDoubtIds),
        totalAvailableDoubts,
      };

    case DoubtActionTypes.ADD_TO_PENDING_DOUBTS:
      if (!pendingOnUserDoubtIds.includes(action.doubtId)) {
        pendingOnUserDoubtIds.push(action.doubtId);
      }
      if (pendingOnUserDoubtIds.length > state.pendingOnUserDoubtIds.length) {
        totalPendingDoubts = totalPendingDoubts + 1;
      }
      return {
        ...state,
        pendingOnUserDoubtIds: sortDoubts(state.doubts, pendingOnUserDoubtIds),
        totalPendingDoubts,
      };

    case DoubtActionTypes.REMOVE_FROM_PENDING_DOUBTS:
      pendingOnUserDoubtIds = pendingOnUserDoubtIds.filter(
        (doubtId) => doubtId !== action.doubtId
      );
      if (pendingOnUserDoubtIds.length < state.pendingOnUserDoubtIds.length) {
        totalPendingDoubts = totalPendingDoubts - 1;
      }
      return {
        ...state,
        pendingOnUserDoubtIds: sortDoubts(state.doubts, pendingOnUserDoubtIds),
        totalPendingDoubts,
      };

    case DoubtActionTypes.ADD_TO_REVIEW_PENDING_DOUBTS:
      if (!reviewPendingDoubtIds.includes(action.doubtId)) {
        reviewPendingDoubtIds.push(action.doubtId);
      }
      if (reviewPendingDoubtIds.length > state.reviewPendingDoubtIds.length) {
        totalReviewPendingDoubts = state.totalReviewPendingDoubts + 1;
      }
      return {
        ...state,
        reviewPendingDoubtIds: sortDoubts(state.doubts, reviewPendingDoubtIds),
        totalReviewPendingDoubts,
      };

    case DoubtActionTypes.REMOVE_FROM_REVIEW_PENDING_DOUBTS:
      reviewPendingDoubtIds = reviewPendingDoubtIds.filter(
        (doubtId) => doubtId !== action.doubtId
      );
      if (reviewPendingDoubtIds.length < state.reviewPendingDoubtIds.length) {
        totalReviewPendingDoubts = state.totalReviewPendingDoubts - 1;
      }
      return {
        ...state,
        reviewPendingDoubtIds: sortDoubts(state.doubts, reviewPendingDoubtIds),
        totalReviewPendingDoubts,
      };

    case DoubtActionTypes.REMOVE_ASSIGNED_DOUBT:
      return {
        ...state,
        assignNextStatus: true,
        actionStatus: { ...actionStatusInitialState },
        assignment: {
          ...assignmentInitialState,
          error: 'Click the button below to get another doubt',
        },
      };

    case DoubtActionTypes.UPDATE_DOUBT_OBJECT:
      doubts.set(action.doubt.id, action.doubt);
      return { ...state, doubts };

    case DoubtActionTypes.REJECTED_DOUBT:
      return {
        ...state,
        assignNextStatus: true,
        actionStatus: actionStatusInitialState,
        assignment: {
          ...assignmentInitialState,
          error: 'Click the button below to get another doubt',
        },
      };

    case DoubtActionTypes.UNASSIGNED_DOUBT:
      return {
        ...state,
        timerExpireCount: state.timerExpireCount + 1,
        actionStatus: actionStatusInitialState,
        assignNextStatus: true,
        assignment: {
          ...assignmentInitialState,
          error: 'Click the button below to get another doubt',
        },
      };

    case DoubtActionTypes.EMPTY_AVAILABLE_DOUBT_IDS:
      return { ...state, availableDoubtIds: [], totalAvailableDoubts: 0 };

    case DoubtActionTypes.UPDATE_ONLINE_MODE:
      return { ...state, onlineMode: action.onlineMode };

    case DoubtActionTypes.AVAILABLE_DOUBTS_COUNT_FETCHED:
      return {
        ...state,
        mentorsAvailableDoubts: action.mentorsAvailableDoubts,
        slotActive: action.slotActive,
      };

    default:
      return state;
  }
}

function sortDoubts(doubts: Map<number, Doubt>, idArray: number[]) {
  let doubtObjArr = Array.from(doubts.values()).filter((doubt) =>
    idArray.includes(doubt.id)
  );
  doubtObjArr = doubtObjArr.sort(function idSort(a, b) {
    return b['last_activity_timestamp'] - a['last_activity_timestamp'];
  });
  const sortedIdArray = [];
  doubtObjArr.forEach((doubt) => {
    sortedIdArray.push(doubt['id']);
  });
  return sortedIdArray;
}

function assignmentReducer(
  state: DoubtAssignmentData,
  action: DoubtActions
): DoubtAssignmentData {
  switch (action.type) {
    case DoubtActionTypes.ASSIGN_DOUBT:
      return state;
    case DoubtActionTypes.ASSIGNING_DOUBT:
      return { ...state, loading: true, error: 'Doubt is being assigned' };
    case DoubtActionTypes.DOUBT_ASSIGNED:
      if (isNullOrUndefined(action.doubt)) {
        return {
          ...assignmentInitialState,
          error: 'Click the button below to get another doubt',
        };
      } else {
        return {
          loading: false,
          doubtId: action.doubt.id,
          error: null,
          timerValue: action.timer,
          timerCompleteAction: action.timerCompleteAction,
          doubtPoints: action.doubtPoints,
        };
      }
    case DoubtActionTypes.DOUBT_ASSIGN_ERROR:
      return {
        loading: false,
        doubtId: -1,
        error: action.error,
        timerValue: 0,
        timerCompleteAction: 'UNASSIGN',
        doubtPoints: 0,
      };
    default:
      return state;
  }
}
