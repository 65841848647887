import {
  animate,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
  state,
} from '@angular/animations';

export const ToastNotificationAnimation: AnimationTriggerMetadata = trigger(
  'enterLeave',
  [
    state('enterBottom', style({ opacity: 1, transform: 'translateX(0)' })),
    transition('* => enterBottom', [
      style({ opacity: 0, transform: 'translateY(5%)' }),
      animate('100ms linear'),
    ]),
    state(
      'leave',
      style({
        opacity: 0,
        transform: 'scaleY(0.8)',
        transformOrigin: '0% 0%',
      })
    ),
    transition('* => leave', [
      style({
        opacity: 1,
        transform: 'scaleY(1)',
        transformOrigin: '0% 0%',
      }),
      animate('100ms linear'),
    ]),
  ]
);
