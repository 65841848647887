<div
  class="my-activity-notification-container border-radius-12 mb-12 p-12"
  [@enterLeave]="state"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()"
>
  <div
    *ngIf="showCloseIcon"
    (click)="close(false)"
    class="close-button p-4 cursor-pointer"
  >
    <mat-icon class="icon" fontSet="zen-icon" fontIcon="icon-cancel"></mat-icon>
  </div>
  <classroom-activity-switcher
    [notification]="notification.notification"
    theme="dark"
    source="Floating Notification"
  ></classroom-activity-switcher>
</div>
