import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'shared-ui-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.css'],
})
export class UnauthorisedComponent implements OnInit, OnDestroy {
  constructor(private metaService: Meta) {}

  ngOnInit() {
    this.metaService.addTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  ngOnDestroy() {
    this.metaService.removeTag('name="robots"');
  }

  redirectTo() {
    window.open('https://www.codingninjas.com/');
  }
}
