import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CallHelper {
  public constructor(@Inject(PLATFORM_ID) private platformId) {}

  public isSSRBot(): boolean {
    let userAgent = 'Human';
    if (isPlatformBrowser(this.platformId)) {
      // Access the window object only in the browser environment
      userAgent = window.navigator.userAgent;
    } else {
      return true;
    }

    // Enhanced RegExp to include common mobile devices
    const robots = new RegExp(
      [
        /spider/,
        /crawl/, // GENERAL TERMS
        /bing/,
        /yandex/,
        /baidu/,
        /duckduck/,
        /yahoo/, // OTHER ENGINES
        /ecosia/,
        /ia_archiver/,
        /pinterest/,
        /reddit/, // SOCIAL MEDIA
        /slack/,
        /whatsapp/,
        /googlebot/,
        /youtube/,
        /semrush/, // OTHER
      ]
        .map((r) => r.source)
        .join('|'),
      'i'
    ); // BUILD REGEXP + "i" FLAG
    return robots.test(userAgent);
    return false;
  }
}
