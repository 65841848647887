import { Inject, Injectable, PLATFORM_ID, Injector } from '@angular/core';
import { SocialAuthService } from './social-login/social-auth.service';
import { SocialAuthData, SocialProvider } from './social-login/';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import {
  ApiClient,
  Call,
  CallBuilder,
  CallMethod,
  NAUKRI_URLs,
  Resource,
  ResourceStatus,
  URLS,
} from '@codingninjas/networking';
import {
  AuthActions,
  AuthResponse,
  AuthState,
  AuthUser,
  LocalLogout,
  PreAuth,
} from './+state';
import { catchError, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {
  EmailLoginResponse,
  FbEmailMergeInitResponse,
} from './+state/models/email-login.response';
import { NinjasLoginCredentials } from './+state/models/ninjas-login-credentials.model';
import { CONSTANTS } from '../../../shared-ui/src/lib/constants';
import { AuthSetup } from './auth.setup';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { IAuthConfig } from './social-login/ninjas-auth-config.interface';
import { ReferralTrackingService } from '../../../ninjas-utils/src/lib/services/referral-tracking.service';
import { NaukriAnalyticsService } from '../../../ninjas-utils/src/lib/services/naukri-analytics.service';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from '../../../ninjas-utils/src/lib/utils/blank-check.util';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authActionSource = new Subject<AuthActions>();
  authActions$ = this._authActionSource.asObservable();
  public country_id: BehaviorSubject<number>;
  dialogRef: MatDialogRef<any>;
  req: any;
  userAgent: string;
  _isMobile = false;
  _isTablet = false;
  isNaukriApp = false;
  source = 'web';

  constructor(
    @Inject(PLATFORM_ID) private _platformId: object,
    @Inject('ninjasAuthConfig') private ninjasAuthConfig: IAuthConfig,
    private socialAuth: SocialAuthService,
    private apiClient: ApiClient,
    private store: Store<AuthState>,
    private authSetup: AuthSetup,
    public dialog: MatDialog,
    private overlay: Overlay,
    private injector: Injector,
    private referralService: ReferralTrackingService,
    private route: ActivatedRoute,
    private naukriAnalyticsService: NaukriAnalyticsService,
    private httpClient: HttpClient,
    @Inject('env') private environment
  ) {
    if (isPlatformServer(_platformId)) {
      this.req = this.injector.get('req');
    }
    if (this.validBrowserPlatform()) {
      this.userAgent = window.navigator.userAgent;
    }
    this.country_id = this.authSetup.country_id;
    this.fetchTokenAndUser();
    this.setSource();
  }

  public validBrowserPlatform() {
    return isPlatformBrowser(this._platformId) && typeof window !== 'undefined';
  }

  public openLoginModal(viewContainerRef) {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.width = '445px';
    config.maxWidth = '80%';
    config.viewContainerRef = viewContainerRef;
    config.scrollStrategy = this.overlay.scrollStrategies.noop();
    this.dialogRef = this.dialog.open(LoginDialogComponent, config);
  }

  private setSource() {
    this._isMobile = this.isMobile();
    this._isTablet = this.isTablet();
    if (this._isMobile) {
      this.source = 'mobile';
    } else if (this._isTablet) {
      this.source = 'tablet';
    }
    this.isNaukriApp = window.location.hostname === 'www.naukri.com';
  }

  public fetchTokenAndUser() {
    const token = this.getCookie('ninja_auth_token');
    const authData: AuthResponse = {
      user: null,
      user_access_token: token,
      provider: null,
    };
    if (token) {
      this.store.dispatch(new PreAuth(authData));
    }
  }

  private decodeRecursive(str, level) {
    if (str.length === 0 || level > 2) {
      return str;
    }
    const decoded = decodeURIComponent(str);
    if (decoded === str) {
      return decoded;
    }
    return this.decodeRecursive(decoded, level + 1);
  }

  public getUserCountryId(): BehaviorSubject<number> {
    return this.country_id;
  }

  public isMobile() {
    if (this.validBrowserPlatform()) {
      return !!(
        /\b(Android)\b/i.test(this.userAgent) &&
        /\b(Mobile)\b/i.test(this.userAgent)
      );
    }
    return false;
  }

  public isTablet() {
    if (this.validBrowserPlatform()) {
      return (
        !!(
          /\b(Android)\b/i.test(this.userAgent) &&
          !/\b(Mobile)\b/i.test(this.userAgent)
        ) || /\b(iPad)\b/i.test(this.userAgent)
      );
    }
    return false;
  }

  public actionDispatched(action: AuthActions) {
    if (action) {
      this._authActionSource.next(action);
    }
  }

  public emailLogin(email: string): Observable<Resource<EmailLoginResponse>> {
    const body = {
      email,
    };

    const call: Call = new CallBuilder(CallMethod.Post, URLS.V2.EMAIL_LOGIN)
      .body(body)
      .build();
    return this.apiClient.enqueue<EmailLoginResponse>(call);
  }

  public initFbEmailMerge(
    email: string,
    merge_token: string
  ): Observable<Resource<FbEmailMergeInitResponse>> {
    const body = {
      email,
      fb_merge_token: merge_token,
    };

    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V2.FB_EMAIL_MERGE_INIT
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<FbEmailMergeInitResponse>(call);
  }

  public verifyFbEmailMerge(
    otp: string,
    merge_token: string
  ): Observable<Resource<any>> {
    const body = {
      otp,
      fb_merge_token: merge_token,
    };

    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V2.FB_EMAIL_MERGE_VERIFY
    )
      .body(body)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  public phoneLogin(
    phoneNumber: string,
    countryCode: string
  ): Observable<Resource<EmailLoginResponse>> {
    const body = {
      phone_number: phoneNumber,
      phone_country_code: countryCode,
    };

    const call: Call = new CallBuilder(CallMethod.Post, URLS.V2.PHONE_LOGIN)
      .body(body)
      .build();
    return this.apiClient.enqueue<EmailLoginResponse>(call);
  }

  public ninjasLogin(data: NinjasLoginCredentials): Observable<Resource<any>> {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = isNullOrUndefined(timezone) ? 'Asia/Calcutta' : timezone;
    const blog_preference =
      data.blogPreference === undefined || data.blogPreference === null
        ? ''
        : data.blogPreference;
    const referral_data = this.referralService.getReferralCode();
    const body = {
      name: data.name || '',
      email: data.email,
      phone_number: data.phoneNumber,
      phone_country_code: data.phoneCountryCode,
      passcode: data.passcode,
      provider: data.provider,
      time_zone_string: timezone,
      college_id: data.college_id,
      grad_year: data.grad_year,
      referral_data,
      blog_preference,
    };

    if (this.ninjasAuthConfig.sourceApp === 'PublicSection') {
      const psReferralCode = this.route.snapshot.queryParams['psReferralCode'];
      if (psReferralCode) {
        body['ps_referral_code'] = psReferralCode;
      }
    }

    const call: Call = new CallBuilder(CallMethod.Post, URLS.V2.LOGIN)
      .setHeader('source', this.source)
      .body(body)
      .build();

    return this.apiClient.enqueue<AuthResponse>(call).pipe(
      map((resource: Resource<AuthResponse>) => {
        if (resource.isSuccessful()) {
          if (!resource.data.fb_merge_state) {
            this.referralService.setReferralApplied();
            const user: AuthUser = { ...resource.data.user } as AuthUser;
            this.setUserCookies(user, resource.data.user_access_token);
            if (this.naukriAnalyticsService.isNaukriUser()) {
              this.naukriAnalyticsService.sendEvent(
                'login',
                'Public_section_login',
                {}
              );
            }
            if (
              data.origin === CONSTANTS.DEFAULTS.LOGIN_FROM_HEADER &&
              window.location.hostname === 'codingninjas.com'
            ) {
              const url = 'https://classroom.codingninjas.com/';
              window.open(url, '_blank');
            }
          }
        }
        return resource;
      })
    );
  }

  public authenticate(
    socialAuthData: SocialAuthData
  ): Observable<Resource<AuthResponse>> {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = isNullOrUndefined(timezone) ? 'Asia/Calcutta' : timezone;
    const referral_data = this.referralService.getReferralCode();
    const body = {
      referral_data,
    };
    if (this.ninjasAuthConfig.sourceApp === 'PublicSection') {
      const psReferralCode = this.route.snapshot.queryParams['psReferralCode'];
      if (psReferralCode) {
        body['ps_referral_code'] = psReferralCode;
      }
    }
    const call: Call = new CallBuilder(CallMethod.Post, URLS.V2.LOGIN)
      .setParam('time_zone_string', timezone, true)
      .setParam('token', socialAuthData?.token)
      .setParam('provider', socialAuthData.provider)
      .setHeader('source', this.source)
      .body(body)
      .build();
    return this.apiClient.enqueue<AuthResponse>(call).pipe(
      map((resource: Resource<AuthResponse>) => {
        if (resource.isSuccessful()) {
          if (!resource.data.fb_merge_state) {
            this.referralService.setReferralApplied();
            const user: AuthUser = { ...resource.data.user } as AuthUser;

            if (user) {
              if (user.country_id) {
                this.country_id.next(user.country_id);
              }
            }
            this.setUserCookies(user, resource.data.user_access_token);
            if (this.naukriAnalyticsService.isNaukriUser()) {
              this.naukriAnalyticsService.sendEvent(
                'login',
                'Public_section_login',
                {}
              );
            }
            if (
              socialAuthData.origin === CONSTANTS.DEFAULTS.LOGIN_FROM_HEADER &&
              window.location.hostname === 'codingninjas.com'
            ) {
              const url = 'https://classroom.codingninjas.com/';
              window.open(url, '_blank');
            }
          }
        }
        return resource;
      })
    );
  }

  public logout(provider: string) {
    if (this.environment.code360 || this.environment.isNaukriEnv) {
      this.logoutNaukriProfile().subscribe((res) => {
        try {
          const cookiesToRemove = res['cookies'];
          for (const cookie of cookiesToRemove) {
            this.eraseCookie(cookie['name']);
          }
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.log('logout api failed...');
          this.deleteNaukriCookies();
        }
        this.ninjaTokenLogout(provider);
      });
    } else {
      this.ninjaTokenLogout(provider);
    }
  }

  public deleteNaukriCookies() {
    const naukriCookies = [
      'nauk_at',
      'nauk_rt',
      'is_login',
      'nauk_sid',
      'nauk_otl',
      'studio_at',
      'studio_rt',
      'failLoginCount',
      'NKWAP',
    ];
    for (const cookie of naukriCookies) {
      this.eraseCookie(cookie['name']);
    }
  }

  public ninjaTokenLogout(provider) {
    this.store
      .select('auth')
      .pipe(
        take(1),
        map((auth) => auth.user_access_token)
      )
      .subscribe((token) => {
        const action = new LocalLogout();
        this.store.dispatch(action);
        if (token) {
          this.logoutApi(token).subscribe((resource) => {
            if (resource.isSuccessful() || resource.isUnsuccessful()) {
              const socialProvider: SocialProvider =
                provider === 'google_oauth2' ? SocialProvider.GOOGLE : null;
              this.socialAuth.socialLogout(socialProvider);
              this.actionDispatched(action);
              return;
            }
          });
        } else {
          this.store.dispatch(new LocalLogout());
          this.actionDispatched(action);
        }
      });
  }

  private logoutApi(token): Observable<Resource<any>> {
    const call: Call = new CallBuilder(CallMethod.Post, URLS.V2.LOGOUT)
      .setHeader('Authorization', 'Token ' + token)
      .build();
    return this.apiClient.enqueue<any>(call);
  }

  private setUserCookies(user: AuthUser, user_access_token: string) {
    this.setCookie('ninja_auth_token', user_access_token, 30);
  }

  public setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    const domain = this.getDomain();
    document.cookie =
      name +
      '=' +
      (value || '') +
      expires +
      '; path=/ ; domain=' +
      domain +
      ';';
  }

  private getDomain() {
    let domain = window.location.hostname.split('.').slice(1).join('.');
    domain = domain === '' ? 'localhost' : '.' + domain;
    return domain;
  }

  private getCookie(name) {
    if (isPlatformBrowser(this._platformId)) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let c of ca) {
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    if (isPlatformServer(this._platformId)) {
      const cookies = this.req.cookies;
      const cookieStore = {};
      if (!!cookies === false) {
        return null;
      }

      const cookiesArr = cookies.split('; ');
      for (const cookie of cookiesArr) {
        const index = cookie.indexOf('=');
        const cookieArr = [cookie.slice(0, index), cookie.slice(index + 1)];
        cookieStore[cookieArr[0]] = cookieArr[1];
      }
      return cookieStore[name];
    }
    return null;
  }

  public eraseCookie(name) {
    const domain = this.getDomain();
    document.cookie =
      name +
      '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
      domain +
      ';';
  }

  checkValidName(name: string) {
    const call: Call = new CallBuilder(CallMethod.Get, URLS.V2.CHECK_VALID_NAME)
      .setParam('name', name)
      .build();

    return this.apiClient.enqueue<any>(call);
  }

  updateName(name: any) {
    const call: Call = new CallBuilder(
      CallMethod.Post,
      URLS.V3.PROFILE.UPDATE_NAME
    )
      .setParam('name', name)
      .build();

    return this.apiClient.enqueue<any>(call);
  }

  private logoutNaukriProfile(): Observable<any> {
    const headers = new HttpHeaders({
      appId: '1001',
      SystemId: 'coding_studio',
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-HTTP-Method-Override': 'DELETE',
      Authorization: 'Bearer=' + this.getCookie('studio_rt'),
    });
    const url = 'https://www.naukri.com/central-login-services/v1/login';

    return this.httpClient
      .post<any>(
        NAUKRI_URLs.STAGING.LOGOUT,
        {},
        { headers, withCredentials: true }
      )
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }
}
