import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const ExpandReportNotificationAnimation: AnimationTriggerMetadata = trigger(
  'expandReportNotification',
  [
    state('expand', style({ opacity: 1, transform: 'translateY(0)' })),
    transition('* => expand', [
      style({ opacity: 0, transform: 'translateX(0)' }),
      animate('200ms linear'),
    ]),
  ]
);
