import { BehaviorSubject } from 'rxjs';

type AnalyticEvent = any;

export enum DelayedInitStatus {
  UNINITIALIZED = 'uninitialized',
  INITIATED = 'initiated',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum DelayedAnalyticsProviderType {
  POSTHOG = 'posthog',
  CLARITY = 'clarity',
  WEBENGAGE = 'webengage',
  ALGOLIA = 'algolia',
}

export interface DelayedProviderConfig {
  provider: DelayedAnalyticsProviderType;
  queue: AnalyticEvent[];
  initStatus$: BehaviorSubject<DelayedInitStatus>;
  loadDelay: number;
}

export type DelayedAnalyticsProviders = {
  [key in DelayedAnalyticsProviderType]: DelayedProviderConfig;
};

const PROVIDER_SCRIPT_LOAD_DELAY = {
  [DelayedAnalyticsProviderType.POSTHOG]: 5000,
  [DelayedAnalyticsProviderType.CLARITY]: 5000,
  [DelayedAnalyticsProviderType.WEBENGAGE]: 5000,
  [DelayedAnalyticsProviderType.ALGOLIA]: 5000,
};

const buildDelayedProviderConfig = (
  provider: DelayedAnalyticsProviderType
): DelayedProviderConfig => ({
  provider,
  queue: [],
  initStatus$: new BehaviorSubject(DelayedInitStatus.UNINITIALIZED),
  loadDelay: PROVIDER_SCRIPT_LOAD_DELAY[provider],
});

export const DELAYED_ANALYTICS_PROVIDER_LIST = [
  DelayedAnalyticsProviderType.POSTHOG,
  DelayedAnalyticsProviderType.CLARITY,
  DelayedAnalyticsProviderType.WEBENGAGE,
  DelayedAnalyticsProviderType.ALGOLIA,
] as const;

export const DELAYED_ANALYTICS_PROVIDERS: DelayedAnalyticsProviders =
  DELAYED_ANALYTICS_PROVIDER_LIST.reduce((acc, provider) => {
    acc[provider] = buildDelayedProviderConfig(provider);
    return acc;
  }, {} as DelayedAnalyticsProviders);
