import { Inject, Injectable, Optional } from '@angular/core';
import {
  NINJAS_NOTIFICATION_CONFIG,
  NINJAS_NOTIFICATION_DEFAULT_CONFIG,
  NinjasNotificationConfig,
} from './ninjas-notification.config';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CustomNinjasNotification,
  CustomNinjasNotificationOptions,
} from './custom-ninja-notification';

@Injectable({
  providedIn: 'root',
})
export class CustomNinjasNotificationService {
  private _actionsSource: BehaviorSubject<CustomNotificationActions> = new BehaviorSubject<CustomNotificationActions>(
    null
  );
  $customNotificationAction: Observable<CustomNotificationActions> = this._actionsSource.asObservable();

  config: NinjasNotificationConfig = {};

  constructor(
    @Optional()
    @Inject(NINJAS_NOTIFICATION_DEFAULT_CONFIG)
    defaultConfig: NinjasNotificationConfig,
    @Optional()
    @Inject(NINJAS_NOTIFICATION_CONFIG)
    config: NinjasNotificationConfig
  ) {
    this.setConfig({ ...defaultConfig, ...config });
  }

  private setConfig(config: NinjasNotificationConfig): void {
    this.config = { ...this.config, ...config };
  }

  getConfig(): NinjasNotificationConfig {
    return this.config;
  }

  success(
    title: string,
    description: string,
    options?: CustomNinjasNotificationOptions
  ): CustomNinjasNotification {
    return this.create(title, description, options);
  }

  create(
    title: string,
    description: string,
    options?: CustomNinjasNotificationOptions
  ): CustomNinjasNotification {
    const notification = new CustomNinjasNotification(
      title,
      description
    ).setOptions(options);
    this._actionsSource.next(new AddCustom(notification));
    return notification;
  }

  removeNotification(notificationId: string) {
    this._actionsSource.next(new RemoveCustom(notificationId));
  }

  clearAll() {
    this._actionsSource.next(new ClearAllCustom());
  }
}

export const ADD_CUSTOM_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_CUSTOM_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_ALL_CUSTOM_NOTIFICATION = 'CLEAR_ALL';

export class AddCustom {
  type = ADD_CUSTOM_NOTIFICATION;
  constructor(public payload: CustomNinjasNotification) {}
}

export class RemoveCustom {
  type = REMOVE_CUSTOM_NOTIFICATION;
  constructor(public payload: string) {}
}

export class ClearAllCustom {
  type = CLEAR_ALL_CUSTOM_NOTIFICATION;
  payload = null;
}

export type CustomNotificationActions =
  | AddCustom
  | RemoveCustom
  | ClearAllCustom;
