import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export class CacheInterceptor implements HttpInterceptor {

  public static readonly HEADER_KEY:string = "x-cache-interceptor";
  private cache = new Map<string, CacheResponse>();


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // No cached response exists. Go to the network, and cache
    // the response when it arrives.
    if(req.headers.has(CacheInterceptor.HEADER_KEY)){

      const cacheTimeout = req.headers.get(CacheInterceptor.HEADER_KEY);
      const cacheTimeoutInSeconds = Number.parseInt(cacheTimeout) || 0;
      const validTill = cacheTimeoutInSeconds > 0 ? Date.now() + cacheTimeoutInSeconds * 1000 : -1;

      // Before doing anything, it's important to only cache GET requests.
      // Skip this interceptor if the request method isn't GET.
      if (req.method !== 'GET') {
        return next.handle(req);
      }

      const urlKey = this._getUrl(req);

      // First, check the cache to see if this request exists.
      const cachedResponse = this.cache.get(urlKey);
      if (cachedResponse && req.headers.has(CacheInterceptor.HEADER_KEY) && cachedResponse.isValid()) {


        // A cached response exists. Serve it instead of forwarding
        // the request to the next handler.
        return of(cachedResponse.response.clone());
      }

      const newHeaders = req.headers.delete(CacheInterceptor.HEADER_KEY);
      const newReq = req.clone({
        headers: newHeaders
      });
      return next.handle(newReq).pipe(tap(event => {
        if (event instanceof HttpResponse && req.headers.get(CacheInterceptor.HEADER_KEY)) {
          // Update the cache.
          this.cache.set(urlKey,new CacheResponse(event.clone(),validTill));
        }
      }));

    }

    return next.handle(req);

  }

  private _getUrl(req: HttpRequest<any>): string{
    let url: string =  req.urlWithParams + "";
    if(req.headers){
      for(const key of req.headers.keys()){
        const header = req.headers.get(key);
        url = url.concat(header);
      }
    }
    return url;
  }
}


class CacheResponse {

  _validTill: number;
  _response: HttpResponse<any>;

  constructor(response: HttpResponse<any>,validTill: number = -1){
    this._response = response;
    this._validTill = validTill;
  }

  get response(){
    return this._response;
  }

  public isValid(): boolean{
    return !(this._validTill > -1 && this._validTill < Date.now());

  }

}
