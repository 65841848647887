import { Injectable } from '@angular/core';

declare var clarity: any;
declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class ClarityService {
  constructor() {}
  private loader: Promise<boolean>;

  /* tslint:disable */
  public init(option: any): Promise<any> {
    this.loader = new Promise((resolve, reject) => {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', '3wfyxuahfv');
      resolve(true);
    });

    return this.loader;
  }

  public userDefine(config) {
    let userId = config.userId;
    if (this.loader) {
      this.loader.then((loaded) => {
        if (loaded) {
          clarity('set', 'ninjasUserId', userId + '');
          clarity('identify', userId + '');
        }
      });
    }
  }

  public pushClarityEvent(config) {
    if (
      typeof clarity !== 'undefined' &&
      config.event !== 'User Defined' &&
      !!config.eventAction &&
      this.loader !== null
    ) {
      this.loader.then((loaded) => {
        if (loaded) {
          clarity('set', 'eventAction', config.eventAction);
          clarity('set', 'eventCategory', config.eventCategory);
          clarity('set', 'eventLabel', config.eventLabel);
        }
      });
    }
  }
  /* tslint:enable */
}
