<div class="main_container">
  <mat-tab-group color="accent">
    <mat-tab label="Leave">
      <mentor-ta-leave-form
        [taId]="taId"
        [currentSlots]="currentSlots"
      ></mentor-ta-leave-form>
    </mat-tab>
  </mat-tab-group>
</div>
