<ng-container *ngIf="theme === 'light'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-secondary-cta
    size="small"
    (click)="openUrl(notification.content.primary_cta_text)"
  >
    <span class="flex-row align-items-center gap-6">
      {{ notification.content.primary_cta_text | sentenceCase }}
      <mat-icon
        class="arrow-icon"
        fontSet="zen-icon"
        fontIcon="icon-arrow-top-right"
      ></mat-icon>
    </span>
  </button>
</ng-container>
<ng-container *ngIf="theme === 'dark'">
  <button
    *ngIf="notification.has_primary_cta"
    zen-primary-cta
    size="small"
    (click)="openUrl(notification.content.primary_cta_text)"
  >
    <span class="flex-row align-items-center gap-6">
      {{ notification.content.primary_cta_text | sentenceCase }}
      <mat-icon
        class="arrow-icon"
        fontSet="zen-icon"
        fontIcon="icon-arrow-top-right"
      ></mat-icon>
    </span>
  </button>
</ng-container>
