import { InjectionToken } from '@angular/core';

export interface MinifiedNotificationConfig {
  top?: string;
  bottom?: string;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  duration?: number;
  maxStack?: number;
  pauseOnHover?: boolean;
  animate?: boolean;
  showClose?: boolean;
  audio?: string;
}

export const NINJAS_NOTIFICATION_DEFAULT_CONFIG =
  new InjectionToken<MinifiedNotificationConfig>(
    'NINJAS_NOTIFICATION_DEFAULT_CONFIG'
  );

export const NINJAS_NOTIFICATION_CONFIG =
  new InjectionToken<MinifiedNotificationConfig>('NINJAS_NOTIFICATION_CONFIG');

export const NINJAS_NOTIFICATION_DEFAULT_CONFIG_PROVIDER = {
  provide: NINJAS_NOTIFICATION_DEFAULT_CONFIG,
  useValue: {
    top: '24px',
    bottom: '24px',
    placement: 'topRight',
    duration: 4500,
    maxStack: 2,
    pauseOnHover: true,
    animate: true,
    showClose: true,
  },
};
