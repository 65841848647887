import { Router, UrlSegmentGroup, UrlTree } from '@angular/router';

// Client Routing Builder
// This service provides utility methods related to routing and redirection
export class ClientRoutingBuilder {
  // Properties
  private redirectURL: UrlTree;
  private readonly queryParameters: any;

  // Constructor
  constructor(private router: Router, redirectURL: string) {
    this.redirectURL = this.router.parseUrl(redirectURL);
    this.queryParameters = this.redirectURL.queryParams;
  }

  // Methods
  /**
   * Retrieves the query parameters from the class instance.
   * @returns The query parameters object.
   */
  get queryParams(): any {
    return this.queryParameters;
  }

  /**
   * Deletes a specific query parameter from the redirections query parameters.
   * @param paramName Name of the query parameter to delete
   * @returns The current instance of the RedirectionBuilder.
   */
  deleteQueryParam(paramName: string): ClientRoutingBuilder {
    delete this.queryParameters[paramName];
    return this;
  }

  /**
   * Builds the modified redirection URL without query parameters.
   * @returns The modified redirection URL without query parameters.
   */
  build(): string {
    const primaryChildren: UrlSegmentGroup =
      this.redirectURL.root.children['primary'];
    let modifiedRedirect = '';
    if (primaryChildren) {
      modifiedRedirect = primaryChildren.segments
        .map((it) => it.path)
        .join('/');
    }
    return modifiedRedirect;
  }
}
