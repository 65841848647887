<div class="confirmation-container p-12">
  <div class="zen-typo-subtitle-large mb-12">Confirmation</div>
  <div class="zen-typo-body-small mb-32">
    Are you sure you want to book this overtime slot?
  </div>
  <div class="actions">
    <button zen-primary-cta size="medium" (click)="closeDialog(true)">
      Confirm
    </button>

    <button zen-secondary-cta size="medium" (click)="closeDialog(false)">
      Cancel
    </button>
  </div>
</div>
