import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NinjasPopoverDirective } from './ninjas-popover.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { NinjasPopoverContainerComponent } from './ninjas-popover-container.component';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  declarations: [NinjasPopoverDirective, NinjasPopoverContainerComponent],
  imports: [CommonModule, PortalModule, OverlayModule],
  exports: [NinjasPopoverDirective, PortalModule, OverlayModule],
})
export class NinjasPopoverModule {}
