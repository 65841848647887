import { BehaviorSubject, fromEvent, Observable, of } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';
import Socket = SocketIOClient.Socket;

export class RxSocket {
  private _socket$: Observable<Socket>;

  private _stateSubject: BehaviorSubject<SocketState> = new BehaviorSubject({
    status: SocketStatus.DISCONNECTED,
  });
  public state$ = this._stateSubject.asObservable();

  private _disconnected = false;

  // public connect$: Observable<Socket>;
  // public disconnect$: Observable<Socket>;
  // public error$: Observable<Socket>;

  constructor(private _socket: Socket) {
    this._stateSubject.next({ status: SocketStatus.CONNECTING });
    if (!_socket.connected) {
      _socket.connect();
      this._disconnected = false;
    }

    this._socket.on('connect', (socket) => {
      this._stateSubject.next({ status: SocketStatus.CONNECTED });
    });
    this._socket.on('disconnect', (data) => {
      this._stateSubject.next({
        status: SocketStatus.DISCONNECTED,
        payload: data,
      });
    });
    this._socket.on('reconnecting', (data) => {
      this._stateSubject.next({
        status: SocketStatus.RECONNECTING,
        payload: data,
      });
    });
    this._socket.on('error', (data) => {
      this._stateSubject.next({ status: SocketStatus.ERROR, payload: data });
    });

    this._socket.on('connect_failed', (data) => {
      this._stateSubject.next({
        status: SocketStatus.CONNECT_FAILED,
        payload: data,
      });
    });

    this._socket.on('connect_error', (data) => {
      this._stateSubject.next({
        status: SocketStatus.CONNECT_ERROR,
        payload: data,
      });
    });

    this._socket$ = of(_socket);
    // this.connect$ = this._socket$.pipe(
    //   switchMap(socket => {
    //     return fromEvent(socket, 'connect')
    //       .pipe(map(() => socket));
    //   }));
    // this.error$ = this._socket$.pipe(
    //   switchMap(socket => {
    //     return fromEvent(socket, 'error')
    //       .pipe(map(() => socket));
    //   }));
    // this.disconnect$ = this._socket$.pipe(switchMap(socket => {
    //   return fromEvent(socket, 'disconnect');
    // }));
  }

  public listen(event: string): Observable<any> {
    return this._socket$.pipe(
      mergeMap((socket) => fromEvent(socket, event)),
      takeWhile(() => !this._disconnected)
    );
  }

  public send(event: string, data: any) {
    if (!this._disconnected) {
      this._socket.emit(event, data);
    }
  }

  public isConnected(): boolean {
    return this._socket.connected && this._disconnected;
  }

  public disconnect() {
    if (!this._disconnected) {
      this._disconnected = true;
      this._stateSubject.complete();
      this._stateSubject.unsubscribe();
      this._socket.disconnect();
    }
  }
}

export enum SocketStatus {
  CONNECTING,
  CONNECTED,
  RECONNECTING,
  ERROR,
  DISCONNECTED,
  CONNECT_FAILED,
  CONNECT_ERROR,
}

export interface SocketState {
  status: SocketStatus;
  payload?: any;
}
