import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  AnalyticsEvent,
  EcommerceAction,
} from '../models/analytics-event.model';

declare let dataLayer: any;
declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Inject('env') private environment
  ) {
    if (
      (window as any).pushEventsToQueue &&
      this.environment?.app !== 'publicsection'
    ) {
      window.addEventListener(
        'gtm_script_loaded',
        () => {
          if ((window as any).gtmEventQueue) {
            while ((window as any).gtmEventQueue.length > 0) {
              this.pushEventToDatalayer((window as any).gtmEventQueue[0]);
              (window as any).gtmEventQueue.shift();
            }
            if (!(window as any).gtmEventQueue.length) {
              delete (window as any).gtmEventQueue;
            }
          }
        },
        { once: true }
      );
    }
  }

  sendEvent(eventAttributes: AnalyticsEvent) {
    if (
      (window as any).pushEventsToQueue ||
      ((window as any).gtmEventQueue &&
        (window as any).gtmEventQueue.length > 0)
    ) {
      (window as any).gtmEventQueue.push(eventAttributes);
    } else {
      this.pushEventToDatalayer(eventAttributes);
    }
  }

  pushEventToDatalayer(eventAttributes) {
    if (isPlatformBrowser(this.platformId)) {
      const eventMap = {
        event: eventAttributes.name,
        eventCategory: eventAttributes.category,
        eventAction: eventAttributes.action,
        eventLabel: eventAttributes.label,
        eventValue: eventAttributes.value,
        eventData: eventAttributes.data,
        ...eventAttributes.data,
      };
      // if (this.environment?.app === 'publicsection') {
      //   this.sendGTAGEvents('event', eventAttributes.name, eventMap);
      //   return;
      // }
      if (dataLayer) {
        dataLayer.push(eventMap);
      }
    }
  }

  sendEcommerceEvent(
    event: string,
    action: string,
    label: string,
    ecommerce: EcommerceAction
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const sendData = {
        event,
        eventCategory: 'Ecommerce',
        eventAction: action,
        eventLabel: label,
        ecommerce,
      };
      if (dataLayer) {
        dataLayer.push(sendData);
      }
    }
  }

  setCustomVariables(customVariablesMap: {}) {
    if (isPlatformBrowser(this.platformId)) {
      if ((window as any).pushEventsToQueue) {
        window.addEventListener(
          'gtm_script_loaded',
          () => {
            if (dataLayer) {
              dataLayer.push({ ...customVariablesMap });
            }
          },
          { once: true }
        );
      } else {
        if (dataLayer) {
          dataLayer.push({ ...customVariablesMap });
        }
      }
    }
  }

  sendGTAGEvents(event, eventKey, eventData) {
    gtag(event, eventKey, eventData);
  }
}
