<div class="activity-container">
  <div [class]="theme" class="flex-row gap-8">
    <div class="left">
      <codingninjas-my-activity-icon
        [iconType]="notification.content.icon_type"
        [iconContext]="notification.content.icon_context"
      ></codingninjas-my-activity-icon>
    </div>
    <div class="right flex-column gap-8">
      <div class="flex-column gap-4">
        <div class="flex-row">
          <div
            class="interview-header flex-row justify-content-space-between align-items-center"
          >
            <span class="zen-typo-subtitle-small" style="margin-right: 4px">
              {{ theme === 'light' ? 'Great!' : 'Congratulations!' }}
              {{ notification.content.title }}
            </span>

            <codingninjas-my-activity-header-info
              *ngIf="theme === 'light'"
              [notification]="notification"
              [actionPerformed]="_actionPerformed"
            ></codingninjas-my-activity-header-info>
          </div>
        </div>

        <span class="message zen-typo-caption-medium">
          {{ notification.content.description }}
        </span>
      </div>

      <div>
        <codingninjas-new-skills-unlocked-actions
          [theme]="theme"
          [notification]="notification"
          (ctaClicked)="actionPerformed()"
          [source]="source"
        ></codingninjas-new-skills-unlocked-actions>
      </div>
    </div>
  </div>
</div>
