<div
  class="ninjas-notification-container notification-container-{{
    config.placement
  }}"
  [ngClass]="{
    'mr-0 mt-28': customNotifications.length > 0,
    'toast-notification': toastNotifications.length > 0
  }"
  [style.top]="
    config.placement === 'topLeft' || config.placement == 'topRight'
      ? config.top
      : null
  "
  [style.bottom]="
    config.placement === 'bottomLeft' ||
    config.placement == 'bottomRight' ||
    config.placement == 'bottomMiddle'
      ? config.top
      : null
  "
  [style.right]="
    config.placement === 'bottomRight' || config.placement == 'topRight'
      ? '0px'
      : null
  "
  [style.left]="
    config.placement === 'topLeft' ||
    config.placement == 'bottomLeft' ||
    config.placement == 'bottomMiddle'
      ? config.placement == 'bottomMiddle'
        ? config.left
        : '0px'
      : null
  "
>
  <codingninjas-custom-notification
    *ngFor="let customNotification of customNotifications; let i = index"
    [notification]="customNotification"
    [index]="i"
  >
  </codingninjas-custom-notification>

  <codingninjas-notification
    *ngFor="let regularNotification of regularNotifications; let i = index"
    [notification]="regularNotification"
    [index]="i"
  >
  </codingninjas-notification>

  <codingninjas-toast-notification
    *ngFor="let toastNotification of toastNotifications; let i = index"
    [notification]="toastNotification"
    [index]="i"
  >
  </codingninjas-toast-notification>

  <codingninjas-my-activity-notification-base
    *ngFor="let activityNotification of activityNotifications; let i = index"
    [notification]="activityNotification"
    [index]="i"
  ></codingninjas-my-activity-notification-base>
</div>
